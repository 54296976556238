//Battery Test

//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//Redux Features
import { useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
} from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

//MaterialUI
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

//Icons
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

//Export
import ExportCSV from '../common/ExportCSV';



/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}


const BatteryTest = (props) => {
	document.title = "Battery Test Results";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Hardware Agent"));
	dispatch(setCurrentMenuItem("/batterytest"));
	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");


	const rowRefs = useRef([]);


	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection: true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems: [],		//Defaults
		totalitems: 0,
		page: 0, //Assume page 0, or else pagination throws an error.
		order: "desc",
		orderby: "created_at",
		selectedcount: 0,
		rowsperpage: 25,
		selectedindexes: [],
		pendingsaves: false, //Used for parent view - Warnings about unsaved items!
		searchoptions: {
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs: {
				//Reserved for basic searchpairs. Inject search parameter 'nameparameter' here.
				searchpair1: { type: "SerialNumber", value: "", mode: "like", uuid: uuidv4() },
				searchpair2: { type: "CurrentResult", value: "1", mode: "like", uuid: uuidv4() },
				searchpair3: { type: "", value: "", mode: "left", uuid: uuidv4() },
				searchpair4: { type: "", value: "", mode: "right", uuid: uuidv4() },
				searchpair5: { type: "", value: "", mode: "like", uuid: uuidv4() },
				searchpair6: { type: "", value: "", mode: "like", uuid: uuidv4() },
				//Reserved for AutoComplete searchpairs.
				searchpair7: { type: "", value: "", mode: "strict", uuid: uuidv4() },
				searchpair8: { type: "", value: "", mode: "strict", uuid: uuidv4() },
				searchpair9: { type: "", value: "", mode: "strict", uuid: uuidv4() },
				searchpair10: { type: "", value: "", mode: "strict", uuid: uuidv4() },
			},
			nestedrelationships: {

			}
		}
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}



	/* ##########################  Top Button Refs and Functions  ########################## */
	const btnSave = useRef();
	const btnPendingSave = useRef();
	const btnDeleteSelected = useRef();
	const btnExpandAll = useRef();
	const btnResetSearches = useRef();
	const btnExport = useRef();
	const btnPrintLabels = useRef();

	//Disable Buttons
	const DisableButtons = () => {
		btnSave.current.setAttribute("disabled", true);
		btnPendingSave.current.setAttribute("disabled", true);
		btnDeleteSelected.current.setAttribute("disabled", true);
		btnExpandAll.current.setAttribute("disabled", true);
		btnResetSearches.current.setAttribute("disabled", true);
		btnExport.current.setAttribute("disabled", true);
		btnPrintLabels.current.setAttribute("disabled", true);
	}

	//Enable Buttons
	const EnableButtons = () => {
		btnSave.current.removeAttribute("disabled");
		btnPendingSave.current.removeAttribute("disabled");
		btnDeleteSelected.current.removeAttribute("disabled");
		btnExpandAll.current.removeAttribute("disabled");
		btnResetSearches.current.removeAttribute("disabled");
		btnExport.current.removeAttribute("disabled");
		btnPrintLabels.current.removeAttribute("disabled");
	}


	/* ##########################  Column States  ########################## */
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		ID: false,
		BootID: false,
		StationID: true,
		SerialNumber: true,
		BatteryNumber: true,
		BatterySerialNumber: true,
		BattModel: true,
		BattMfg: true,
		MachineModel: true,
		BatteryHealth: true,
		BattDuration: true,
		PercentChargeStart: true,
		PercentCharge: true,
		created_at: true,
		CurrentResult: true
	});


	const UpdateColState = (colstate) =>{
		setColState(colstate);
	}




	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);
	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = ! newcolstate[key];
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover}/>
				}
			</React.Fragment>
		)
	}

	/* Export Menu */
	const [showexportmenu, setExportMenu] = useState(null);
	const ShowExportMenu = (event) => { setExportMenu(event.currentTarget); }
	const CloseExportMenu = () => { setExportMenu(null); }


	/* ##########################  Selected Rows  ########################## */
	//Explanation: Since we're using <Checkbox> from materialui in an uncontrolled manner (avoiding state, avoiding rerenders...)
	//we are assuming the only way to show a "checked" value of the checkbox is by a user clicking the actual checkbox.

	//Simply using rowRefs.current[index+"Checkbox"].checked can be set to "true", but the view WILL NOT REFLECT THE CHANGE.
	//So for manual clicks on <Checkbox> we register in localstate
	//For SelectAll, we have to run through all items and set isSelected, then rerender.
	//Non-direct interaction with checkbox components will result in difficulty trying to access it through some kind
	//of ref/DOM manipulation. Therefore we pass changes to these components through state changes, mutating localstate.

	const SelectRow = (index) => {
		if (localstate.selectedindexes.indexOf(index) === -1) {
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length) {
				UpdateState(localstate);
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex, 1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length + 1)) {
				UpdateState(localstate);
			}
		}

		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i = 0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	};



	/* ##########################  Search Options  ########################## */


	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	//Set Search Key:
	var mode = "";
	const onChangeSearchType = (searchtype, searchnumber) => {
		//Search Mode: Each search type may have a different search mode
		//left, right, like, strict, not
		//Default Mode: LIKE
		mode = "like";
		//Provision for automatically switching search mode
		if (searchtype === "Location") {
			mode = "strict";
		}

		//Not Conditionals: Convert NotName to Name in BasicTableController
		if (searchtype === "NotName") {
			mode = "not"
		}
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].type = searchtype;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].mode = mode;
		//Provision to add columns if selected for search.
		if (searchtype === "Name") {
			colstate["Name"] = true;
			setColumnMenu(null);
		}
		UpdateState(localstate);
	}

	//Set Search Value:
	const onChangeSearchValue = debounce(function (searchvalue, searchnumber) {
		//Clears out changes because table is about to be reloaded:
		ResetPendingSaves();
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = searchvalue;
		localstate.dbreload = true;
		UpdateState(localstate);
	}, 600);

	//Key-Value Inputs
	const [searchinputs, setSearchInputs] = useState({
		show1: true,
		show2: true,
		show3: true,
		show4: false,
		show5: false,
		show6: false,
		lastsearch: 3
	});


	const AddSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show" + (newsearchinputs.lastsearch + 1)] = true;
		newsearchinputs.lastsearch++;
		setSearchInputs(newsearchinputs);
	}

	const RemoveSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show" + (newsearchinputs.lastsearch)] = false;
		newsearchinputs.lastsearch--;
		setSearchInputs(newsearchinputs);
	}


	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		//This evaluates if we're requesting the same property and if it's asc.
		const isAsc = localstate.orderby === property && localstate.order === "asc";	
		//We need to evaluate if we should start back at page 1:
		if (localstate.orderby !== property){
			localstate.page = 0;
		}
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page = 0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems() {
		if (localstate.clearselection) {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection = true;
		}
		const postdata = {
			searchoptions: {
				limit: localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs: localstate.searchoptions.searchpairs,
				nestedrelationships: localstate.searchoptions.nestedrelationships
			}
		};
		axios.post(dbendpoint + "/hardwareagent/getbattstats?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var resultdata = res.data.pagedata.data;
					for (var i = 0; i < resultdata.length; i++) {
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey = i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						if (localstate.selectedindexes.includes(i)) {
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}
					}
					localstate.griditems = resultdata;
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.griditems = [];
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}


	
	/* ##########################  App Init  ########################## */
	//Run once, don't clean up until controller dismount (unchanging init variable)
	const [appinit] = useState(true);
	useEffect(() => {
		document.title = "Battery Test Results";
		//Cleanup
		return function cleanup() {
			dispatch(setProgressTimeout(0));
		}
	}, [appinit]);


	//DB Reloads
	useEffect(() => {
		if (state.dbreload){
			//Avoid duplicate loads.
			localstate.dbreload = false;
			LoadItems();
		} else {
			
		}
	},);



	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	/* Disable Add Row
	const AddRow = () => {
		localstate.griditems.unshift({
			PendingItem:true,
			ID:uuidv4(),
			BootID:uuidv4(),
			StationID:0,
			SerialNumber:"XYZ",
			BatteryNumber:1,
			BatterySerialNumber:"ABC",
			BatteryHealth:"0%",
			BattDuration:"00:00:00",
			PercentChargeStart:"100%",
			PercentCharge:"0%",
			CurrentResult:1
		});
		//All selected indexes move up by 1.
		for (var i=0; i<localstate.selectedindexes.length; i++){
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}
	*/

	//A Ref stays consistent throughout async function
	const requestcount = useRef(0);

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		//Configuration
		requestcount.current = 0;
		var timeout = 300; //Seconds before closing final ProgressBar results - To Do: Add option to NOT timeout at all
		var requestinterval = 100; //Milliseconds

		var updatearray = [];
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				updatearray.push(localstate.griditems[i]);
			}
		}

		if (updatearray.length > 0) {
			DisableButtons();
			dispatch(newProgress({
				msg: 'Saving items...',
				show: true,
				settimeout: true, //This option allows for a timeout after the final item is pass/failed
				timeout: timeout, //Initiated on final item 
				type: 'items', //Labels your save types such as items, products, skus, parts
				finished: 0,
				percent: "0%",
				total: updatearray.length,
				pass: 0,
				fail: 0,
				faillist: [], //Can take simple values,
				faillinks: [], //Can take links to things like items or products.
				timeoutid: false,
				errors:[] //Can take strings
			}));
			i = 0;
			var limit = updatearray.length - 1;
			/* INTERVAL */
			var updateitems = setInterval(function () {
				var item = updatearray[i];
				const postdata = {
					item: item
				};
				axios.post(dbendpoint + "/hardwareagent/updatebattstats", postdata, defaultpostoptions).then(res => {
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we sent new rows, we'll need to reference the old ID.
							var itemindex = 0;
							if (res.data.OldID) {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems[itemindex].unsaved = false;
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
								//Set New ID
								localstate.griditems[itemindex].ID = res.data.item.ID;
							} else {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.item.ID);
								localstate.griditems[itemindex].unsaved = false;
								//Refs allow us to update the grid live!
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
							}
							dispatch(incrementPass());
						}
						if (res.data.Status === "Failure") {
							//Failure error - To Do: Dispatch failure messages to the ProgressBar
							dispatch(incrementFail());
						}
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);

					} else {
						//Non-200 message from server.
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
						dispatch(incrementFail());
					}
				}).catch(err => {
					//Non-200, 500 Error, timeout?
					requestcount.current++;
					FinalizeRequest(requestcount.current, limit, timeout);
					dispatch(incrementFail());
				});
				//If we have looped through all items, clear this interval.
				if (i === limit) {
					clearInterval(updateitems);
				}
				i++;
			}, requestinterval);
		} else {
			errors.NewError({ errmsg: "Nothing to save.", errshow: true, errtimeout: 5, errtype: "neutral" })
			ResetPendingSaves();
		}
	}


	//Clean up View
	const FinalizeRequest = (i, limit, timeout) => {
		//The itterator after responses are incremented to n+1. Reversed below:
		if ((i-1)===limit){
			EnableButtons();
			dispatch(setProgressTimeout(timeout));
			MarkErrorItems();
			btnSave.current.style.display="";
			btnPendingSave.current.style.display="none";
		}
	} 


	//Mark Remaining Items as Warning Items
	const MarkErrorItems = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.unsavedhighlight);
				rowRefs.current[i+'SaveStatus'].classList.add(classes.errorhighlight);
			} else {
				//Resolve previously unresolved
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.errorhighlight);
			}
		}
	}


	const ResetPendingSaves = () =>{
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				localstate.griditems[i].unsaved = false;
			}
		}
		EnableButtons();
		btnSave.current.style.display="";
		btnPendingSave.current.style.display="none";
	}


	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () => {
		var deleteitemsarray = [];
		//Reflect items to user for confimation.
		for (var i = 0; i < state.selectedindexes.length; i++) {
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")) {
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {
					item: localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint + "/hardwareagent/deletebattstats", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID) {
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
							} else {
								errors.NewError({ errmsg: "Could not delete one or more items.", errshow: true, errtimeout: 8, errtype: "warning" })
							}
						}
						if (res.data.Status === "Failure") {
							//Failure error
							errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
						}
					} else {
						//Non-200 message from server.
						errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}


	/* ##########################  Cell Interaction  ########################## */


	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	const taborder = ["StationID", "SerialNumber", "BatteryNumber", "BatterySerialNumber", "BattModel", "BattMfg", "MachineModel", "BatteryHealth", "BattDuration", "PercentChargeStart", "PercentCharge"];

	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown") {
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp") {
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length - 1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
	}

	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) => {
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (index > 0) {
			var decimalchars = (len - 1) - index;
			if (decimalchars > 2) {
				return oldvalue;
			}
		}
		return newvalue;
	}
	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {
			//Conditionals for Types:
			/*
			if (column==="Cost"){
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Cost changes, so does Margin
				rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
			}
			if (column==="Price"){
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Price changes, so does Margin
				rowRefs.current[index+"Margin"].textContent = (newvalue - rowRefs.current[index+"Cost"].value).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
			}
			*/
			//Provision for Booleans that require a re-render. Expensive!
			if (column === "SomeBoolean") {
				if (event.target.checked) {
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}

			} else {
				//Selects already render the correct and unsaved result of selecting. (but right now they cause rerender via state.... possible change here.)
				if (column === "CurrentResult") { //MaterialUI Checkbox
					if (event.target.checked) {
						localstate.griditems[index][column] = 1;
					} else {
						localstate.griditems[index][column] = 0;
					}
				} else if (column === "SomeSelectable") {
					//Status works similar to booleans - Component takes care of view while localstate has yet to update the DB
					localstate.griditems[index][column] = newvalue;
				} else {
					//Update Refs like usual.
					rowRefs.current[index + column].value = newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
		}
	}


	/* ##########################  Button Functions  ########################## */

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ResetSearches = () => {
		//Reset all:
		for (var i = 1; i < 7; i++) {
			localstate.searchoptions.searchpairs["searchpair" + i].type = "";
			localstate.searchoptions.searchpairs["searchpair" + i].value = "";
			localstate.searchoptions.searchpairs["searchpair" + i].mode = "like";
			localstate.searchoptions.searchpairs["searchpair" + i].uuid = uuidv4();
		}
		//Set Defaults:
		localstate.searchoptions.searchpairs.searchpair1.type = "StationID";
		localstate.searchoptions.searchpairs.searchpair3.type = "CurrentResult";
		localstate.searchoptions.searchpairs.searchpair3.value = 1;
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	const BulkUpdate = () => {
		//Explanation:
		//Currently checkboxes are uncontrolled, they simply add the indexes to the localstate.selectedindexes, uncommitted to state.
		//This avoids an expensive rerender for large grids.
		var i;
		var random;
		for (i = 0; i < localstate.selectedindexes.length; i++) {
			console.log(localstate.griditems[localstate.selectedindexes[i]].Name);
			random = getRandomInt(3);
			//Update view to reflect pending changes
			rowRefs.current[localstate.selectedindexes[i] + "Cost"].value = random;
			rowRefs.current[localstate.selectedindexes[i] + "SaveStatus"].classList.add("unsavedhighlight");
			//Update localstate
			localstate.griditems[localstate.selectedindexes[i]]["Cost"] = random;
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
		}
		localstate.pendingsaves = true;
		btnSave.current.style.color = "white";
		btnSave.current.style.backgroundColor = "#01579B";
	}

	//Export Confirmation and Exports
	const [showexportconfirmation, setShowExportConfirmation] = useState(false);
	//Chooses between "selected" or "searchresults"
	const [exporttype, setExportType] = useState("");
	//Export Message
	const [exportmessage, setExportMessage] = useState("");
	var exportmode = "";
	var exportlimit = 2000;
	var exportfilename = "BoilerplateCSV";

	const InitExport = (exporttype) => {
		if (exporttype === "selected" && localstate.selectedindexes.length === 0) {
			//Do nothing
		} else {
			//Show export type selection
			setShowExportConfirmation(true);
			//Set export by "selected" or "searchresults"
			setExportType(exporttype);
			if (exporttype === "selected") {
				setExportMessage("");
			}
			if (exporttype === "searchresults") {
				setExportMessage("Exports of search results will be limited to " + exportlimit + " rows.");
			}
		}
	}

	const PrepareExport = (mode) => {
		//Set global
		exportmode = mode;
		var exportdata = [];
		var clonedata = [];
		if (exporttype === "selected") {
			//Need to DEEP clone:
			var clonedata = JSON.parse(JSON.stringify(localstate.griditems))
			for (var i = 0; i < localstate.selectedindexes.length; i++) {
				exportdata.push(clonedata[localstate.selectedindexes[i]]);
			}
			Export(exportdata);
		}
		if (exporttype === "searchresults") {
			const postdata = {
				searchoptions: {
					//Set Max Limit Here
					limit: exportlimit,
					currentsort: localstate.orderby,
					currentsortdir: localstate.order,
					searchpairs: localstate.searchoptions.searchpairs
				}
			};
			axios.post(dbendpoint + "/hardwareagent/getbattstats?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						exportdata = res.data.pagedata.data;
						Export(exportdata);
					}
					if (res.data.Status === "Failure") {
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
					}
				} else {
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				}
			});
		}
	}

	const Export = (exportdata) => {
		//Remove Metas
		for (var i = 0; i < exportdata.length; i++) {
			removeProp(exportdata[i], "isSelected");
			removeProp(exportdata[i], "unsaved");
			removeProp(exportdata[i], "ExpandRow");
			removeProp(exportdata[i], "GridKey");
		}

		//Use Export Mode to pare data
		if (exportmode === "simple") {
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "Date");
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "expanded") {
			for (var i = 0; i < exportdata.length; i++) {
				// See creationd date: removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "exhaustive") {
			//Remove nothing - or possibly break down nested relationships
		}
		ExportCSV(exportdata, exportfilename);
		setShowExportConfirmation(false);
	}



	/* ##########################  TABLE HEAD  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "ID", numeric: false, label: "ID", align: "left", allowsort: true, style: {} },
		{ id: "BootID", numeric: false, label: "Boot ID", align: "left", allowsort: false, style: {} },
		{ id: "StationID", numeric: false, label: "Station ID", align: "left", allowsort: true, style: {} },
		{ id: "SerialNumber", numeric: false, label: "Serial Number", align: "left", allowsort: false, style: {} },
		{ id: "BatteryNumber", numeric: true, label: "Battery #", align: "left", allowsort: true, style: {} },
		{ id: "BatterySerialNumber", numeric: false, label: "Serial", align: "left", allowsort: true, style: {} },
		{ id: "BattModel", numeric: false, label: "Model", align: "left", allowsort: true, style: {} },
		{ id: "BattMfg", numeric: false, label: "Mfg", align: "left", allowsort: true, style: {} },
		{ id: "MachineModel", numeric: false, label: "Machine", align: "left", allowsort: true, style: {} },
		{ id: "BatteryHealth", numeric: false, label: "Health %", align: "left", allowsort: true, style: {} },
		{ id: "BattDuration", numeric: false, label: "Test Duration", align: "left", allowsort: true, style: {} },
		{ id: "PercentChargeStart", numeric: false, label: "Start Charge", align: "left", allowsort: true, style: {} },
		{ id: "PercentCharge", numeric: false, label: "End Charge", align: "left", allowsort: true, style: {} },
		{ id: "created_at", numeric: false, label: "Date", align: "center", allowsort: true, style: {} },
		{ id: "CurrentResult", numeric: false, label: "Current Result", align: "center", allowsort: true, style: {} },
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};

		return (
			<thead style={{ display: "table-header-group" }}>
				<tr style={{
					border: "1px solid #CCC",
					backgroundColor: "#DDD"
				}}>
					<td style={{ width: "14px", padding: "none", display: "table-cell", padding: "2px 4px 2px 5px" }}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] &&
						(
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
							</td>
						))}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	{/* Utility Drawer state and functions */ }
	const [showutilitydrawer, setShowUtilityDrawer] = useState(false);

	const CloseUtilityDrawer = () => {
		setShowUtilityDrawer(false);
		//Reload DB?
		//localstate.dbreload = true;
		//Update State?
		//UpdateState(localstate);
	}

	{/* Print Labels */ }
	const PrintLabels = () => {
		var itemids = [];
		//Grab all IDs
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			itemids.push(localstate.griditems[localstate.selectedindexes[i]]['ID']);
		}

		var params = {
			itemids: JSON.stringify(itemids),
			labeltype: 'batterylabels',
			autoclose: false,
			seriallist: '',
			orderby: localstate.orderby,
			order: localstate.order
		};

		// Creating a form
		var form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", "../v3/labels");
		form.setAttribute("target", "NewLabel");
		for (var i in params) {
			if (params.hasOwnProperty(i)) {
				// Creating the input
				var input = document.createElement('input');
				input.type = 'hidden';
				input.name = i;
				input.value = params[i];

				// Attach input to form
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		form.submit();
	}


	/* 
																							 
		 _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
		_/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
	   _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
	  _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
	 _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
																						 
																						 
	  */


	/* ##########################  Render Function  ########################## */
	return (
		<div style={{ padding: "8px", overflow: "auto", minWidth: "350px" }}>

			{/* Standard Page Header with right floated error message space */}
			<div style={{ minHeight: "50px", paddingTop:"5px" }}>
				<Grid container justifyContent="space-between">
					<Grid item xs={12} sm={6} md={3} order={{ xs: 3, sm: 3, md: 1 }} style={{padding:"5px", minWidth:"270px"}}>{(errors.currenterror.errshow) &&
						<ErrorMessage />
					}</Grid>
					<Grid item xs={12} sm={6} md={3} order={{ xs: 1, sm: 1, md: 2 }} style={{textAlign:"center", margin:"auto", padding:"5px"}}><h2>Battery Test Results</h2></Grid>
					<Grid item xs={12} sm={6} md={3} order={{ xs: 2, sm: 2, md: 3 }} style={{padding:"5px", minWidth:"270px"}}>
						<div style={{float:"right"}}>
							<ProgressBar />
						</div>
					</Grid>
				</Grid>
			</div>

			{/* /* ##########################  Search Inputs  ########################## */}
			{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
			{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}

			{(!isPrintView) &&
				<div>

					{/* Key-Value Searches */}

					{/* Search Pair 1 */}
					{(searchinputs.show1) &&
						<div className={classes.searchinputs}>
							<FormControl variant="standard" className={classes.searchtypeinput} style={{ minWidth: "120px" }}>
								{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
								<Select
									key={localstate.searchoptions.searchpairs.searchpair1.uuid}
									value={localstate.searchoptions.searchpairs.searchpair1.type}
									onChange={(event) => onChangeSearchType(event.target.value, 1)}
									disableUnderline
								>
									<MenuItem value={"StationID"}>Station ID</MenuItem>
									<MenuItem value={"SerialNumber"}>Machine Serial Number</MenuItem>
									<MenuItem value={"BatterySerialNumber"}>Battery Serial Number</MenuItem>
									<MenuItem value={"BatteryNumber"}>Battery Number</MenuItem>
									<MenuItem value={"BatteryHealth"}>Battery Health</MenuItem>
									<MenuItem value={"BattDuration"}>Battery Duration</MenuItem>
									<MenuItem value={"PercentChargeStart"}>Start Charge</MenuItem>
									<MenuItem value={"PercentCharge"}>End Charge</MenuItem>
									<MenuItem value={"created_at"}>Date</MenuItem>
									<MenuItem value={"CurrentResult"}>Current Result</MenuItem>
								</Select>
							</FormControl>
							<TextField id="search1" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair1.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair1.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 1)} />
							<div style={{ width: "30px" }}>&nbsp;</div>
						</div>
					}

					{/* Search Pair 2 */}
					{(searchinputs.show2) &&
						<div className={classes.searchinputs}>
							<FormControl variant="standard" className={classes.searchtypeinput} style={{ minWidth: "120px" }}>
								<Select
									key={localstate.searchoptions.searchpairs.searchpair2.uuid}
									value={localstate.searchoptions.searchpairs.searchpair2.type} disableUnderline
									onChange={(event) => onChangeSearchType(event.target.value, 2)}
								>
									<MenuItem value={"StationID"}>Station ID</MenuItem>
									<MenuItem value={"SerialNumber"}>Machine Serial Number</MenuItem>
									<MenuItem value={"BatterySerialNumber"}>Battery Serial Number</MenuItem>
									<MenuItem value={"BatteryNumber"}>Battery Number</MenuItem>
									<MenuItem value={"BatteryHealth"}>Battery Health</MenuItem>
									<MenuItem value={"BattDuration"}>Battery Duration</MenuItem>
									<MenuItem value={"PercentChargeStart"}>Start Charge</MenuItem>
									<MenuItem value={"PercentCharge"}>End Charge</MenuItem>
									<MenuItem value={"created_at"}>Date</MenuItem>
									<MenuItem value={"CurrentResult"}>Current Result</MenuItem>
								</Select>
							</FormControl>
							<TextField id="search2" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair2.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair2.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 2)} />
							<div style={{ width: "30px", float: "right" }}>
								{(searchinputs.show2 && !searchinputs.show3) &&
									<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
										<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
									</IconButton>
								}
							</div>
						</div>
					}

					{/* Search Pair 3 */}
					{(searchinputs.show3) &&
						<div className={classes.searchinputs}>
							<FormControl variant="standard" className={classes.searchtypeinput} style={{ minWidth: "120px" }}>
								<Select
									key={localstate.searchoptions.searchpairs.searchpair3.uuid}
									value={localstate.searchoptions.searchpairs.searchpair3.type} disableUnderline
									onChange={(event) => onChangeSearchType(event.target.value, 3)}
								>
									<MenuItem value={"StationID"}>Station ID</MenuItem>
									<MenuItem value={"SerialNumber"}>Machine Serial Number</MenuItem>
									<MenuItem value={"BatterySerialNumber"}>Battery Serial Number</MenuItem>
									<MenuItem value={"BatteryNumber"}>Battery Number</MenuItem>
									<MenuItem value={"BatteryHealth"}>Battery Health</MenuItem>
									<MenuItem value={"BattDuration"}>Battery Duration</MenuItem>
									<MenuItem value={"PercentChargeStart"}>Start Charge</MenuItem>
									<MenuItem value={"PercentCharge"}>End Charge</MenuItem>
									<MenuItem value={"created_at"}>Date</MenuItem>
									<MenuItem value={"CurrentResult"}>Current Result</MenuItem>
								</Select>
							</FormControl>
							<TextField id="search3" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair3.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair3.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 3)} />
							<div style={{ width: "30px" }}>
								{(searchinputs.show3 && !searchinputs.show4) &&
									<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
										<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
									</IconButton>
								}
							</div>
						</div>
					}

					{/* Search Pair 4 */}
					{(searchinputs.show4) &&
						<div className={classes.searchinputs}>
							<FormControl variant="standard" className={classes.searchtypeinput} style={{ minWidth: "120px" }}>
								<Select
									key={localstate.searchoptions.searchpairs.searchpair4.uuid}
									value={localstate.searchoptions.searchpairs.searchpair4.type} disableUnderline
									onChange={(event) => onChangeSearchType(event.target.value, 4)}
								>
									<MenuItem value={"StationID"}>Station ID</MenuItem>
									<MenuItem value={"SerialNumber"}>Machine Serial Number</MenuItem>
									<MenuItem value={"BatterySerialNumber"}>Battery Serial Number</MenuItem>
									<MenuItem value={"BatteryNumber"}>Battery Number</MenuItem>
									<MenuItem value={"BatteryHealth"}>Battery Health</MenuItem>
									<MenuItem value={"BattDuration"}>Battery Duration</MenuItem>
									<MenuItem value={"PercentChargeStart"}>Start Charge</MenuItem>
									<MenuItem value={"PercentCharge"}>End Charge</MenuItem>
									<MenuItem value={"created_at"}>Date</MenuItem>
									<MenuItem value={"CurrentResult"}>Current Result</MenuItem>
								</Select>
							</FormControl>
							<TextField id="search4" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair4.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair4.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 4)} />
							<div style={{ width: "30px" }}>
								{(searchinputs.show4 && !searchinputs.show5) &&
									<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
										<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
									</IconButton>
								}
							</div>
						</div>
					}


					{/* Search Pair 5 */}
					{(searchinputs.show5) &&
						<div className={classes.searchinputs}>
							<FormControl variant="standard" className={classes.searchtypeinput} style={{ minWidth: "120px" }}>
								<Select
									key={localstate.searchoptions.searchpairs.searchpair5.uuid}
									value={localstate.searchoptions.searchpairs.searchpair5.type} disableUnderline
									onChange={(event) => onChangeSearchType(event.target.value, 5)}
								>
									<MenuItem value={"StationID"}>Station ID</MenuItem>
									<MenuItem value={"SerialNumber"}>Machine Serial Number</MenuItem>
									<MenuItem value={"BatterySerialNumber"}>Battery Serial Number</MenuItem>
									<MenuItem value={"BatteryNumber"}>Battery Number</MenuItem>
									<MenuItem value={"BatteryHealth"}>Battery Health</MenuItem>
									<MenuItem value={"BattDuration"}>Battery Duration</MenuItem>
									<MenuItem value={"PercentChargeStart"}>Start Charge</MenuItem>
									<MenuItem value={"PercentCharge"}>End Charge</MenuItem>
									<MenuItem value={"created_at"}>Date</MenuItem>
									<MenuItem value={"CurrentResult"}>Current Result</MenuItem>
								</Select>
							</FormControl>
							<TextField id="search5" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair5.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair5.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 5)} />
							<div style={{ width: "30px" }}>
								{(searchinputs.show5 && !searchinputs.show6) &&
									<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
										<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
									</IconButton>
								}
							</div>
						</div>
					}

					{/* Search Pair 6 */}
					{(searchinputs.show6) &&
						<div className={classes.searchinputs}>
							<FormControl variant="standard" className={classes.searchtypeinput} style={{ minWidth: "120px" }}>
								<Select
									key={localstate.searchoptions.searchpairs.searchpair6.uuid}
									value={localstate.searchoptions.searchpairs.searchpair6.type} disableUnderline
									onChange={(event) => onChangeSearchType(event.target.value, 6)}
								>
									<MenuItem value={"StationID"}>Station ID</MenuItem>
									<MenuItem value={"SerialNumber"}>Machine Serial Number</MenuItem>
									<MenuItem value={"BatterySerialNumber"}>Battery Serial Number</MenuItem>
									<MenuItem value={"BatteryNumber"}>Battery Number</MenuItem>
									<MenuItem value={"BatteryHealth"}>Battery Health</MenuItem>
									<MenuItem value={"BattDuration"}>Battery Duration</MenuItem>
									<MenuItem value={"PercentChargeStart"}>Start Charge</MenuItem>
									<MenuItem value={"PercentCharge"}>End Charge</MenuItem>
									<MenuItem value={"created_at"}>Date</MenuItem>
									<MenuItem value={"CurrentResult"}>Current Result</MenuItem>
								</Select>
							</FormControl>
							<TextField id="search6" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair6.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair6.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 6)} />
							<div style={{ width: "30px" }}>
								{(searchinputs.show6) &&
									<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
										<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
									</IconButton>
								}
							</div>
						</div>
					}


					{/* On last search input, don't show! */}
					{(!searchinputs.show6) &&
						<div style={{ display: "inline-block", paddingLeft: "15px" }}>
							<IconButton size="small" aria-label="Add Search" onClick={() => AddSearch()}>
								<AddIcon color="primary" fontSize="large" style={{ padding: "5px" }}></AddIcon>
							</IconButton>
						</div>
					}
				</div>
			}

			{/* End of Search Inputs */}


			{/* Top Buttons & Pagination */}
			{(!isPrintView) &&
				<React.Fragment>
					<div style={{ height: "5px" }}>&nbsp;</div>
					<div>
						{(!showdeleteconfirmation && !showexportconfirmation) &&
							<React.Fragment>

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => SaveChanges()}
									ref={el => btnSave.current = el}>
									<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
								</Button>

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									style={{ display: "none" }}
									onClick={() => SaveChanges()}
									ref={el => btnPendingSave.current = el}>
									<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
								</Button>

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => DeleteSelectedInit()}
									ref={el => btnDeleteSelected.current = el}>
									Delete Selected
								</Button>


								{/* ##########################  Column Toggles  ########################## */}
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									aria-haspopup="true"
									onClick={ShowColumnMenu}>
									Columns
								</Button>

								<Menu
									className={classes.bluebtn}
									color="primary"
									id="simple-menu"
									anchorEl={showcolumnmenu}
									keepMounted
									open={Boolean(showcolumnmenu)}
									onClose={CloseColumnMenu}
								>
									<MenuItem disableRipple className={classes.columnmenu}>
										<div style={{ verticalAlign: "top" }}> {/* Optional container for 2 column menu! */}
											<div style={{ display: "inline-block", maxHeight: "600px", overflow: "auto", verticalAlign: "top" }}>
												<FormGroup>
													<FlexColumnOption value="ID" label="ID" />
													<FlexColumnOption value="BootID" label="Boot ID" />
													<FlexColumnOption value="StationID" label="Station ID" />
													<FlexColumnOption value="SerialNumber" label="Serial Number" />
													<FlexColumnOption value="BatterySerialNumber" label="Battery Serial Number" />
													<FlexColumnOption value="BattModel" label="Batt Model" />
													<FlexColumnOption value="BattMfg" label="Batt Mfg" />
													<FlexColumnOption value="MachineModel" label="Machine Model" />
													<FlexColumnOption value="BatteryHealth" label="Battery Health" />
													<FlexColumnOption value="BattDuration" label="Batt Duration" />
													<FlexColumnOption value="PercentChargeStart" label="Percent Charge Start" />
													<FlexColumnOption value="PercentCharge" label="Percent Charge" />
													<FlexColumnOption value="created_at" label="Date of Result" />
													<FlexColumnOption value="CurrentResult" label="Current Result" />
												</FormGroup>
											</div>
										</div>
									</MenuItem>
								</Menu>






								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => ExpandAll()}
									ref={el => btnExpandAll.current = el}>
									Expand All
								</Button>

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => ResetSearches()}
									ref={el => btnResetSearches.current = el}>
									Reset Searches
								</Button>

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									aria-haspopup="true"
									onClick={ShowExportMenu}
									ref={el => btnExport.current = el}>
									Export
								</Button>
								<Menu id="simple-menu"
									anchorEl={showexportmenu}
									keepMounted
									open={Boolean(showexportmenu)}
									onClose={CloseExportMenu}>
									<MenuItem onClick={() => InitExport("selected")}>Export Selected</MenuItem>
									<MenuItem onClick={() => InitExport("searchresults")}>Export All (limit 2000)</MenuItem>
								</Menu>

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrintLabels()}
									ref={el => btnPrintLabels.current = el}>
									Print Labels
								</Button>

							</React.Fragment>
						}

						{/* Delete Items Confirmation */}
						{(showdeleteconfirmation) &&
							<div>
								<b>Are you sure you want to delete these Station IDs?</b>
								<div style={{ padding: "10px 0px" }}>
									{deleteitems.map((row, index) => {
										if (deleteitems.length === index + 1) {
											return (<span key={index}>{row.StationID}</span>)
										} else {
											return (<span key={index}>{row.StationID}, </span>)
										}
									})}
								</div>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained" onClick={() => DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
							</div>
						}

						{/* Export Mode Confirmation */}
						{(showexportconfirmation) &&
							<div>
								<b>Export Mode</b><br></br>
								In most cases a simple export is appropriate as to avoid revealing information to potential buyers. {exportmessage}
								<div style={{ paddingTop: "10px" }}>
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => PrepareExport("simple")}>
										Simple
									</Button>
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => PrepareExport("expanded")}>
										Expanded
									</Button>
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => PrepareExport("exhaustive")}>
										Exhaustive
									</Button>
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => setShowExportConfirmation(false)}>
										Cancel
									</Button>
								</div>
							</div>
						}



						{(localstate.totalitems > 0) &&
							<TablePagination className={classes.paginationalign}
								style={{ display: "inline-flex", float: "right" }}
								component="div"
								count={localstate.totalitems}
								page={localstate.page}
								onPageChange={handleChangePage}
								rowsPerPage={localstate.rowsperpage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								rowsPerPageOptions={[25, 50, 100]}
							/>
						}
					</div>
				</React.Fragment>
			}
			{/* End of Top Buttons & Pagination */}


			{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
			<div className={classes.flexgridcontainerA}>
				{/* ##########################  Start of Table  ########################## */}
				<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{ minWidth: "100%", borderCollapse: "collapse", borderColor: "grey" }}>
					<EnhancedTableHead
						numSelected={localstate.selectedcount}
						classes={classes}
						order={localstate.order}
						orderBy={localstate.orderby}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={state.griditems.length}
					/>
					{/* /* ##########################  Row Design  ########################## */}
					{/* If DB reload, don't allow view of table. */}
					{(!localstate.dbreload) &&
						<tbody style={{ display: "table-row-group" }}>
							{(localstate.griditems.length > 0) &&
								localstate.griditems.map((row, index) => {
									//Create all-new refs on each render. Helps avoid issues with grid states.
									rowRefs.current[index + "StationID"] = React.createRef();
									rowRefs.current[index + "SerialNumber"] = React.createRef();
									rowRefs.current[index + "BatterySerialNumber"] = React.createRef();
									rowRefs.current[index + "BatteryHealth"] = React.createRef();
									rowRefs.current[index + "BattDuraton"] = React.createRef();
									rowRefs.current[index + "PercentChargeStart"] = React.createRef();
									rowRefs.current[index + "PercentCharge"] = React.createRef();
									return (
										<React.Fragment key={row.ID}>
											<tr className={classes.flexgridrow}>
												{/* Checkbox - Requires inner div to change background color with SaveStatus */}
												<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
													<div style={{ padding: "3px 4px 1px 4px" }}>
														{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
														{/* Don't show checkbox if this is a PendingItem */}
														{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el=>rowRefs.current[index+"Checkbox"]=el} 
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}

													</div>
												</td>

												{/* ID */}
												{(colstate.ID) &&
													<td className={classes.flexgridstaticcontainer}>
														<span>
															{row.ID}
														</span>
													</td>
												}

												{/* BootID */}
												{(colstate.BootID) &&
													<td className={classes.flexgridstaticcontainer}>
														<span>
															{row.BootID}
														</span>
													</td>
												}

												{/* StationID - Optional Expand toggle! Change flexgridinput-30 to flexgridinput to remove icon spacer. */}
												{(colstate.StationID) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "StationID"] = el}
															className={classes.flexgridinput30}
															onKeyDown={(event) => HandleKeyDown(event, index, "StationID")}
															onKeyUp={(event) => onChangeValue(event, index, "StationID")}
															defaultValue={row.StationID} />
													</td>
												}

												{/* SerialNumber */}
												{(colstate.SerialNumber) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "SerialNumber"] = el}
															className={classes.flexgridinput}
															onKeyDown={(event) => HandleKeyDown(event, index, "SerialNumber")}
															onKeyUp={(event) => onChangeValue(event, index, "SerialNumber")}
															defaultValue={row.SerialNumber} />
													</td>
												}

												{/* BatteryNumber */}
												{(colstate.BatteryNumber) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "BatteryNumber"] = el}
															className={classes.flexgridinput}
															onKeyDown={(event) => HandleKeyDown(event, index, "BatteryNumber")}
															onKeyUp={(event) => onChangeValue(event, index, "BatteryNumber")}
															defaultValue={row.BatteryNumber} />
													</td>
												}

												{/* BatterySerialNumber */}
												{(colstate.BatterySerialNumber) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "BatterySerialNumber"] = el}
															className={classes.flexgridinput}
															onKeyDown={(event) => HandleKeyDown(event, index, "BatterySerialNumber")}
															onKeyUp={(event) => onChangeValue(event, index, "BatterySerialNumber")}
															defaultValue={row.BatterySerialNumber} />
													</td>
												}

												{/* BattModel */}
												{(colstate.BattModel) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "BattModel"] = el}
															className={classes.flexgridinput}
															onKeyDown={(event) => HandleKeyDown(event, index, "BattModel")}
															onKeyUp={(event) => onChangeValue(event, index, "BattModel")}
															defaultValue={row.BattModel} />
													</td>
												}

												{/* BattMfg */}
												{(colstate.BattMfg) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "BattMfg"] = el}
															className={classes.flexgridinput}
															onKeyDown={(event) => HandleKeyDown(event, index, "BattMfg")}
															onKeyUp={(event) => onChangeValue(event, index, "BattMfg")}
															defaultValue={row.BattMfg} />
													</td>
												}

												{/* MachineModel */}
												{(colstate.MachineModel) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "MachineModel"] = el}
															className={classes.flexgridinput}
															onKeyDown={(event) => HandleKeyDown(event, index, "MachineModel")}
															onKeyUp={(event) => onChangeValue(event, index, "MachineModel")}
															defaultValue={row.MachineModel} />
													</td>
												}

												{/* BatteryHealth */}
												{(colstate.BatteryHealth) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "BatteryHealth"] = el}
															className={classes.flexgridinput}
															onKeyDown={(event) => HandleKeyDown(event, index, "BatteryHealth")}
															onKeyUp={(event) => onChangeValue(event, index, "BatteryHealth")}
															defaultValue={row.BatteryHealth} />
													</td>
												}

												{/* BattDuration */}
												{(colstate.BattDuration) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "BattDuration"] = el}
															className={classes.flexgridinput}
															onKeyDown={(event) => HandleKeyDown(event, index, "BattDuration")}
															onKeyUp={(event) => onChangeValue(event, index, "BattDuration")}
															defaultValue={row.BattDuration} />
													</td>
												}

												{/* PercentChargeStart */}
												{(colstate.PercentChargeStart) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "PercentChargeStart"] = el}
															className={classes.flexgridinput}
															onKeyDown={(event) => HandleKeyDown(event, index, "PercentChargeStart")}
															onKeyUp={(event) => onChangeValue(event, index, "PercentChargeStart")}
															defaultValue={row.PercentChargeStart} />
													</td>
												}

												{/* PercentCharge */}
												{(colstate.BatteryNumber) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "PercentCharge"] = el}
															className={classes.flexgridinput}
															onKeyDown={(event) => HandleKeyDown(event, index, "PercentCharge")}
															onKeyUp={(event) => onChangeValue(event, index, "PercentCharge")}
															defaultValue={row.PercentCharge} />
													</td>
												}

												{/* created_at */}
												{(colstate.created_at) &&
													<td className={classes.flexgridstaticcontainer}>
														<span>
															{row.created_at}
														</span>
													</td>


												}

												{/* CurrentResult */}
												{(colstate.CurrentResult) &&
													<td>
														<div style={{ padding: "4px 4px 1px 4px", textAlign: "center" }}>
															<Checkbox
																className={classes.gridcheckbox}
																disableRipple
																color="default"
																defaultChecked={row.CurrentResult === 1 ? true : false}
																checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																icon={<span className={classes.icon} />}
																onChange={(event) => onChangeValue(event, index, "CurrentResult")}
															/>
														</div>
													</td>
												}

											</tr>
											{/* Try: conditional for any render whatsoever! */}
											{(row.ExpandRow === true) &&
												<tr>
													<td colSpan="100%">
														<div style={{ margin: "25px" }}>
															Potential Batt Stats Page. Submit proposal, signed, in triplicate to the front desk.
														</div>
													</td>
												</tr>
											}
										</React.Fragment>

									)
								}
								)
							}
							{(localstate.griditems.length === 0) &&
								<tr className="flexgridrow"><td colSpan="100%"
									style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
							}
						</tbody>
					}
					{(localstate.dbreload) &&
						<tbody>
							<tr>
								<td colSpan="100%">
									<div style={{ padding: "20px", textAlign: "center", margin: "auto" }}>
										<CircularProgress />
									</div>
								</td>
							</tr>
						</tbody>
					}
				</table>
			</div>




			{(localstate.totalitems > localstate.rowsperpage) &&
				<TablePagination className={classes.paginationalign}
					component="div"
					count={localstate.totalitems}
					page={localstate.page}
					onPageChange={handleChangePage}
					rowsPerPage={localstate.rowsperpage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPageOptions={[25, 50, 100]}
				/>
			}


		</div>
	);
}


export default BatteryTest;
