import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';



const FlexTablePagination = (props) => {
	let localstate = props.localstate;
	let classes = props.classes;
	let editmode = props.editmode;
	let handleChangePage = props.handleChangePage;
	let handleChangeRowsPerPage = props.handleChangeRowsPerPage;


	return(
		<TablePagination className={classes.paginationalign}
		sx={{
			'& .MuiTablePagination-selectLabel': {
				display: 'none', // Hide the rows per page label
			},
			'& .MuiSelect-select': {
				display: (editmode && localstate.rowsperpage >= localstate.maxrowseditmode) ? 'none' : "flex",
				minWidth: 'auto',  // Allow the select to shrink
				paddingRight: '24px', // Ensure dropdown icon is visible
				textAlign: 'center', // Center the selected text inside the select box
			},
			'& .MuiSelect-selectMenu': {
				display: (editmode && localstate.rowsperpage >= localstate.maxrowseditmode) ? 'none' : "flex",
				width: 'auto', // Adjust width of the select menu
			},
			'& .MuiInputBase-root': {
				display: (editmode && localstate.rowsperpage >= localstate.maxrowseditmode) ? 'none' : "flex",
				width: 'auto', // Adjust width of the select menu
			},
			'& .MuiMenu-paper': {
				minWidth: 'auto', // Allow the options menu to shrink
				width: 'auto', // Set a custom width if needed
			},
		}}
		style={{ display: "inline-flex", float: "right" }}
		component="div"
		count={localstate.totalitems}
		page={localstate.page}
		onPageChange={handleChangePage}
		rowsPerPage={localstate.rowsperpage}
		onRowsPerPageChange={handleChangeRowsPerPage}
		rowsPerPageOptions={localstate.rowsperpageoptions}
		//Optional First and Last Buttons!
		showFirstButton showLastButton
		//labelRowsPerPage="Items per page:" // Custom label for rows per page
		labelDisplayedRows={({ from, to, count }) => {
			return (
				<span>
					{from}-{to} of {count !== -1 ? count : `more than ${to}`}
				</span>
			)
		}
		}
		slotProps={{
			select: {
				// inputProps: {
				// 	sx: {
				// 		display:"none"
				// 	},
				// },
				sx: {
					//display:"none", //Does nothing
					minWidth: '75px', // Adjust the width of the select box
					textAlign: 'center', // Center the text in the select box
					// display: (editmode && localstate.rowsperpage >= localstate.maxrowseditmode) ? 'none' : {
					// 	xs: 'none',  // Hide on extra small screens
					// 	sm: 'flex', // Show on small screens and up
					// },
				},
				renderValue: (value) => <Box>
					Limit: {value}
				</Box>,
				MenuProps: {
					PaperProps: {
						sx: {
							// display: (editmode && localstate.rowsperpage >= localstate.maxrowseditmode) ? 'none' : {
							// 	xs: 'none',  // Hide on extra small screens
							// 	sm: 'flex', // Show on small screens and up
							// },
							//display:"none",
							'& .MuiMenuItem-root': {
								fontSize: '0.875rem', // Custom font size for menu items
								display: 'flex',
								justifyContent: 'center', // Center horizontally
								textAlign: 'center', // Center text
							},
							'& .Mui-selected': {
								'&:hover': {
									backgroundColor: 'darkblue', // Custom hover color for selected item
								},
							},
						},
					},
				},
			},
		}}
	/>
	)
}

    


export default FlexTablePagination;