//Item View

/* ##########################  Configuration Sections  ########################## */
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions


import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import fetch from 'cross-fetch';
import cloneDeep from 'lodash/cloneDeep';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';

//Datetime formatting
import Moment from 'react-moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; //Possibly our new adapter for mui x 7 datetime
import dayjs from 'dayjs'; //Used with new datetimepickers
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//Common Utilities
import RestrictInputNumber from "../common/RestrictInputNumber";

//Inventory Toolbar
import InventoryToolbar from './InventoryToolbar';

//Contexts
import { AppContext, AppProvider } from "../Auth/contexts/AppContext";
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

//Icons
import PendingIcon from '@mui/icons-material/Pending';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import CheckIcon from '@mui/icons-material/Check';

//Buttons
import ButtonGroup from '@mui/material/ButtonGroup';

//Accordions
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';

//Export
import ExportCSV from '../common/ExportCSV';

//Record History
import RecordHistory from '../common/RecordHistory';


/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	//timeout:11800,
	timeout: 300000
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};



const Item = (props) => {
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole, userName } = appContext;

	//URL Param for ItemID
	let { id } = useParams();

	let history = useHistory();

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");


	const rowRefs = useRef([]);
	const btnSave = useRef();
	const btnPendingSave = useRef();

	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		//Need to initialize a blank item
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		id: id, //TRY - Init new id via URL params. If we update ID from results on SaveChanges, we can avoid having to deal with URL params on history.push (which doesn't seem to change much more than change the current URL, not useState variables.)
		pagereload: false,
		itemloaded: false, //POSSIBLY MOST IMPORTANT! Using defaultValue on an input causes issues if you try to render before item is loaded.
		pendingupdate: false,
		auditview: false,
		historyavailable: false,
		recordhistory: [],
		historyindex: 0,
		itemdiffs: [],
		productsearchterm: '',
		itemdata: {
			product: {
				Name: 'Default'
			},
			damagedconditions: [],
			majorconditions: [],
			minorconditions: [],
			Status: "",
			lot: {
				LotName: ""
			},
			manifests: [],
			specs: []
		},
		ProductNamePlaceholder: "Default Name1",
		ItemStatusPlaceholder: "",
		//New Conditions
		newconditiontype: "Minor",
		setcondition: false, //For condition updates, we set this to true temporarily. After reload, set this back to false.
		newconditiontext: "",
		//Delete Conditions
		deletecondition: false,
		deleteconditionid: '',
		historymode: false,



	});

	//Some inputs will need to work via refs. Let's try to codify it, possibly even use it elsewhere.
	//Try Object....
	const itemRefs = useRef({
		CostEl: "" //Will be Cost input ref element, not blank string?
	})

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Item
	function LoadItem(itemid) {

		//To Do: Chain load item and previous item state.
		//Warn user if the current record is old.
		//If admin, allow rollbacks.
		const postdata = {
			itemid: itemid,
			loadhistory: localstate.loadhistory
		};
		axios.post(dbendpoint + "/items/getitembyid", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {



					localstate.itemdata = res.data.item;

					//RECORD HISTORY DROP IN
					if (localstate.itemdata.hasOwnProperty('RecordHistory')) {
						if (localstate.itemdata.RecordHistory) {
							localstate.recordhistory = localstate.itemdata.RecordHistory;
							//Sort by updated_at asc (Change a/b for desc)
							localstate.recordhistory = localstate.recordhistory.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
							if (localstate.recordhistory.length > 0) {
								localstate.historyavailable = true;
							}
						}
					}
					//Adjust Datetimes in Record History
					//This may be converting them all to objects which will always show as a diff
					// for (var j=0; j<localstate.recordhistory.length; j++){
					// 	localstate.recordhistory[j].created_at = dayjs(localstate.recordhistory[j].created_at);
					// 	localstate.recordhistory[j].updated_at = dayjs(localstate.recordhistory[j].updated_at);
					// 	localstate.recordhistory[j].ItemCreationDate = dayjs(localstate.recordhistory[j].ItemCreationDate);
					// }



					//Evaluate Editable:
					localstate.editable = false;
					//If user can create and the item is PendingItem, allow a single edit.
					if (localstate.itemdata.PendingItem === 1 && userPerms.createItem === 1) {
						localstate.editable = true;
					} else if (userPerms.updateItem === 1) {
						localstate.editable = true;
					}

					//Datetime Formatting
					localstate.itemdata.created_at = dayjs(localstate.itemdata.created_at);
					localstate.itemdata.updated_at = dayjs(localstate.itemdata.updated_at);
					localstate.itemdata.ItemCreationDate = dayjs(localstate.itemdata.ItemCreationDate);


					//Set value for Product Selection
					if (res.data.item.product) {
						//setProductSearchTerm(res.data.item.product.Name)
						localstate.productsearchterm = res.data.item.product.Name;
						localstate.modelplaceholder = res.data.item.product.Name;
						//To Do: if condition for db.product.ProductOverridesModel, setModelPlaceholder
						//setModelPlaceholder(res.data.item.product.Name);
					}
					//Cost is always nice to have pre-formatted
					localstate.itemdata.Cost = parseFloat(res.data.item.Cost).toFixed(2);
					// SetGrade(res.data.item.Grade, true);
					// if (res.data.item.BodyGrade){
					// 	SetBodyGrade(res.data.item.BodyGrade, true);
					// }

					//Reset condition flag
					localstate.setcondition = false;
					localstate.newconditiontext = "";

					//I am suspecting we should recover current conditions for each history entry so we can easily render them.

					if (localstate.loadhistory) {
						EvaluateConditions();
					}

					localstate.dbreload = false;
					localstate.itemloaded = true; //Now allow render
					UpdateState(localstate);
				}
				//Provision to push user to newest URL of item:
				if (res.data.Status === "Reload") {
					localstate.id = res.data.item["ItemID"];
					localstate.dbreload = true;
					UpdateState(localstate);
					history.push(res.data.item["ItemID"].toString());
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.dbreload = false;
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}

	//We have loads and saves that need to reevaluate localstate.itemdata and localstate.itemhistory.
	const EvaluateConditions = () => {
		console.log("Evaluating conditions.");
		//Re-evaluating Conditions. We can wipe out current, most recent history of components, then add/remove according to history
		localstate.itemhistory[0].minorconditions = [];
		localstate.itemhistory[0].majorconditions = [];
		localstate.itemhistory[0].damagedconditions = [];


		//We will go in chronological order, reverse the history array
		localstate.itemhistory = localstate.itemhistory.reverse();

		//Loop through current conditions and add to previous history records
		var i = 0;
		var j = 0;
		var k = 0;
		var l = 0;

		//Marshal IN
		for (i = 0; i < localstate.itemhistory.length; i++) {
			//console.log(localstate.itemhistory[i].ItemID);
			for (j = 0; j < localstate.itemhistory[i].addedminorconditions.length; j++) {
				for (k = (i + 1); k < localstate.itemhistory.length; k++) {
					console.log("I - K: " + i + " - " + k);
					localstate.itemhistory[k].minorconditions.push(localstate.itemhistory[i].addedminorconditions[j]);
				}
			}
		}
		for (i = 0; i < localstate.itemhistory.length; i++) {
			for (j = 0; j < localstate.itemhistory[i].addedmajorconditions.length; j++) {
				for (k = (i + 1); k < localstate.itemhistory.length; k++) {
					localstate.itemhistory[k].majorconditions.push(localstate.itemhistory[i].addedmajorconditions[j]);
				}
			}
		}
		for (i = 0; i < localstate.itemhistory.length; i++) {
			for (j = 0; j < localstate.itemhistory[i].addeddamagedconditions.length; j++) {
				for (k = (i + 1); k < localstate.itemhistory.length; k++) {
					localstate.itemhistory[k].damagedconditions.push(localstate.itemhistory[i].addeddamagedconditions[j]);
				}
			}
		}

		//Marshal OUT
		for (i = 0; i < localstate.itemhistory.length; i++) {
			for (j = 0; j < localstate.itemhistory[i].removedminorconditions.length; j++) {
				for (k = i; k < localstate.itemhistory.length; k++) {
					for (l = 0; l < localstate.itemhistory[k].minorconditions.length; l++) {
						if (localstate.itemhistory[i].removedminorconditions[j].ConditionID === localstate.itemhistory[k].minorconditions[l].ConditionID) {
							localstate.itemhistory[k].minorconditions.splice(l, 1);
						}
					}
				}
			}
		}
		for (i = 0; i < localstate.itemhistory.length; i++) {
			for (j = 0; j < localstate.itemhistory[i].removedmajorconditions.length; j++) {
				for (k = i; k < localstate.itemhistory.length; k++) {
					for (l = 0; l < localstate.itemhistory[k].majorconditions.length; l++) {
						if (localstate.itemhistory[i].removedmajorconditions[j].ConditionID === localstate.itemhistory[k].majorconditions[l].ConditionID) {
							localstate.itemhistory[k].majorconditions.splice(l, 1);
						}
					}
				}
			}
		}
		for (i = 0; i < localstate.itemhistory.length; i++) {
			for (j = 0; j < localstate.itemhistory[i].removeddamagedconditions.length; j++) {
				for (k = i; k < localstate.itemhistory.length; k++) {
					for (l = 0; l < localstate.itemhistory[k].damagedconditions.length; l++) {
						if (localstate.itemhistory[i].removeddamagedconditions[j].ConditionID === localstate.itemhistory[k].damagedconditions[l].ConditionID) {
							localstate.itemhistory[k].damagedconditions.splice(l, 1);
						}
					}
				}
			}
		}
	}




	//IMPORTANT: A big fix here was avoiding DB reloads when the state hasn't changed. Making this useEffect dependent on state changes avoids duplicate loads.
	useEffect(() => {
		//To Do: On load of item, set title to the serial number.
		document.title = "Item";
		if (state.dbreload) {
			//Avoid duplicate loads.
			localstate.dbreload = false;
			//Diagnostic:
			console.log("Localstate ID: " + localstate.id);
			LoadItem(localstate.id);
		} else {
			//Data Loaded:
			//Sync Grade btns
			SetGrade(localstate.itemdata.Grade, true);
			if (localstate.itemdata.AssetType === "Laptop" || localstate.itemdata.AssetType === "AIO") {
				SetBodyGrade(localstate.itemdata.BodyGrade, true);
				SetLCDGrade(localstate.itemdata.LCDGrade, true);
			}
		}
	}, [state]);


	//New method to target what is sent from a datetime picker:
	const onChangeDatetimeValue = (newvalue, itemkey) => {
		localstate.itemdata[itemkey] = newvalue;
		localstate.pendingsaves = true;
		//Old
		// btnSave.current.style.color = "white";
		// btnSave.current.style.backgroundColor = "#01579B";
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		UpdateState(localstate);
	}


	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) => {
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (index > 0) {
			var decimalchars = (len - 1) - index;
			if (decimalchars > 2) {
				return oldvalue;
			}
		}
		return newvalue;
	}

	//Restrict Number to Integer - Don't accept any decimal.
	const RestrictNumberInteger = (newvalue, oldvalue) => {
		console.log(newvalue);
		if (newvalue < 0) {
			return 0;
		} else {
			return parseInt(newvalue);
		}
	}

	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	const SaveChanges = (checkbackinbool) => {
		console.log("#######################");
		console.log(localstate.itemdata);
		/* About:
		Changes may OR MAY NOT reload the database item. Updates are cheaper if they don't
		involve a data fetch. Assuming a successful update, we can simple hold our view.
		New fetches of data involve a heavy set of relationships that would all need to be reloaded.
		Key: When any item changes ID, we reload the data. We hold a consistent ID after the first update.
		We know we need to reload the data if the API returns an OldID (the outdated\placeholder ID).
		*/

		/* Unfortunately it looks like we need to add conditions in our view before we go on to update other fields. */

		//Basic form check:
		if (localstate.itemdata.SerialNumber === "") {
			errors.NewError({ errmsg: "Serial number can not be blank!", errshow: true, errtimeout: 15, errtype: "warning" });
		} else {
			var postdata = {
				item: localstate.itemdata
			};
			if (checkbackinbool) {
				postdata.item.CheckBackIn = true;
			}
			if (deleteitem) {
				postdata.item.DeleteItem = true;
			}
			if (localstate.setcondition) {
				postdata.item.NewCondition = true; //Flag in API to trigger new condition.
				postdata.item.NewConditionType = localstate.newconditiontype;
				postdata.item.NewConditionText = localstate.newconditiontext;
			}
			if (localstate.deletecondition) {
				postdata.item.DeleteCondition = true;
				postdata.item.DeleteConditionID = localstate.deleteconditionid;
			}
			postdata.item.loadhistory = localstate.loadhistory;


			axios.post(dbendpoint + "/items/updateitem", postdata, defaultpostoptions).then(res => {
				//API should be setup to send 200 response with status. Merge paginated requests.
				if (res.status === 200) {
					//If ValidateUser() fails to verify user, it sends back 'login' error. 
					if (res.data.Status === "login") {
						//Not logged in. Reload page causes redirect to /login
						window.location.reload(false);
					}
					//All new API calls should return a status.
					if (res.data.Status === "Success") {
						//Shortcut to Inventory Manager if the item was marked for deletion
						btnSave.current.style.display = "";
						btnPendingSave.current.style.display = "none";
						//Attempt to replace: btnSave.current.disabled = true;
						localstate.pendingsaves = false;
						localstate.historymode = false;

						if (res.data.OldID) {
							localstate.id = res.data.item["ItemID"];
							//API has been modified to send the needed itemdata (including nested relationships)
							localstate.itemdata = res.data.item;
							UpdateState(localstate);
						} else {
							//CheckBackIn will change manifest and Status values and needs resync.
							localstate.dbreload = true;
							UpdateState(localstate);
						}
						if (localstate.auditview) { //Condition text input does not exist inside audit view.
							localstate.auditview = false;
						} else {
							conditiontextref.current.value = "";
						}

					}
					if (res.data.Status === "Deleted") {
						history.push("/inventorymanager");
						errors.NewError({ errmsg: '', errshow: false, errtimeout: 0, errtype: "ok" })
					}
					if (res.data.Status === "Failure") {
						//Failure error
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 15, errtype: "warning" })
					}
				} else {
					//Non-200 message from server.
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" })
				}
			});
		}
	}



	//Changes to item
	const onChangeValue = (event, itemkey) => {
		var oldvalue = localstate.itemdata[itemkey];
		console.log("Oldvalue: " + oldvalue);
		var newvalue = event.target.value;
		console.log("Newvalue: " + newvalue);

		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {

			if (itemkey === "Cost") {
				if (!oldvalue) {
					oldvalue = "0";
				}
				//A new value from RestrictInputNumber means it will override the input. We'll attempt to place the cursor back where it was. Otherwise, take in new value and do not adjust the input.
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float"); //All arguments required. New universal function for all pages. However handle result below.
				if (newvalue) {
					itemRefs.current[itemkey + "El"].value = newvalue;
					localstate.itemdata[itemkey] = newvalue;
					//Attempt to refocus and place cursor at the end of the input - This will require we switch the input to text, then back to number:
					const inputElement = itemRefs.current[itemkey + "El"];
					inputElement.type = 'text';
					inputElement.focus();
					inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
					inputElement.type = 'number';
				} else {
					console.log("No newvalue returned, set value in localstate, but leave input untouched.");
					console.log(parseFloat(event.target.value).toFixed(2));
					localstate.itemdata[itemkey] = parseFloat(event.target.value).toFixed(2);
				}
			}

			if (itemkey === "RepairNote") {
				setRepairWarning(true);
				//We don't need this, DB should make new date. localstate.itemdata.RepairDate = new Date();
				localstate.itemdata.RepairedByName = userName;
			}


			//Update Value if they weren't already asigned above. (Everything else)
			if (itemkey !== "Cost") {
				localstate.itemdata[itemkey] = newvalue;
			}


			//localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
			//btnSave.current.disabled = false;
			//Old
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			UpdateState(localstate);
		}

	}

	//Record History
	const ViewRecordHistory = () => {
		//Initiate record history and load last history item for immediate viewing!
		var historylength = localstate.recordhistory.length;
		//Use length-1 for last index
		localstate.historyindex = historylength - 1;
		//localstate.itemdata = Object.assign({}, localstate.recordhistory[historylength-1]);
		localstate.itemdata = cloneDeep(localstate.recordhistory[historylength - 1]);
		//Diffs
		if ((historylength - 1) !== 0) {
			GetDiffs(localstate.itemdata, localstate.recordhistory[localstate.recordhistory.length - 2]);
		} else {
			//Reset diffs:
			for (let key in localstate.itemdata) {
				localstate.itemdiffs[key] = {};
				localstate.itemdiffs[key].dirty = false;
			}
		}

		//Pre-Formatted currency
		localstate.itemdata.Cost = parseFloat(localstate.recordhistory[localstate.recordhistory.length - 1].Cost).toFixed(2);
		localstate.auditview = true;
		UpdateState(localstate);
	}

	const ViewRecord = (newindex) => {
		//Provision to go back to live view:
		if (newindex === localstate.recordhistory.length) {
			//All out of history records, go back to live
			localstate.dbreload = true;
			localstate.auditview = false;
			localstate.itemloaded = false; //Helps rerender! No kidding. After reload, it'll force rerender contents.
			UpdateState(localstate);
		} else {
			localstate.historyindex = newindex;
			localstate.itemdata = localstate.recordhistory[newindex];
			//Diffs
			localstate.itemdiffs = {};
			if (newindex !== 0) {
				GetDiffs(localstate.itemdata, localstate.recordhistory[newindex - 1]);
			} else {
				//Reset diffs:
				for (let key in localstate.itemdata) {
					localstate.itemdiffs[key] = {};
					localstate.itemdiffs[key].dirty = false;
				}
			}

			//Pre-Formatted currency
			localstate.itemdata.Cost = parseFloat(localstate.itemdata.Cost).toFixed(2);
			UpdateState(localstate);
		}

	}

	const GetDiffs = (currentrecord, previousrecord) => {
		console.log("Getting diffs...");
		for (let key in currentrecord) {
			localstate.itemdiffs[key] = {};


			//Let's see if we can handle minorconditons majorconditions and damagedconditions
			if (key === "minorconditions" || key === "majorconditions" || key === "damagedconditions") {
				//It is possible that the previous record doesn't have any components
				if (previousrecord.hasOwnProperty(key)) {
					//It's VERY likely we can just check for length change here.
					if (currentrecord[key].length !== previousrecord[key].length) {
						localstate.itemdiffs[key].dirty = true;
					} else {
						localstate.itemdiffs[key].dirty = false;
						continue;
					}
				} else {
					localstate.itemdiffs[key].dirty = false;
					continue;
				}
			}

			if (key === "ItemCreationDate") {
				console.log("ItemCreationDate Current: " + currentrecord[key]);
				console.log("ItemCreationDate Previous: " + previousrecord[key]);

				if (currentrecord[key] !== previousrecord[key]) {
					console.log("Doesn't match!");
					console.log(typeof currentrecord[key]);
					console.log(typeof previousrecord[key]);
				}
			}

			if (currentrecord[key] !== previousrecord[key]) {
				localstate.itemdiffs[key].dirty = true;
				//We can also setup some kind of greaterthan / lessthan setup one day. 
				//localstate.itemdiffs[key].greaterthanvalue = something;
			} else {
				localstate.itemdiffs[key].dirty = false;
			}
		}
	}

	const RestoreRecord = () => {
		//Notice: YOU WILL NEED TO MAKE ADJUSTMENTS TO YOUR API TO INCLUDE NESTED RELATIONSHIPS
		//Assuming everything is stored in localstate.itemdata (our traversal of RecordHistory helps this)
		//Let's try to send it right back to our update:
		//Send a flag with our item so our update() api endpoint can make specific adjustments.
		localstate.itemloaded = false; //Forces rerender.
		localstate.itemdata["RestoreRecord"] = 1;
		//localstate.auditview = false; We need to use this value after a response in SaveChanges. Be sure to set to false there!
		SaveChanges();
	}



	const [openproductoptions, openProductOptions] = React.useState(false);
	const [productoptions, setProductOptions] = React.useState([]);
	const [loadingproductoptions, setLoadingProductOptions] = useState(false);
	const [modelplaceholder, setModelPlaceholder] = useState("");
	const InitProductOptions = () => {
		if (productoptions.length === 0) {
			ProductSearch("");
		}
		openProductOptions(true);
	}

	const onCloseProductOptions = (event) => {
		openProductOptions(false);
	}

	const onChangeProductOption = (event, newvalue) => {
		//Set value for current search
		// Example:
		// localstate.searchoptions.searchpairs.searchpairX.type=autocompletesearchtypeX;
		// localstate.searchoptions.searchpairs.searchpairX.value=newvalue[autocompletesearchtypeX];
		// localstate.dbreload = true;
		// UpdateState(localstate);

		//Or set value for your item:
		//localstate.itemdata['ProductID'] = newvalue['ProductID'];

		//Or just yell at the user, the ProductID they just set
		//alert(newvalue['ProductID']);
		if (newvalue['ProductOverridesModel']) {
			localstate.itemdata['Model'] = newvalue['Name'];
			localstate.modelplaceholder = newvalue['Name'];
		}

		localstate.itemdata['ProductID'] = newvalue['ProductID'];
		localstate.itemdata['AssetType'] = newvalue['ProductType'];

		//btnSave.current.disabled = false;
		//Old
		// btnSave.current.style.color="white";
		// btnSave.current.style.backgroundColor="#01579B";
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		localstate.pendingsaves = true;
		UpdateState(localstate);
	}

	const ProductSearch = debounce(function (searchvalue) {
		//setProductSearchTerm(searchvalue);
		localstate.productsearchterm = searchvalue;
		setLoadingProductOptions(true);
		const postdata = {
			search: searchvalue,
			limit: 20
		};
		axios.post(dbendpoint + "/items/searchproducts", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					setProductOptions(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
			setLoadingProductOptions(false);
		});
	}, 600);

	/* GRADES */
	const btnGradeA = useRef();
	const btnGradeB = useRef();
	const btnGradeC = useRef();
	const btnGradeRepair = useRef();
	const btnGradeScrap = useRef();

	const SetGrade = (grade, init) => {
		if (!init) {
			localstate.itemdata.Grade = grade;
		}
		if (grade === "A") {
			btnGradeA.current.classList.add(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "B") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.add(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "C") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.add(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Repair") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.add(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Scrap") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.add(classes.flexactivebtn);
		}

		if (!init) {
			//btnSave.current.disabled = false;
			//Old
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			localstate.pendingsaves = true;
			UpdateState(localstate);
		}
	}

	/* Body Grades */
	const btnBodyGradeA = useRef();
	const btnBodyGradeB = useRef();
	const btnBodyGradeC = useRef();
	const btnBodyGradeBad = useRef();

	const SetBodyGrade = (grade, init) => {
		if (!init) {
			localstate.itemdata.BodyGrade = grade;
		}
		if (grade === "A") {
			btnBodyGradeA.current.classList.add(classes.flexactivebtn);
			btnBodyGradeB.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeC.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "B") {
			btnBodyGradeA.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeB.current.classList.add(classes.flexactivebtn);
			btnBodyGradeC.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "C") {
			btnBodyGradeA.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeB.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeC.current.classList.add(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Bad") {
			btnBodyGradeA.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeB.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeC.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.add(classes.flexactivebtn);
		}

		if (!init) {
			//btnSave.current.disabled = false;
			//Old
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			localstate.pendingsaves = true;
			UpdateState(localstate);
		}
	}

	/* LCD Grades */
	const btnLCDGradeA = useRef();
	const btnLCDGradeB = useRef();
	const btnLCDGradeC = useRef();
	const btnLCDGradeBad = useRef();

	const SetLCDGrade = (grade, init) => {
		if (!init) {
			localstate.itemdata.LCDGrade = grade;
		}
		if (grade === "A") {
			btnLCDGradeA.current.classList.add(classes.flexactivebtn);
			btnLCDGradeB.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeC.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "B") {
			btnLCDGradeA.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeB.current.classList.add(classes.flexactivebtn);
			btnLCDGradeC.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "C") {
			btnLCDGradeA.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeB.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeC.current.classList.add(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Bad") {
			btnLCDGradeA.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeB.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeC.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.add(classes.flexactivebtn);
		}

		if (!init) {
			//localstate.pendingsaves = true;
			//btnSave.current.disabled = false;
			//Old
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			localstate.pendingsaves = true;
			UpdateState(localstate);
		}
	}



	/* CONDITIONS */
	const btnMinor = useRef();
	const btnMajor = useRef();
	const btnDamaged = useRef();
	const conditiontextref = useRef();

	const SelectCondition = (conditiontype) => {
		localstate.newconditiontype = conditiontype;
		if (conditiontype === "Minor") {
			btnMinor.current.classList.add(classes.flexactivebtn);
			btnMajor.current.classList.remove(classes.flexactivebtn);
			btnDamaged.current.classList.remove(classes.flexactivebtn);
		}
		if (conditiontype === "Major") {
			btnMinor.current.classList.remove(classes.flexactivebtn);
			btnMajor.current.classList.add(classes.flexactivebtn);
			btnDamaged.current.classList.remove(classes.flexactivebtn);
		}
		if (conditiontype === "Damaged") {
			btnMinor.current.classList.remove(classes.flexactivebtn);
			btnMajor.current.classList.remove(classes.flexactivebtn);
			btnDamaged.current.classList.add(classes.flexactivebtn);
		}
	}

	const onChangeCondition = (event, newvalue) => {
		//console.log(event.target.value);
		if (event.key === "Enter") {
			if (event.target.value.length < 2) {
				errors.NewError({ errmsg: "Conditions must be 2 characters or more.", errshow: true, errtimeout: 5, errtype: "warning" })
			} else {
				console.log(event.target.value);
				//To Do: Query DB for duplicate before saving changes:

				var conditiondetails = {
					ItemID: localstate.itemdata.ItemID,
					ConditionText: event.target.value,
					ConditionType: localstate.newconditiontype
				}

				axios.post(dbendpoint + "/items/checkdupecondition", { conditiondetails: conditiondetails }, defaultpostoptions).then(res => {
					if (res.status === 200) {
						if (res.data.Status === "login") {
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Assume we're moving to do the update immediately.
							localstate.setcondition = true;
							localstate.newconditiontext = event.target.value;
							SaveChanges(false);
						} else {
							errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "warning" });
						}
					} else {
						//Non-200 message from server.
						errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" });
					}
				});
			}
		}
	}


	const DeleteCondition = (conditionid) => {
		localstate.deletecondition = true;
		localstate.deleteconditionid = conditionid;
		SaveChanges(false);


		/* Replace:
		axios.post(dbendpoint+"/items/deletecondition", {ConditionID:conditionid, ItemID:localstate.itemdata.ItemID}, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					console.log(res.data);

					//console.log(conditiontextref);
					//conditiontextref.current.value="";
					localstate.dbreload = true;
					UpdateState(localstate);
				} else {
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"warning"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
		*/
	}



	const [deleteitem, setDeleteItem] = useState(false);
	const DeleteItem = () => {
		if (!deleteitem) {
			errors.NewError({ errmsg: "Item has been marked for deletion. Click 'Save Changes' to complete deletion.", errshow: true, errtimeout: 300, errtype: "warning" });
		} else {
			errors.NewError({ errmsg: "Canceled.", errshow: true, errtimeout: 1, errtype: "OK" });
		}
		setDeleteItem(!deleteitem);
		//Old
		// btnSave.current.style.color="white";
		// btnSave.current.style.backgroundColor="#01579B";
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		localstate.pendingsaves = true;
		UpdateState(localstate);
	}


	//Currently not used - could be setup, but don't really want people changing already deleted items.
	const [undeleteitem, setUndeleteItem] = useState(false);
	const UndeleteItem = () => {
		if (!undeleteitem) {
			errors.NewError({ errmsg: "Item has been marked for deletion recovery. Click 'Save Changes' to complete recovery.", errshow: true, errtimeout: 300, errtype: "warning" });
		} else {
			errors.NewError({ errmsg: "Canceled.", errshow: true, errtimeout: 1, errtype: "OK" });
		}
		setUndeleteItem(!undeleteitem);
		//Old
		// btnSave.current.style.color="white";
		// btnSave.current.style.backgroundColor="#01579B";
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		localstate.pendingsaves = true;
		UpdateState(localstate);
	}

	const [repairwarning, setRepairWarning] = useState(false);


	//Accordions
	const Accordion = styled((props) => (
		<MuiAccordion disableGutters elevation={0} square {...props} />
	))(({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
	}));

	const AccordionSummary = styled((props) => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...props}
		/>
	))(({ theme }) => ({
		backgroundColor:
			theme.palette.mode === 'dark'
				? 'rgba(255, 255, 255, .05)'
				: 'rgba(0, 0, 0, .03)',
		flexDirection: 'row-reverse',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)',
		},
		'& .MuiAccordionSummary-content': {
			marginLeft: theme.spacing(1),
		},
	}));

	const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
		padding: theme.spacing(0),
		borderTop: '1px solid rgba(0, 0, 0, .125)',
	}));

	const PrintLabel = (type) => {
		var params = {
			itemid: localstate.itemdata.ItemID,
			labeltype: type,
			autoclose: true
		};
		// Creating a form
		var form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", dbendpoint + "/labels");
		form.setAttribute("target", "NewLabel");
		for (var i in params) {
			if (params.hasOwnProperty(i)) {
				// Creating the input
				var input = document.createElement('input');
				input.type = 'hidden';
				input.name = i;
				input.value = params[i];

				// Attach input to form
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		form.submit();

	}


	/* Utility Function - Attempt to Update Tables: */
	/* Not used.
	const UpdateManifests = () =>{
		axios.post(dbendpoint+"/items/updatemanifests", {}, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}
	*/

	const FlagMDM = (mdmtype) => {
		//Sample postdata.
		const postdata = {
			item: localstate.itemdata,
			mdmtype: mdmtype
		};
		axios.post(dbendpoint + "/items/flagmdm", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					console.log(res);
					errors.NewError({ errmsg: "MDM flag set successfully.", errshow: true, errtimeout: 5, errtype: "ok" });
					localstate.dbreload = true;
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 10, errtype: "neutral" });
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" });
			}
		});
	}

	const FlagMDMReported = (mdmtype) => {
		//Sample postdata.
		const postdata = {
			item: localstate.itemdata,
			mdmtype: mdmtype
		};
		axios.post(dbendpoint + "/items/flagmdmreported", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					console.log(res);
					errors.NewError({ errmsg: "MDM flag set successfully.", errshow: true, errtimeout: 5, errtype: "ok" });
					localstate.dbreload = true;
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 10, errtype: "neutral" });
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" });
			}
		});
	}


	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			{(localstate.itemloaded) &&
				<div style={{ padding: "8px", minWidth: "750px", maxWidth: "1200px", textAlign: "center", margin: "auto", marginBottom: "100px" }}>

					{/* Standard Page Header with right floated error message space */}
					<div style={{ position: "relative", height: "85px", paddingTop: "5px" }}>
						<div style={{ textAlign: "center" }}>
							<h3>Inventory Item</h3>
						</div>
						<div>
							<Box sx={{ '& button': { m: 1 } }}>
								<Button
									className={(localstate.editable && !localstate.auditview) ? classes.bluebtn : classes.hidden}
									color="primary" variant="contained"
									onClick={() => SaveChanges()}
									ref={el => btnSave.current = el}>
									<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
								</Button>
								<Button
									className={(localstate.editable && !localstate.auditview) ? classes.bluebtn : classes.hidden}
									color="primary" variant="contained"
									style={{ display: "none" }}
									onClick={() => SaveChanges()}
									ref={el => btnPendingSave.current = el}>
									<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
								</Button>
								<Button
									className={(!localstate.auditview ? classes.bluebtn : classes.hidden)}
									color="primary" variant="contained"
									onClick={() => PrintLabel('inventory-sm')}>
									<PrintIcon />&nbsp;&nbsp;Small Label
								</Button>
								<Button
									className={(!localstate.auditview ? classes.bluebtn : classes.hidden)}
									color="primary" variant="contained"
									onClick={() => PrintLabel('inventory')}>
									<PrintIcon />&nbsp;&nbsp;Large Label
								</Button>
								{(localstate.auditview && userPerms.updateItem) &&
									<Button
										className={classes.orangebtn}
										color="primary" variant="contained"
										onClick={() => RestoreRecord()}>
										Restore This Record
									</Button>
								}



								{(localstate.itemdata.SoftDelete === 1 && true) &&
									<Button className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => UndeleteItem()} style={{ float: "right" }}>
										<SettingsBackupRestoreIcon />&nbsp;&nbsp;Undelete Item
									</Button>
								}

							</Box>
						</div>
						<div style={{ height: "26px" }}>
							{(errors.currenterror.errshow) &&
								<React.Fragment>
									<ErrorMessage />

								</React.Fragment>
							}

							{(localstate.auditview && userPerms.updateItem) &&
								<>
									If you are restoring an item that is currently "Sold", please check it back in first before restoring an old record.
								</>
							}
						</div>


					</div>

					{/* LIVE VIEW */}
					{(!localstate.auditview) &&
						<Typography variant="h6">
							<Grid container spacing={0}>
								<div className={classes.itemtableheader} style={{ display: "flex" }}>
									<div style={{ flexGrow: 1 }}>Item Details</div>
									{(userPerms.auditItem === 1 && localstate.historyavailable) &&
										<div style={{ flexGrow: 1, textAlign: "right", paddingRight: "5px" }}>
											<span className={classes.disabledhoverunit + " " + classes.unselectable} >History: </span>
											{(localstate.recordhistory.length > 0 ? <a className={classes.hoverunit + " " + classes.unselectable} onClick={() => ViewRecordHistory()}>&lt;Prev</a> :
												<a className={classes.disabledhoverunit + " " + classes.unselectable} >Prev &lt;</a>)}
											&nbsp;|&nbsp;
											<a className={classes.disabledhoverunit + " " + classes.unselectable} >Next &gt;</a>
										</div>
									}
								</div>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Serial Number:
													</td>
													<td>

														{/* Pending Item can change serial - Admins get the benefit of a freindly reminder to set a new serial  */}
														{(localstate.itemdata.PendingItem === 1) &&
															<>
																{(localstate.editable) &&
																	<input className={classes.flexiteminput}
																		type="text"
																		placeholder="SET SERIAL NUMBER"
																		//defaultValue={"SET SERIAL NUMBER"}
																		onKeyUp={(event) => onChangeValue(event, "SerialNumber")}
																	/>
																}
																{(!localstate.editable) &&
																	<div className={classes.flexitemstaticinput}>
																		{localstate.itemdata.SerialNumber}
																	</div>
																}
															</>
														}
														{(localstate.itemdata.PendingItem !== 1) &&
															<>
																{/* Admin can change serial  */}
																{(userRole === 'admin') &&
																	<input className={classes.flexiteminput}
																		type="text"
																		defaultValue={localstate.itemdata.SerialNumber}
																		onKeyUp={(event) => onChangeValue(event, "SerialNumber")}
																	/>
																}
																{(userRole !== 'admin') &&
																	<div className={classes.flexitemstaticinput}>
																		{localstate.itemdata.SerialNumber}
																	</div>
																}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>
														Mfg:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.Mfg}
																onKeyUp={(event) => onChangeValue(event, "Mfg")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Mfg}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Model:
													</td>
													<td>
														{(localstate.itemdata.product) &&
															<React.Fragment>
																{(localstate.itemdata.product.ProductOverridesModel === 1 || !localstate.editable) &&
																	<div className={classes.flexitemstaticinput}>
																		{localstate.modelplaceholder}
																	</div>
																}
																{(localstate.itemdata.product.ProductOverridesModel === 0 && localstate.editable) &&
																	<input className={classes.flexiteminput}
																		type="text"
																		defaultValue={localstate.itemdata.Model}
																		onKeyUp={(event) => onChangeValue(event, "Model")}
																	/>
																}
															</React.Fragment>
														}
														{(!localstate.itemdata.product && localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.Model}
																onKeyUp={(event) => onChangeValue(event, "Model")}
															/>
														}
														{(!localstate.itemdata.product && !localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.modelplaceholder}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Product:
													</td>
													<td>
														{(localstate.editable) &&
															<Autocomplete freeSolo forcePopupIcon={false} disableClearable style={{ width: "100%" }}
																className={classes.flexitemautocompleteinput}
																open={openproductoptions} onOpen={() => { InitProductOptions(); }} onClose={() => openProductOptions(false)}
																//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
																onChange={(event, newValue) => onChangeProductOption(event, newValue)}
																onInputChange={(event) => ProductSearch(event.target.value)}
																isOptionEqualToValue={(option, value) => option['Name'] === value}
																getOptionLabel={(option) => option['Name']}
																options={productoptions}
																loading={loadingproductoptions}
																//Setting value or defaultValue kinda breaks this. Use placeholder instead based on a useState variable
																renderInput={(params) => (
																	<div ref={params.InputProps.ref}>
																		<input placeholder={(localstate.productsearchterm === '' ? 'Select Product...' : localstate.productsearchterm)} {...params.inputProps} />
																	</div>
																)}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.productplaceholder}
															</div>
														}

													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Grid>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Status:
													</td>
													<td>
														{(localstate.editable) &&
															<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																<Select
																	displayEmpty
																	onChange={(event) => onChangeValue(event, "Status")}
																	//Setting value means React will try to consider this a controlled component.
																	//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																	//This will present an error whent he component tries to go back to controlled.
																	value={localstate.itemdata.Status}
																	disabled={localstate.itemdata.Status === "Sold"}
																	renderValue={
																		(selected) => {
																			return <Typography variant="h6">{localstate.itemdata.Status}</Typography>;
																		}
																	}
																>
																	<MenuItem value={"Checked In"}>Checked In</MenuItem>
																	<MenuItem value={"Sold"}>Sold</MenuItem>
																	<MenuItem value={"Scrap"}>Scrap</MenuItem>
																	<MenuItem value={"Returned to Vendor"}>Returned to Vendor</MenuItem>
																</Select>
															</FormControl>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Status}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Location:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.Location}
																onKeyUp={(event) => onChangeValue(event, "Location")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Location}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Cost:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																ref={el => itemRefs.current.CostEl = el}
																type="number"
																step="0.01"
																defaultValue={localstate.itemdata.Cost}
																onKeyUp={(event) => onChangeValue(event, "Cost")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Cost}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Asset Type:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.AssetType}
																onKeyUp={(event) => onChangeValue(event, "AssetType")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.AssetType}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>

													</td>
													<td>
														{(localstate.editable && localstate.itemdata.Status === "Sold") &&
															<Button variant="contained" size="small" onClick={() => SaveChanges(true)}>Check Back In</Button>

														}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Grid>



								<div className={classes.itemtableheader}>Condition</div>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Overall:
													</td>
													<td>
														<div>
															{(localstate.editable) &&
																<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																	<Button ref={el => btnGradeA.current = el} onClick={() => SetGrade('A')} key="gradeabtn">A</Button>
																	<Button ref={el => btnGradeB.current = el} onClick={() => SetGrade('B')} key="gradebbtn">B</Button>
																	<Button ref={el => btnGradeC.current = el} onClick={() => SetGrade('C')} key="gradecbtn">C</Button>
																	<Button ref={el => btnGradeRepair.current = el} onClick={() => SetGrade('Repair')} key="graderepairbtn">Repair</Button>
																	<Button ref={el => btnGradeScrap.current = el} onClick={() => SetGrade('Scrap')} key="gradescrapbtn">Scrap</Button>
																</ButtonGroup>
															}
															{(!localstate.editable) &&
																<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																	<Button ref={el => btnGradeA.current = el} key="gradeabtn">A</Button>
																	<Button ref={el => btnGradeB.current = el} key="gradebbtn">B</Button>
																	<Button ref={el => btnGradeC.current = el} key="gradecbtn">C</Button>
																	<Button ref={el => btnGradeRepair.current = el} key="graderepairbtn">Repair</Button>
																	<Button ref={el => btnGradeScrap.current = el} key="gradescrapbtn">Scrap</Button>
																</ButtonGroup>
															}
														</div>

													</td>
												</tr>
												{(localstate.itemdata.AssetType === "Laptop" || localstate.itemdata.AssetType === "AIO") &&
													<React.Fragment>
														<tr>
															<td>
																Body Grade:
															</td>
															<td>
																<div>
																	{(localstate.editable) &&
																		<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																			<Button ref={el => btnBodyGradeA.current = el} onClick={() => SetBodyGrade('A')} key="bodygradeabtn">A</Button>
																			<Button ref={el => btnBodyGradeB.current = el} onClick={() => SetBodyGrade('B')} key="bodygradebbtn">B</Button>
																			<Button ref={el => btnBodyGradeC.current = el} onClick={() => SetBodyGrade('C')} key="bodygradecbtn">C</Button>
																			<Button ref={el => btnBodyGradeBad.current = el} onClick={() => SetBodyGrade('Bad')} key="bodygradebadbtn">Bad</Button>
																		</ButtonGroup>
																	}
																	{(!localstate.editable) &&
																		<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																			<Button ref={el => btnBodyGradeA.current = el} key="bodygradeabtn">A</Button>
																			<Button ref={el => btnBodyGradeB.current = el} key="bodygradebbtn">B</Button>
																			<Button ref={el => btnBodyGradeC.current = el} key="bodygradecbtn">C</Button>
																			<Button ref={el => btnBodyGradeBad.current = el} key="bodygradebadbtn">Bad</Button>
																		</ButtonGroup>
																	}
																</div>

															</td>
														</tr>
														<tr>
															<td>
																LCD Grade:
															</td>
															<td>
																<div>
																	{(localstate.editable) &&
																		<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																			<Button ref={el => btnLCDGradeA.current = el} onClick={() => SetLCDGrade('A')} key="lcdgradeabtn">A</Button>
																			<Button ref={el => btnLCDGradeB.current = el} onClick={() => SetLCDGrade('B')} key="lcdgradebbtn">B</Button>
																			<Button ref={el => btnLCDGradeC.current = el} onClick={() => SetLCDGrade('C')} key="lcdgradecbtn">C</Button>
																			<Button ref={el => btnLCDGradeBad.current = el} onClick={() => SetLCDGrade('Bad')} key="lcdgradebadbtn">Bad</Button>
																		</ButtonGroup>
																	}
																	{(!localstate.editable) &&
																		<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																			<Button ref={el => btnLCDGradeA.current = el} key="lcdgradeabtn">A</Button>
																			<Button ref={el => btnLCDGradeB.current = el} key="lcdgradebbtn">B</Button>
																			<Button ref={el => btnLCDGradeC.current = el} key="lcdgradecbtn">C</Button>
																			<Button ref={el => btnLCDGradeBad.current = el} key="lcdgradebadbtn">Bad</Button>
																		</ButtonGroup>
																	}

																</div>

															</td>
														</tr>
													</React.Fragment>
												}
												<tr>
													<td>
														Notes:
													</td>
													<td>
														{(localstate.editable) &&
															<TextareaAutosize
																style={{ minHeight: "50px", backgroundColor: "#EEE" }}
																className={classes.flexiteminput}
																defaultValue={localstate.itemdata.Notes}
																onKeyUp={(event) => onChangeValue(event, "Notes")}
															/>
														}
														{(!localstate.editable) &&
															<TextareaAutosize disabled
																style={{ minHeight: "50px", backgroundColor: "#EEE" }}
																className={classes.flexiteminput}
																defaultValue={localstate.itemdata.Notes}
																onKeyUp={(event) => onChangeValue(event, "Notes")}
															/>
														}

													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Grid>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Add:
													</td>
													<td>
														<div>
															{(localstate.editable) &&
																<React.Fragment>
																	<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																		<Button ref={el => btnMinor.current = el} onClick={() => SelectCondition('Minor')} key="minorbtn" className={classes.flexactivebtn}>Minor</Button>
																		<Button ref={el => btnMajor.current = el} onClick={() => SelectCondition('Major')} key="majorbtn">Major</Button>
																		<Button ref={el => btnDamaged.current = el} onClick={() => SelectCondition('Damaged')} key="damagedbtn">Damaged</Button>
																	</ButtonGroup>
																	<input className={classes.flexiteminput + " " + classes.flexinputsmallplaceholder}
																		type="text"
																		ref={el => conditiontextref.current = el}
																		onKeyUp={(event) => onChangeCondition(event, "AssetType")}
																		maxLength="64"
																		placeholder="Enter text and press enter"
																	/>
																</React.Fragment>
															}
															{(!localstate.editable) &&
																<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																	<Button ref={el => btnMinor.current = el} key="minorbtn" className={classes.flexactivebtn}>Minor</Button>
																	<Button ref={el => btnMajor.current = el} key="majorbtn">Major</Button>
																	<Button ref={el => btnDamaged.current = el} key="damagedbtn">Damaged</Button>
																</ButtonGroup>
															}
														</div>
													</td>
												</tr>
												{((localstate.itemdata.damagedconditions.length > 0) || (localstate.itemdata.addeddamagedconditions?.length > 0) || (localstate.itemdata.removeddamagedconditions?.length > 0)) &&
													<tr>
														<td style={{ width: "140px" }}>
															Damaged:
														</td>
														<td>
															{(!localstate.historymode) &&
																<React.Fragment>
																	{localstate.itemdata.damagedconditions.map((condition) => (
																		<div key={condition.ConditionID} className={classes.damagedcondition}>
																			{condition.ConditionText}&nbsp;
																			{(localstate.editable && localstate.itemdata.Current === 1) &&
																				<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																			}
																		</div>
																	))}
																</React.Fragment>
															}
															{(localstate.historymode) &&
																<React.Fragment>
																	{localstate.itemdata.damagedconditions.map((condition) => (
																		<div key={condition.ConditionID} className={classes.staticcondition}>
																			{condition.ConditionText}&nbsp;
																			{(localstate.editable && localstate.itemdata.Current === 1) &&
																				<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																			}
																		</div>
																	))}
																	{localstate.itemdata.addeddamagedconditions?.map((condition) => (
																		<div key={condition.ConditionID} className={classes.damagedcondition}>
																			{condition.ConditionText}&nbsp;
																			{(localstate.editable && localstate.itemdata.Current === 1) &&
																				<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																			}
																		</div>
																	))}
																	{localstate.itemdata.removeddamagedconditions?.map((condition) => (
																		<div key={condition.ConditionID} className={classes.removedcondition}>
																			{condition.ConditionText}&nbsp;
																		</div>
																	))}
																</React.Fragment>
															}

														</td>
													</tr>
												}
												{(localstate.itemdata.majorconditions.length > 0 || localstate.itemdata.addedmajorconditions?.length > 0 || localstate.itemdata.removedmajorconditions?.length > 0) &&
													<tr>
														<td style={{ width: "140px" }}>
															Major:
														</td>
														<td>



															{(!localstate.historymode) &&
																<React.Fragment>
																	{localstate.itemdata.majorconditions.map((condition) => (
																		<div key={condition.ConditionID} className={classes.majorcondition}>
																			{condition.ConditionText}&nbsp;
																			{(localstate.editable && localstate.itemdata.Current === 1) &&
																				<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																			}
																		</div>
																	))}
																</React.Fragment>
															}
															{(localstate.historymode) &&
																<React.Fragment>
																	{localstate.itemdata.majorconditions.map((condition) => (
																		<div key={condition.ConditionID} className={classes.staticcondition}>
																			{condition.ConditionText}&nbsp;
																			{(localstate.editable && localstate.itemdata.Current === 1) &&
																				<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																			}
																		</div>
																	))}
																	{localstate.itemdata.addedmajorconditions?.map((condition) => (
																		<div key={condition.ConditionID} className={classes.majorcondition}>
																			{condition.ConditionText}&nbsp;
																			{(localstate.editable && localstate.itemdata.Current === 1) &&
																				<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																			}
																		</div>
																	))}
																	{localstate.itemdata.removedmajorconditions?.map((condition) => (
																		<div key={condition.ConditionID} className={classes.removedcondition}>
																			{condition.ConditionText}&nbsp;
																		</div>
																	))}
																</React.Fragment>
															}
														</td>
													</tr>
												}
												{(localstate.itemdata.minorconditions.length > 0 || localstate.itemdata.addedminorconditions?.length > 0 || localstate.itemdata.removedminorconditions?.length > 0) &&
													<tr>
														<td style={{ width: "140px" }}>
															Minor:
														</td>
														<td>
															{(!localstate.historymode) &&
																<React.Fragment>
																	{localstate.itemdata.minorconditions.map((condition) => (
																		<div key={condition.ConditionID} className={classes.minorcondition}>
																			{condition.ConditionText}&nbsp;
																			{(localstate.editable && localstate.itemdata.Current === 1) &&
																				<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																			}
																		</div>
																	))}
																</React.Fragment>
															}
															{(localstate.historymode) &&
																<React.Fragment>
																	{localstate.itemdata.minorconditions.map((condition) => (
																		<div key={condition.ConditionID} className={classes.staticcondition}>
																			{condition.ConditionText}&nbsp;
																			{(localstate.editable && localstate.itemdata.Current === 1) &&
																				<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																			}
																		</div>
																	))}
																	{localstate.itemdata.addedminorconditions?.map((condition) => (
																		<div key={condition.ConditionID} className={classes.minorcondition}>
																			{condition.ConditionText}&nbsp;
																			{(localstate.editable && localstate.itemdata.Current === 1) &&
																				<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																			}
																		</div>
																	))}
																	{localstate.itemdata.removedminorconditions?.map((condition) => (
																		<div key={condition.ConditionID} className={classes.removedcondition}>
																			{condition.ConditionText}&nbsp;
																		</div>
																	))}
																</React.Fragment>
															}
														</td>
													</tr>
												}
												<tr>
													<td>&nbsp;</td>
													<td>&nbsp;</td>
												</tr>
												{(localstate.editable) &&
													<tr>
														<td style={{ width: "140px" }}>
															Repair:
														</td>
														<td>

															<input className={classes.flexiteminput + " " + classes.flexinputsmallplaceholder}
																type="text"
																placeholder="Short description of repairs"
																onKeyUp={(event) => onChangeValue(event, "RepairNote")}
															/>
														</td>
													</tr>
												}

												{(localstate.itemdata.Repaired === 1) &&
													<tr>
														<td style={{ width: "140px" }}>

														</td>
														<td style={{ fontSize: "12px", textAlign: "left" }}>
															Most recent repair:<br></br>
															{localstate.itemdata.RepairedByName}: {localstate.itemdata.RepairNote}<br></br>
															Date: {dayjs(localstate.itemdata.RepairDate).format("MMMM D, YYYY [at] h:mma")}
														</td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</Grid>

								<div className={classes.itemtableheader}>Specifications</div>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														CPU:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.CPU}
																onKeyUp={(event) => onChangeValue(event, "CPU")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.CPU}
															</div>
														}

													</td>
												</tr>
												<tr>
													<td>
														RAM:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.RAM}
																onKeyUp={(event) => onChangeValue(event, "RAM")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.RAM}
															</div>
														}

													</td>
												</tr>
												<tr>
													<td>
														HDD 1:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.HDD1Size}
																onKeyUp={(event) => onChangeValue(event, "HDD1Size")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.HDD1Size}
															</div>
														}

													</td>
												</tr>
												<tr>
													<td>
														HDD 2:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.HDD2Size}
																onKeyUp={(event) => onChangeValue(event, "HDD2Size")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.HDD2Size}
															</div>
														}

													</td>
												</tr>
												<tr>
													<td>
														Optical:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.Optical}
																onKeyUp={(event) => onChangeValue(event, "Optical")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Optical}
															</div>
														}

													</td>
												</tr>
												<tr>
													<td>
														Batt 1 Health:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.Batt1}
																onKeyUp={(event) => onChangeValue(event, "Batt1")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Batt1}
															</div>
														}

													</td>
												</tr>
												<tr>
													<td>
														Batt 2 Health:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.Batt2}
																onKeyUp={(event) => onChangeValue(event, "Batt2")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Batt2}
															</div>
														}

													</td>
												</tr>
												{/* Some values are read-only. Most of this comes from spec dumps. */}
												{/* Keep in mind, Laravel doesn't care for blank strings and will update the DB with NULL - making this conditional way easier. */}
												{(localstate.itemdata.Network1) &&
													<tr>
														<td>
															Network 1:
														</td>
														<td>
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Network1}
															</div>
														</td>
													</tr>
												}
												{(localstate.itemdata.Network2) &&
													<tr>
														<td>
															Network 2:
														</td>
														<td>
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Network2}
															</div>
														</td>
													</tr>
												}
												{(localstate.itemdata.Network3) &&
													<tr>
														<td>
															Network 3:
														</td>
														<td>
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Network3}
															</div>
														</td>
													</tr>
												}
												{(localstate.itemdata.Network4) &&
													<tr>
														<td>
															Network 4:
														</td>
														<td>
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Network4}
															</div>
														</td>
													</tr>
												}
												{(localstate.itemdata.Network5) &&
													<tr>
														<td>
															Network 5:
														</td>
														<td>
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Network5}
															</div>
														</td>
													</tr>
												}
												{(localstate.itemdata.Network6) &&
													<tr>
														<td>
															Network 6:
														</td>
														<td>
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Network6}
															</div>
														</td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</Grid>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Webcam:
													</td>
													<td>
														{(localstate.editable) &&
															<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																<Select
																	displayEmpty
																	onChange={(event) => onChangeValue(event, "Webcam")}
																	//Setting value means React will try to consider this a controlled component.
																	//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																	//This will present an error whent he component tries to go back to controlled.
																	defaultValue={localstate.itemdata.Webcam}
																	renderValue={
																		(selected) => {
																			if (!selected) {
																				//Placeholder!
																				return <Typography variant="h6">
																					{(localstate.itemdata.Webcam === 1) &&
																						<React.Fragment>Yes</React.Fragment>
																					}
																					{(localstate.itemdata.Webcam === 0) &&
																						<React.Fragment>No</React.Fragment>
																					}
																				</Typography>;
																			} else {
																				return <Typography variant="h6">
																					{(localstate.itemdata.Webcam === 1) &&
																						<React.Fragment>Yes</React.Fragment>
																					}
																					{(localstate.itemdata.Webcam === 0) &&
																						<React.Fragment>No</React.Fragment>
																					}
																				</Typography>;
																			}
																		}
																	}
																>
																	<MenuItem value={1}>Yes</MenuItem>
																	<MenuItem value={0}>No</MenuItem>
																</Select>
															</FormControl>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{(localstate.itemdata.Webcam === 1) &&
																	<React.Fragment>Yes</React.Fragment>
																}
																{(localstate.itemdata.Webcam === 0) &&
																	<React.Fragment>No</React.Fragment>
																}
															</div>
														}

													</td>
												</tr>
												<tr>
													<td style={{ width: "140px" }}>
														Battery:
													</td>
													<td>
														{(localstate.editable) &&
															<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																<Select
																	displayEmpty
																	onChange={(event) => onChangeValue(event, "Battery")}
																	//Setting value means React will try to consider this a controlled component.
																	//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																	//This will present an error whent he component tries to go back to controlled.
																	defaultValue={localstate.itemdata.Battery}
																	renderValue={
																		(selected) => {
																			if (!selected) {
																				//Placeholder!
																				return <Typography variant="h6">
																					{(localstate.itemdata.Battery === 1) &&
																						<React.Fragment>Yes</React.Fragment>
																					}
																					{(localstate.itemdata.Battery === 0) &&
																						<React.Fragment>No</React.Fragment>
																					}
																				</Typography>;
																			} else {
																				return <Typography variant="h6">
																					{(localstate.itemdata.Battery === 1) &&
																						<React.Fragment>Yes</React.Fragment>
																					}
																					{(localstate.itemdata.Battery === 0) &&
																						<React.Fragment>No</React.Fragment>
																					}
																				</Typography>;
																			}
																		}
																	}
																>
																	<MenuItem value={1}>Yes</MenuItem>
																	<MenuItem value={0}>No</MenuItem>
																</Select>
															</FormControl>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{(localstate.itemdata.Battery === 1) &&
																	<React.Fragment>Yes</React.Fragment>
																}
																{(localstate.itemdata.Battery === 0) &&
																	<React.Fragment>No</React.Fragment>
																}
															</div>
														}

													</td>
												</tr>
												<tr>
													<td style={{ width: "140px" }}>
														Touchscreen:
													</td>
													<td>
														{(localstate.editable) &&
															<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																<Select
																	displayEmpty
																	onChange={(event) => onChangeValue(event, "Touchscreen")}
																	//Setting value means React will try to consider this a controlled component.
																	//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																	//This will present an error whent he component tries to go back to controlled.
																	defaultValue={localstate.itemdata.Touchscreen}
																	renderValue={
																		(selected) => {
																			if (!selected) {
																				//Placeholder!
																				return <Typography variant="h6">
																					{(localstate.itemdata.Touchscreen === 1) &&
																						<React.Fragment>Yes</React.Fragment>
																					}
																					{(localstate.itemdata.Touchscreen === 0) &&
																						<React.Fragment>No</React.Fragment>
																					}
																				</Typography>;
																			} else {
																				return <Typography variant="h6">
																					{(localstate.itemdata.Touchscreen === 1) &&
																						<React.Fragment>Yes</React.Fragment>
																					}
																					{(localstate.itemdata.Touchscreen === 0) &&
																						<React.Fragment>No</React.Fragment>
																					}
																				</Typography>;
																			}
																		}
																	}
																>
																	<MenuItem value={1}>Yes</MenuItem>
																	<MenuItem value={0}>No</MenuItem>
																</Select>
															</FormControl>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{(localstate.itemdata.Touchscreen === 1) &&
																	<React.Fragment>Yes</React.Fragment>
																}
																{(localstate.itemdata.Touchscreen === 0) &&
																	<React.Fragment>No</React.Fragment>
																}
															</div>
														}

													</td>
												</tr>
												<tr>
													<td style={{ width: "140px" }}>
														Bluetooth:
													</td>
													<td>
														{(localstate.editable) &&
															<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																<Select
																	displayEmpty
																	onChange={(event) => onChangeValue(event, "Bluetooth")}
																	//Setting value means React will try to consider this a controlled component.
																	//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																	//This will present an error whent he component tries to go back to controlled.
																	defaultValue={localstate.itemdata.Touchscreen}
																	renderValue={
																		(selected) => {
																			if (!selected) {
																				//Placeholder!
																				return <Typography variant="h6">
																					{(localstate.itemdata.Bluetooth === 1) &&
																						<React.Fragment>Yes</React.Fragment>
																					}
																					{(localstate.itemdata.Bluetooth === 0) &&
																						<React.Fragment>No</React.Fragment>
																					}
																				</Typography>;
																			} else {
																				return <Typography variant="h6">
																					{(localstate.itemdata.Bluetooth === 1) &&
																						<React.Fragment>Yes</React.Fragment>
																					}
																					{(localstate.itemdata.Bluetooth === 0) &&
																						<React.Fragment>No</React.Fragment>
																					}
																				</Typography>;
																			}
																		}
																	}
																>
																	<MenuItem value={1}>Yes</MenuItem>
																	<MenuItem value={0}>No</MenuItem>
																</Select>
															</FormControl>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{(localstate.itemdata.Bluetooth === 1) &&
																	<React.Fragment>Yes</React.Fragment>
																}
																{(localstate.itemdata.Bluetooth === 0) &&
																	<React.Fragment>No</React.Fragment>
																}
															</div>
														}

													</td>
												</tr>
												<tr>
													<td>
														Resolution:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.Resolution}
																onKeyUp={(event) => onChangeValue(event, "Resolution")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Resolution}
															</div>
														}

													</td>
												</tr>
												<tr>
													<td>
														Video 1:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.Video1Desc}
																onKeyUp={(event) => onChangeValue(event, "Video1Desc")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Video1Desc}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Video 2:
													</td>
													<td>
														{(localstate.editable) &&
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.Video2Desc}
																onKeyUp={(event) => onChangeValue(event, "Video2Desc")}
															/>
														}
														{(!localstate.editable) &&
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Video2Desc}
															</div>
														}

													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Grid>
								{/* Btns for MDM */}
								<div style={{ textAlign: "right", width: "100%" }}>
										<div style={{ float: "right" }}>
											{(localstate.itemdata.IntuneStatus === 0) &&
												<Button className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => FlagMDM('intune')}>
													Flag Intune
												</Button>
											}
											{(localstate.itemdata.PanGPStatus === 0) &&
												<Button className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => FlagMDM('pangp')}>
													Flag PanGP
												</Button>
											}
											{(localstate.itemdata.AbsPersistenceStatus === 0) &&
												<Button className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => FlagMDM('abspersistence')}>
													Flag Absolute Persistence
												</Button>
											}

										</div>
									</div>




								{/* ###### MDM SECTIONS ##### 
								
								  //Intune/PanGP/AbsPersistence Status:
										//	0: Unknown
										//	1: Confirmed Clear (probably not available without evidence, this is a placeholder in case we need to use it one day)
										//	2: Likely Enabled
										//	3: Confirmed Enabled
										//	4: Resolved Automatically *(When inventory item is checked out to an order and IntuneStatus=3 - New key has very likely been used!)
										//     This option should not be available in front end.
										//	5: Resolved by Vendor *(Editable individually or bulk by purchasing agent)
										//	6: Resolved Despite Vendor *(When inventory item IntuneStatus=5,
										//     then iPXE set Confirmed Enabled, Intune is marked resolved despite vendor statement of removal.)
										//	   Also send risk record for when this happens.
										//     This option should not be available in front end.	
										//	7: Unable to Resolve - Vendor could not resolve.
								
								*/}

								{/* ## Section 1: Intune ## */}
								{(localstate.itemdata.IntuneStatus !== 0 || localstate.itemdata.IntuneReported !==0) &&
									<>
										<div className={classes.itemtableheader}>Intune Info</div>
										<Grid item sm={12} md={6}>
											<div className={classes.flextabledebug}>
												<table className={classes.itemtable}>
													<tbody>
														<tr>
															<td style={{ width: "140px" }}>
																Status:
															</td>
															<td>
																{(localstate.editable) &&
																	<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																		<Select
																			displayEmpty
																			onChange={(event) => onChangeValue(event, "IntuneStatus")}
																			defaultValue={localstate.itemdata.IntuneStatus}
																			renderValue={
																				(selected) => {
																					if (!selected) {
																						return <Typography variant="h6">
																							{(localstate.itemdata.IntuneStatus === 0) &&
																								<React.Fragment>Unknown</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 1) &&
																								<React.Fragment>Confirmed Clear</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 2) &&
																								<React.Fragment>Likely Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 3) &&
																								<React.Fragment>Confirmed Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 4) &&
																								<React.Fragment>Resolved Automatically</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 5) &&
																								<React.Fragment>Resolved By Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 6) &&
																								<React.Fragment>Resolved Despite Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 7) &&
																								<React.Fragment>Unable to Resolve</React.Fragment>
																							}

																						</Typography>;
																					} else {
																						return <Typography variant="h6">
																						{(localstate.itemdata.IntuneStatus === 0) &&
																								<React.Fragment>Unknown</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 1) &&
																								<React.Fragment>Confirmed Clear</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 2) &&
																								<React.Fragment>Likely Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 3) &&
																								<React.Fragment>Confirmed Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 4) &&
																								<React.Fragment>Resolved Automatically</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 5) &&
																								<React.Fragment>Resolved By Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 6) &&
																								<React.Fragment>Resolved Despite Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.IntuneStatus === 7) &&
																								<React.Fragment>Unable to Resolve</React.Fragment>
																							}
																						</Typography>;
																					}
																				}
																			}
																		>
																			<MenuItem value={0}>Unknown</MenuItem>
																			<MenuItem value={2}>Likely Enabled</MenuItem>
																			<MenuItem value={3}>Confirmed Enabled</MenuItem>
																			<MenuItem value={5}>Resolved By Vendor</MenuItem>
																			<MenuItem value={7}>Unable to Resolve</MenuItem>
																		</Select>
																	</FormControl>
																}
																{(!localstate.editable) &&
																	<div className={classes.flexitemstaticinput}>
																		{(localstate.itemdata.IntuneStatus === 0) &&
																			<React.Fragment>Unknown</React.Fragment>
																		}
																		{(localstate.itemdata.IntuneStatus === 1) &&
																			<React.Fragment>Confirmed Clear</React.Fragment>
																		}
																		{(localstate.itemdata.IntuneStatus === 2) &&
																			<React.Fragment>Likely Enabled</React.Fragment>
																		}
																		{(localstate.itemdata.IntuneStatus === 3) &&
																			<React.Fragment>Confirmed Enabled</React.Fragment>
																		}
																		{(localstate.itemdata.IntuneStatus === 4) &&
																			<React.Fragment>Resolved Automatically</React.Fragment>
																		}
																		{(localstate.itemdata.IntuneStatus === 5) &&
																			<React.Fragment>Resolved By Vendor</React.Fragment>
																		}
																		{(localstate.itemdata.IntuneStatus === 6) &&
																			<React.Fragment>Resolved Despite Vendor</React.Fragment>
																		}
																		{(localstate.itemdata.IntuneStatus === 7) &&
																			<React.Fragment>Unable to Resolve</React.Fragment>
																		}
																	</div>
																}

															</td>
														</tr>

														{(localstate.itemdata.IntuneStatusDateTime) &&
															<tr>
																<td>
																	Status At:
																</td>
																<td>
																	<div className={classes.flexitemstaticinput}>
																		{dayjs(localstate.itemdata.IntuneStatusDateTime).format("MMMM D, YYYY [at] h:mma")}
																	</div>
																</td>
															</tr>
														}
													</tbody>
												</table>
											</div>
										</Grid>


										<Grid item sm={12} md={6}>
											<div className={classes.flextabledebug}>
												<table className={classes.itemtable}>
													<tbody>
														{(localstate.itemdata.IntuneReported === 0) &&
															<tr>
																<td>
																	{/* Btns for MDM */}
																	<div style={{ textAlign: "right", width: "100%" }}>
																		<div style={{ float: "right" }}>
																			<Button className={classes.bluebtn}
																				color="primary" variant="contained"
																				onClick={() => FlagMDMReported('intune')}>
																				Flag as Vendor Notified
																			</Button>
																		</div>
																	</div>
																</td>
															</tr>
														}
														{(localstate.itemdata.IntuneReported === 1) &&
															<tr>
																<td style={{ width: "140px" }}>
																	Reported:
																</td>
																<td>
																	{(localstate.editable) &&
																		<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																			<Select
																				displayEmpty
																				onChange={(event) => onChangeValue(event, "IntuneReported")}
																				//Setting value means React will try to consider this a controlled component.
																				//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																				//This will present an error whent he component tries to go back to controlled.
																				defaultValue={localstate.itemdata.IntuneReported}
																				renderValue={
																					(selected) => {
																						if (!selected) {
																							//Placeholder!
																							return <Typography variant="h6">
																								{(localstate.itemdata.IntuneReported === 1) &&
																									<React.Fragment>Yes</React.Fragment>
																								}
																								{(localstate.itemdata.IntuneReported === 0) &&
																									<React.Fragment>No</React.Fragment>
																								}
																							</Typography>;
																						} else {
																							return <Typography variant="h6">
																								{(localstate.itemdata.IntuneReported === 1) &&
																									<React.Fragment>Yes</React.Fragment>
																								}
																								{(localstate.itemdata.IntuneReported === 0) &&
																									<React.Fragment>No</React.Fragment>
																								}
																							</Typography>;
																						}
																					}
																				}
																			>
																				<MenuItem value={1}>Yes</MenuItem>
																				<MenuItem value={0}>No</MenuItem>
																			</Select>
																		</FormControl>
																	}
																	{(!localstate.editable) &&
																		<div className={classes.flexitemstaticinput}>
																			{(localstate.itemdata.IntuneReported === 1) &&
																				<React.Fragment>Yes</React.Fragment>
																			}
																			{(localstate.itemdata.IntuneReported === 0) &&
																				<React.Fragment>No</React.Fragment>
																			}
																		</div>
																	}

																</td>
															</tr>
														}

														{(localstate.itemdata.IntuneReported === 1) &&
															<>
																<tr>
																	<td>
																		Reported At:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput}>
																			{dayjs(localstate.itemdata.IntuneReportedDateTime).format("MMMM D, YYYY [at] h:mma")}
																			{/* <Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.itemdata.Date}</Moment> */}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Response:
																	</td>
																	<td>
																		<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																			<Select
																				variant="standard"
																				value={localstate.itemdata.IntuneReportResponse}
																				displayEmpty
																				onChange={(event) => onChangeValue(event, "IntuneReportResponse")}
																				renderValue={
																					(selected) => {
																						return <Typography variant="h6">{localstate.itemdata.IntuneReportResponse}</Typography>;
																					}
																				}
																			>
																				<MenuItem value={"None"}>None</MenuItem>
																				<MenuItem value={"Pending Removal"}>Pending Removal</MenuItem>
																				<MenuItem value={"Unable To Remove"}>Unable to Remove</MenuItem>
																				<MenuItem value={"Intune Removed"}>Intune Removed</MenuItem>
																			</Select>
																		</FormControl>
																	</td>
																</tr>
															</>
														}
													</tbody>
												</table>
											</div>
										</Grid>
									</>
								}



								{/* ## Section 2: PanGP ## */}
								{(localstate.itemdata.PanGPStatus !== 0 || localstate.itemdata.PanGPReported !==0) &&
									<>
										<div className={classes.itemtableheader}>PanGP Info</div>
										<Grid item sm={12} md={6}>
											<div className={classes.flextabledebug}>
												<table className={classes.itemtable}>
													<tbody>
														<tr>
															<td style={{ width: "140px" }}>
																Status:
															</td>
															<td>
																{(localstate.editable) &&
																	<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																		<Select
																			displayEmpty
																			onChange={(event) => onChangeValue(event, "PanGPStatus")}
																			defaultValue={localstate.itemdata.PanGPStatus}
																			renderValue={
																				(selected) => {
																					if (!selected) {
																						return <Typography variant="h6">
																							{(localstate.itemdata.PanGPStatus === 0) &&
																								<React.Fragment>Unknown</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 1) &&
																								<React.Fragment>Confirmed Clear</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 2) &&
																								<React.Fragment>Likely Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 3) &&
																								<React.Fragment>Confirmed Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 4) &&
																								<React.Fragment>Resolved Automatically</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 5) &&
																								<React.Fragment>Resolved By Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 6) &&
																								<React.Fragment>Resolved Despite Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 7) &&
																								<React.Fragment>Unable to Resolve</React.Fragment>
																							}

																						</Typography>;
																					} else {
																						return <Typography variant="h6">
																						{(localstate.itemdata.PanGPStatus === 0) &&
																								<React.Fragment>Unknown</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 1) &&
																								<React.Fragment>Confirmed Clear</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 2) &&
																								<React.Fragment>Likely Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 3) &&
																								<React.Fragment>Confirmed Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 4) &&
																								<React.Fragment>Resolved Automatically</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 5) &&
																								<React.Fragment>Resolved By Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 6) &&
																								<React.Fragment>Resolved Despite Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.PanGPStatus === 7) &&
																								<React.Fragment>Unable to Resolve</React.Fragment>
																							}
																						</Typography>;
																					}
																				}
																			}
																		>
																			<MenuItem value={0}>Unknown</MenuItem>
																			<MenuItem value={2}>Likely Enabled</MenuItem>
																			<MenuItem value={3}>Confirmed Enabled</MenuItem>
																			<MenuItem value={5}>Resolved By Vendor</MenuItem>
																			<MenuItem value={7}>Unable to Resolve</MenuItem>
																		</Select>
																	</FormControl>
																}
																{(!localstate.editable) &&
																	<div className={classes.flexitemstaticinput}>
																		{(localstate.itemdata.PanGPStatus === 0) &&
																			<React.Fragment>Unknown</React.Fragment>
																		}
																		{(localstate.itemdata.PanGPStatus === 1) &&
																			<React.Fragment>Confirmed Clear</React.Fragment>
																		}
																		{(localstate.itemdata.PanGPStatus === 2) &&
																			<React.Fragment>Likely Enabled</React.Fragment>
																		}
																		{(localstate.itemdata.PanGPStatus === 3) &&
																			<React.Fragment>Confirmed Enabled</React.Fragment>
																		}
																		{(localstate.itemdata.PanGPStatus === 4) &&
																			<React.Fragment>Resolved Automatically</React.Fragment>
																		}
																		{(localstate.itemdata.PanGPStatus === 5) &&
																			<React.Fragment>Resolved By Vendor</React.Fragment>
																		}
																		{(localstate.itemdata.PanGPStatus === 6) &&
																			<React.Fragment>Resolved Despite Vendor</React.Fragment>
																		}
																		{(localstate.itemdata.PanGPStatus === 7) &&
																			<React.Fragment>Unable to Resolve</React.Fragment>
																		}
																	</div>
																}

															</td>
														</tr>

														{(localstate.itemdata.PanGPStatusDateTime) &&
															<tr>
																<td>
																	Status At:
																</td>
																<td>
																	<div className={classes.flexitemstaticinput}>
																		{dayjs(localstate.itemdata.PanGPStatusDateTime).format("MMMM D, YYYY [at] h:mma")}
																	</div>
																</td>
															</tr>
														}
													</tbody>
												</table>
											</div>
										</Grid>


										<Grid item sm={12} md={6}>
											<div className={classes.flextabledebug}>
												<table className={classes.itemtable}>
													<tbody>
														{(localstate.itemdata.PanGPReported === 0) &&
															<tr>
																<td>
																	{/* Btns for MDM */}
																	<div style={{ textAlign: "right", width: "100%" }}>
																		<div style={{ float: "right" }}>
																			<Button className={classes.bluebtn}
																				color="primary" variant="contained"
																				onClick={() => FlagMDMReported('pangp')}>
																				Flag as Vendor Notified
																			</Button>
																		</div>
																	</div>
																</td>
															</tr>
														}
														{(localstate.itemdata.PanGPReported === 1) &&
															<tr>
																<td style={{ width: "140px" }}>
																	Reported:
																</td>
																<td>
																	{(localstate.editable) &&
																		<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																			<Select
																				displayEmpty
																				onChange={(event) => onChangeValue(event, "PanGPReported")}
																				//Setting value means React will try to consider this a controlled component.
																				//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																				//This will present an error whent he component tries to go back to controlled.
																				defaultValue={localstate.itemdata.PanGPReported}
																				renderValue={
																					(selected) => {
																						if (!selected) {
																							//Placeholder!
																							return <Typography variant="h6">
																								{(localstate.itemdata.PanGPReported === 1) &&
																									<React.Fragment>Yes</React.Fragment>
																								}
																								{(localstate.itemdata.PanGPReported === 0) &&
																									<React.Fragment>No</React.Fragment>
																								}
																							</Typography>;
																						} else {
																							return <Typography variant="h6">
																								{(localstate.itemdata.PanGPReported === 1) &&
																									<React.Fragment>Yes</React.Fragment>
																								}
																								{(localstate.itemdata.PanGPReported === 0) &&
																									<React.Fragment>No</React.Fragment>
																								}
																							</Typography>;
																						}
																					}
																				}
																			>
																				<MenuItem value={1}>Yes</MenuItem>
																				<MenuItem value={0}>No</MenuItem>
																			</Select>
																		</FormControl>
																	}
																	{(!localstate.editable) &&
																		<div className={classes.flexitemstaticinput}>
																			{(localstate.itemdata.PanGPReported === 1) &&
																				<React.Fragment>Yes</React.Fragment>
																			}
																			{(localstate.itemdata.PanGPReported === 0) &&
																				<React.Fragment>No</React.Fragment>
																			}
																		</div>
																	}

																</td>
															</tr>
														}

														{(localstate.itemdata.PanGPReported === 1) &&
															<>
																<tr>
																	<td>
																		Reported At:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput}>
																			{dayjs(localstate.itemdata.PanGPReportedDateTime).format("MMMM D, YYYY [at] h:mma")}
																			{/* <Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.itemdata.Date}</Moment> */}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Response:
																	</td>
																	<td>
																		<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																			<Select
																				variant="standard"
																				value={localstate.itemdata.PanGPReportResponse}
																				displayEmpty
																				onChange={(event) => onChangeValue(event, "PanGPReportResponse")}
																				renderValue={
																					(selected) => {
																						return <Typography variant="h6">{localstate.itemdata.PanGPReportResponse}</Typography>;
																					}
																				}
																			>
																				<MenuItem value={"None"}>None</MenuItem>
																				<MenuItem value={"Pending Removal"}>Pending Removal</MenuItem>
																				<MenuItem value={"Unable To Remove"}>Unable to Remove</MenuItem>
																				<MenuItem value={"PanGP Removed"}>PanGP Removed</MenuItem>
																			</Select>
																		</FormControl>
																	</td>
																</tr>
															</>
														}
													</tbody>
												</table>
											</div>
										</Grid>
									</>
								}




								{/* ## Section 3: AbsPersistence (Absolute Persistence) ## */}
								{(localstate.itemdata.AbsPersistenceStatus !== 0 || localstate.itemdata.AbsPersistenceReported !==0) &&
									<>
										<div className={classes.itemtableheader}>Absolute Persistence Info</div>
										<Grid item sm={12} md={6}>
											<div className={classes.flextabledebug}>
												<table className={classes.itemtable}>
													<tbody>
														<tr>
															<td style={{ width: "140px" }}>
																Status:
															</td>
															<td>
																{(localstate.editable) &&
																	<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																		<Select
																			displayEmpty
																			onChange={(event) => onChangeValue(event, "AbsPersistenceStatus")}
																			defaultValue={localstate.itemdata.AbsPersistenceStatus}
																			renderValue={
																				(selected) => {
																					if (!selected) {
																						return <Typography variant="h6">
																							{(localstate.itemdata.AbsPersistenceStatus === 0) &&
																								<React.Fragment>Unknown</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 1) &&
																								<React.Fragment>Confirmed Clear</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 2) &&
																								<React.Fragment>Likely Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 3) &&
																								<React.Fragment>Confirmed Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 4) &&
																								<React.Fragment>Resolved Automatically</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 5) &&
																								<React.Fragment>Resolved By Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 6) &&
																								<React.Fragment>Resolved Despite Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 7) &&
																								<React.Fragment>Unable to Resolve</React.Fragment>
																							}

																						</Typography>;
																					} else {
																						return <Typography variant="h6">
																						{(localstate.itemdata.AbsPersistenceStatus === 0) &&
																								<React.Fragment>Unknown</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 1) &&
																								<React.Fragment>Confirmed Clear</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 2) &&
																								<React.Fragment>Likely Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 3) &&
																								<React.Fragment>Confirmed Enabled</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 4) &&
																								<React.Fragment>Resolved Automatically</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 5) &&
																								<React.Fragment>Resolved By Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 6) &&
																								<React.Fragment>Resolved Despite Vendor</React.Fragment>
																							}
																							{(localstate.itemdata.AbsPersistenceStatus === 7) &&
																								<React.Fragment>Unable to Resolve</React.Fragment>
																							}
																						</Typography>;
																					}
																				}
																			}
																		>
																			<MenuItem value={0}>Unknown</MenuItem>
																			<MenuItem value={2}>Likely Enabled</MenuItem>
																			<MenuItem value={3}>Confirmed Enabled</MenuItem>
																			<MenuItem value={5}>Resolved By Vendor</MenuItem>
																			<MenuItem value={7}>Unable to Resolve</MenuItem>
																		</Select>
																	</FormControl>
																}
																{(!localstate.editable) &&
																	<div className={classes.flexitemstaticinput}>
																		{(localstate.itemdata.AbsPersistenceStatus === 0) &&
																			<React.Fragment>Unknown</React.Fragment>
																		}
																		{(localstate.itemdata.AbsPersistenceStatus === 1) &&
																			<React.Fragment>Confirmed Clear</React.Fragment>
																		}
																		{(localstate.itemdata.AbsPersistenceStatus === 2) &&
																			<React.Fragment>Likely Enabled</React.Fragment>
																		}
																		{(localstate.itemdata.AbsPersistenceStatus === 3) &&
																			<React.Fragment>Confirmed Enabled</React.Fragment>
																		}
																		{(localstate.itemdata.AbsPersistenceStatus === 4) &&
																			<React.Fragment>Resolved Automatically</React.Fragment>
																		}
																		{(localstate.itemdata.AbsPersistenceStatus === 5) &&
																			<React.Fragment>Resolved By Vendor</React.Fragment>
																		}
																		{(localstate.itemdata.AbsPersistenceStatus === 6) &&
																			<React.Fragment>Resolved Despite Vendor</React.Fragment>
																		}
																		{(localstate.itemdata.AbsPersistenceStatus === 7) &&
																			<React.Fragment>Unable to Resolve</React.Fragment>
																		}
																	</div>
																}

															</td>
														</tr>

														{(localstate.itemdata.AbsPersistenceStatusDateTime) &&
															<tr>
																<td>
																	Status At:
																</td>
																<td>
																	<div className={classes.flexitemstaticinput}>
																		{dayjs(localstate.itemdata.AbsPersistenceStatusDateTime).format("MMMM D, YYYY [at] h:mma")}
																	</div>
																</td>
															</tr>
														}
													</tbody>
												</table>
											</div>
										</Grid>


										<Grid item sm={12} md={6}>
											<div className={classes.flextabledebug}>
												<table className={classes.itemtable}>
													<tbody>
														{(localstate.itemdata.AbsPersistenceReported === 0) &&
															<tr>
																<td>
																	{/* Btns for MDM */}
																	<div style={{ textAlign: "right", width: "100%" }}>
																		<div style={{ float: "right" }}>
																			<Button className={classes.bluebtn}
																				color="primary" variant="contained"
																				onClick={() => FlagMDMReported('abspersistence')}>
																				Flag as Vendor Notified
																			</Button>
																		</div>
																	</div>
																</td>
															</tr>
														}
														{(localstate.itemdata.AbsPersistenceReported === 1) &&
															<tr>
																<td style={{ width: "140px" }}>
																	Reported:
																</td>
																<td>
																	{(localstate.editable) &&
																		<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																			<Select
																				displayEmpty
																				onChange={(event) => onChangeValue(event, "AbsPersistenceReported")}
																				//Setting value means React will try to consider this a controlled component.
																				//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																				//This will present an error whent he component tries to go back to controlled.
																				defaultValue={localstate.itemdata.AbsPersistenceReported}
																				renderValue={
																					(selected) => {
																						if (!selected) {
																							//Placeholder!
																							return <Typography variant="h6">
																								{(localstate.itemdata.AbsPersistenceReported === 1) &&
																									<React.Fragment>Yes</React.Fragment>
																								}
																								{(localstate.itemdata.AbsPersistenceReported === 0) &&
																									<React.Fragment>No</React.Fragment>
																								}
																							</Typography>;
																						} else {
																							return <Typography variant="h6">
																								{(localstate.itemdata.AbsPersistenceReported === 1) &&
																									<React.Fragment>Yes</React.Fragment>
																								}
																								{(localstate.itemdata.AbsPersistenceReported === 0) &&
																									<React.Fragment>No</React.Fragment>
																								}
																							</Typography>;
																						}
																					}
																				}
																			>
																				<MenuItem value={1}>Yes</MenuItem>
																				<MenuItem value={0}>No</MenuItem>
																			</Select>
																		</FormControl>
																	}
																	{(!localstate.editable) &&
																		<div className={classes.flexitemstaticinput}>
																			{(localstate.itemdata.AbsPersistenceReported === 1) &&
																				<React.Fragment>Yes</React.Fragment>
																			}
																			{(localstate.itemdata.AbsPersistenceReported === 0) &&
																				<React.Fragment>No</React.Fragment>
																			}
																		</div>
																	}

																</td>
															</tr>
														}

														{(localstate.itemdata.AbsPersistenceReported === 1) &&
															<>
																<tr>
																	<td>
																		Reported At:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput}>
																			{dayjs(localstate.itemdata.AbsPersistenceReportedDateTime).format("MMMM D, YYYY [at] h:mma")}
																			{/* <Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.itemdata.Date}</Moment> */}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Response:
																	</td>
																	<td>
																		<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																			<Select
																				variant="standard"
																				value={localstate.itemdata.AbsPersistenceReportResponse}
																				displayEmpty
																				onChange={(event) => onChangeValue(event, "AbsPersistenceReportResponse")}
																				renderValue={
																					(selected) => {
																						return <Typography variant="h6">{localstate.itemdata.AbsPersistenceReportResponse}</Typography>;
																					}
																				}
																			>
																				<MenuItem value={"None"}>None</MenuItem>
																				<MenuItem value={"Pending Removal"}>Pending Removal</MenuItem>
																				<MenuItem value={"Unable To Remove"}>Unable to Remove</MenuItem>
																				<MenuItem value={"Absolute Persistence Removed"}>Absolute Persistence Removed</MenuItem>
																			</Select>
																		</FormControl>
																	</td>
																</tr>
															</>
														}
													</tbody>
												</table>
											</div>
										</Grid>
									</>
								}







								<div className={classes.itemtableheader}>Record Details</div>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Created At:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{dayjs(localstate.itemdata.ItemCreationDate).format("MMMM D, YYYY [at] h:mma")}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Created By:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.CreatedBy}
														</div>
													</td>
												</tr>
												{(localstate.itemdata.lot) &&
													<tr>
														<td>
															Lot:
														</td>
														<td>
															<div className={classes.flexitemstaticinput}>
																<a target="_blank" href={"/inventorymanager?LotName=" + localstate.itemdata.lot.LotName}>{localstate.itemdata.lot.LotName}</a>
																{(localstate.itemdata.SkidNumber) &&
																	<React.Fragment>
																		, skid #{localstate.itemdata.SkidNumber}
																	</React.Fragment>
																}
															</div>
														</td>
													</tr>
												}
												{(localstate.itemdata.lot) &&
													<tr>
														<td>
															Vendor:
														</td>
														<td>
															<div className={classes.flexitemstaticinput}>
																{localstate.itemdata.Vendor}
															</div>
														</td>
													</tr>
												}
												{(!localstate.itemdata.lot) &&
													<tr>
														<td>
															Vendor:
														</td>
														<td>
															<input className={classes.flexiteminput}
																type="text"
																defaultValue={localstate.itemdata.Vendor}
																onKeyUp={(event) => onChangeValue(event, "Vendor")}
															/>
														</td>
													</tr>
												}

												{(localstate.itemdata.RestoredBool === 1) &&
													<tr>
														<td>
															<span style={{ fontWeight: "bold" }}>Restored From:</span>
														</td>
														<td>
															<div className={classes.flexitemstaticinput}>
																{dayjs(localstate.itemdata.RestoredFromDate).format("MMMM D, YYYY [at] h:mma")}
															</div>
														</td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</Grid>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Last Audit:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{dayjs(localstate.itemdata.LastAudit).format("MMMM D, YYYY [at] h:mma")}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Updated at:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{dayjs(localstate.itemdata.updated_at).format("MMMM D, YYYY [at] h:mma")}
														</div>
													</td>
												</tr>

												<tr>
													<td>
														Updated By:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.updated_by}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Item ID:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.ItemID}
														</div>
													</td>
												</tr>
												{(userPerms.deleteItem === 1) &&
													<tr>
														<td colSpan="2">
															<div style={{ float: "right" }}>
																<Button className={classes.bluebtn}
																	color="primary" variant="contained"
																	onClick={() => DeleteItem()}>
																	<DeleteIcon />&nbsp;&nbsp;Delete Item
																</Button>
															</div>
														</td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</Grid>
								{/*
					<Grid item sm={12} md={6}>
						<div className={classes.flextabledebug}>
							<table className={classes.itemtable}>
								<tbody>
									<tr><td style={{ width: "140px" }}></td></tr>
								</tbody>
							</table>
						</div>
					</Grid>
					<Grid item sm={12} md={6}>
						<div className={classes.flextabledebug}>
							<table className={classes.itemtable}>
								<tbody>
									<tr><td style={{ width: "140px" }}></td></tr>
								</tbody>
							</table>
						</div>
					</Grid>
					*/}
								{(localstate.itemdata.manifests.length > 0 && !localstate.historymode) &&
									<React.Fragment>
										<div className={classes.itemtableheader}>Manifests</div>
										<div style={{ padding: "0px", width: "100%" }}>
											<table className={classes.simpletable} style={{ textAlign: "left" }}>
												<thead>
													<tr key="mdefault">
														<td style={{ paddingLeft: "16px" }}>
															Order Number
														</td>
														<td>
															Name
														</td>
														<td>
															Date
														</td>
														<td>Assigned
														</td>
													</tr>
												</thead>
												<tbody>
													{localstate.itemdata.manifests.map((manifest) => (
														<React.Fragment key={"m" + manifest.ID}>
															<tr style={manifest.Valid ? { backgroundColor: "#c8efc8" } : {}}>
																<td style={{ paddingLeft: "16px" }}>
																	<a target="_blank" href={"/order/" + manifest.InternalOrderID}>{manifest.order.OrderID}&nbsp;</a>
																</td>
																<td>
																	{/* Systems integration prefers a Billing Full Name, BFullName. It is possible this could be empty however. */}
																	{(manifest.order.BFullName) &&
																		<span>{manifest.order.BFullName}&nbsp;</span>
																	}
																	{!(manifest.order.BFullName) &&
																		<span>{manifest.order.BFirstName} {manifest.order.BLastName}&nbsp;</span>
																	}
																</td>
																<td>
																	{dayjs(manifest.order.OrderDate).format("MMMM D, YYYY")}
																</td>
																<td>
																	{(manifest.Valid ? 'Yes' : 'No')}&nbsp;
																</td>
															</tr>
														</React.Fragment>
													))}
												</tbody>
											</table>
										</div>
									</React.Fragment>
								}




								{/* Start of Captured Specs Accordion */}
								{(localstate.itemdata.specs.length > 0) &&
									<React.Fragment>
										<div className={classes.itemtableheader}>Captured Specs</div>
										{localstate.itemdata.specs.map((spec) => (
											<Accordion key={spec.ID} sx={{ width: "100%" }} className={classes.flexaccordion}>
												<AccordionSummary
													expandIcon={<ArrowForwardIosSharpIcon />}
													aria-controls="panel1a-content"
													id="panel1a-header"
												>
													<div style={{ textAlign: "center", width: "100%" }}>
														{dayjs(spec.created_at).format("MMMM D, YYYY")}&nbsp;-&nbsp;{spec.SpecStep}
													</div>
												</AccordionSummary>
												<AccordionDetails>
													<Grid container spacing={0}>
														<Grid item sm={12} md={6}>
															<div className={classes.flextabledebug}>
																<table className={classes.itemtable}>
																	<tbody>
																		<tr>
																			<td style={{ width: "140px" }}>
																				Mfg:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.Manufacturer}
																				</div>
																			</td>
																		</tr>
																		<tr>
																			<td style={{ width: "140px" }}>
																				Model:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.Model}
																				</div>
																			</td>
																		</tr>
																		<tr>
																			<td style={{ width: "140px" }}>
																				CPU:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.CPU}
																				</div>
																			</td>
																		</tr>
																		<tr>
																			<td style={{ width: "140px" }}>
																				TotalRAM:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.TotalRAM}
																				</div>
																			</td>
																		</tr>
																		{(spec.HDD1Size) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					HDD 1 Size:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.HDD1Size}
																					</div>
																				</td>
																			</tr>
																		}
																		{(spec.HDD2Size) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					HDD 2 Size:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.HDD2Size}
																					</div>
																				</td>
																			</tr>
																		}
																		{(spec.Video1Desc) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					Video 1:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.Video1Desc}
																					</div>
																				</td>
																			</tr>
																		}
																		{(spec.Video2Desc) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					Video 2:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.Video2Desc}
																					</div>
																				</td>
																			</tr>
																		}
																		{(spec.HorizontalResolution) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					Resolution:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.HorizontalResolution}x{spec.VerticalResolution}
																					</div>
																				</td>
																			</tr>
																		}

																		{(spec.Batt1Health) &&
																			<React.Fragment>
																				<tr>
																					<td style={{ width: "140px" }}>

																					</td>
																					<td>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																							Battery 1
																						</div>
																					</td>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Health:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt1Health}
																						</div>
																					</td>
																				</tr>
																				{(spec.Batt1Name) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Name:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt1Name}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Batt1Manufacturer) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Manufacturer:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt1Manufacturer}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Batt1SerialNumber) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Serial:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt1SerialNumber}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Batt1PowerState) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Power State:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt1PowerState}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Batt1ChargeCycles) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Cycles:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt1ChargeCycles}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Batt1Charge) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Charge:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt1Charge}
																							</div>
																						</td>
																					</tr>
																				}
																			</React.Fragment>
																		}

																		{(spec.Batt2Health) &&
																			<React.Fragment>
																				<tr>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																					<td>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																							Battery 2
																						</div>
																					</td>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Health:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt2Health}
																						</div>
																					</td>
																				</tr>
																				{(spec.Batt2Name) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Name:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt2Name}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Batt2Manufacturer) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Manufacturer:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt2Manufacturer}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Batt2SerialNumber) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Serial:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt2SerialNumber}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Batt2PowerState) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Power State:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt2PowerState}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Batt2ChargeCycles) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Cycles:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt2ChargeCycles}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Batt2Charge) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Charge:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Batt2Charge}
																							</div>
																						</td>
																					</tr>
																				}
																			</React.Fragment>
																		}


																		{(spec.HDD1Size) &&
																			<React.Fragment>
																				<tr>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																					<td>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																							Hard Drive 1
																						</div>
																					</td>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Size:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD1Size}
																						</div>
																					</td>
																				</tr>
																				{(spec.HDD1Manufacturer) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Manufacturer:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD1Manufacturer}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD1Model) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Model:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD1Model}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD1SerialNumber) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Serial:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD1SerialNumber}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD1InterfaceType) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Interface:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD1InterfaceType}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD1SMARTStatus) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							SMART Status:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD1SMARTStatus}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD1ReallocatedSectors) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Reallocated:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD1ReallocatedSectors}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD1PendingSectors) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Pending:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD1PendingSectors}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD1Temp) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Temp:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD1Temp}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD1PowerOnHours) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Hours:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD1PowerOnHours}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD1PendingSectors) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Pending:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD1PendingSectors}
																							</div>
																						</td>
																					</tr>
																				}
																			</React.Fragment>
																		}
																		{(spec.HDD2Size) &&
																			<React.Fragment>
																				<tr>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																					<td>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																							Hard Drive 2
																						</div>
																					</td>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Size:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD2Size}
																						</div>
																					</td>
																				</tr>
																				{(spec.HDD2Manufacturer) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Manufacturer:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD2Manufacturer}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD2Model) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Model:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD2Model}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD2SerialNumber) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Serial:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD2SerialNumber}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD2InterfaceType) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Interface:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD2InterfaceType}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD2SMARTStatus) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							SMART Status:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD2SMARTStatus}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD2ReallocatedSectors) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Reallocated:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD2ReallocatedSectors}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD2PendingSectors) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Pending:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD2PendingSectors}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD2Temp) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Temp:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD2Temp}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD2PowerOnHours) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Hours:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD2PowerOnHours}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.HDD2PendingSectors) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Pending:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.HDD2PendingSectors}
																							</div>
																						</td>
																					</tr>
																				}
																			</React.Fragment>
																		}
																		{(spec.Network1) &&
																			<React.Fragment>
																				<tr>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																					<td>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																							Network
																						</div>
																					</td>
																					<td>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Device 1:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Network1}
																						</div>
																					</td>
																				</tr>
																				{(spec.Network2) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Device 2:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Network2}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Network3) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Device 3:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Network3}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Network4) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Device 4:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Network4}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Network5) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Device 5:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Network5}
																							</div>
																						</td>
																					</tr>
																				}
																				{(spec.Network6) &&
																					<tr>
																						<td style={{ width: "140px" }}>
																							Device 6:
																						</td>
																						<td>
																							<div className={classes.flexitemstaticinput}>
																								{spec.Network6}
																							</div>
																						</td>
																					</tr>
																				}
																			</React.Fragment>
																		}
																		<tr>
																			<td style={{ width: "140px" }}>
																				Mfg:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.Mfg}
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</Grid>

														<Grid item sm={12} md={6}>
															<div className={classes.flextabledebug}>
																<table className={classes.itemtable}>
																	<tbody>
																		{(spec.SMBIOSVersion) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					SMBIOS Version:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.SMBIOSVersion}
																					</div>
																				</td>
																			</tr>
																		}
																		{(spec.OSVersion) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					OS:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.OS}
																					</div>
																				</td>
																			</tr>
																		}
																		{(spec.WindowsProductKey) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					Win Key:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.WindowsProductKey}
																					</div>
																				</td>
																			</tr>
																		}
																		{(spec.WindowsBiosKey) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					Win BIOS Key:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.WindowsBiosKey}
																					</div>
																				</td>
																			</tr>
																		}
																		{(spec.OSVersion) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					OS Version:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.OSVersion}
																					</div>
																				</td>
																			</tr>
																		}
																		{(spec.ThermalState) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					Thermal State
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.ThermalState}
																					</div>
																				</td>
																			</tr>
																		}
																		{(spec.ClientIP) &&
																			<tr>
																				<td style={{ width: "140px" }}>
																					Client IP:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.ClientIP}
																					</div>
																				</td>
																			</tr>
																		}

																		{/* Section for Complete Step Testing Results */}
																		{(spec.SpecStep === "Complete") &&
																			<React.Fragment>
																				<tr>
																					<td style={{ width: "140px" }}>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																					<td>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																							Tests
																						</div>
																					</td>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Specs:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{(spec.ChecklistSpecs === 1) &&
																								<CheckIcon color="success" />
																							}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Drivers:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{(spec.ChecklistMissingDevices === 1) &&
																								<CheckIcon color="success" />
																							}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Sound:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{(spec.ChecklistSound === 1) &&
																								<CheckIcon color="success" />
																							}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Keyboard:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{(spec.ChecklistKeyboard === 1) &&
																								<CheckIcon color="success" />
																							}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Wifi:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{(spec.ChecklistWifi === 1) &&
																								<CheckIcon color="success" />
																							}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Battery:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{(spec.ChecklistBattery === 1) &&
																								<CheckIcon color="success" />
																							}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Stress:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{(spec.ChecklistStress === 1) &&
																								<CheckIcon color="success" />
																							}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Activation:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{(spec.ChecklistActivation === 1) &&
																								<CheckIcon color="success" />
																							}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Webcam:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{(spec.ChecklistWebcam === 1) &&
																								<CheckIcon color="success" />
																							}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Keys Hit:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.KeysHit}
																						</div>
																					</td>
																				</tr>
																			</React.Fragment>
																		}

																		{(spec.RAM1Capacity) &&
																			<>
																				<tr>
																					<td style={{ width: "140px" }}>
																						<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																							RAM 1
																						</div>
																					</td>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																				</tr>

																				<tr>
																					<td style={{ width: "140px" }}>
																						Capacity:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM1Capacity}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Mfg:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM1Manufacturer}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Model:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM1Model}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Serial:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM1SerialNumber}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Speed:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM1Speed}
																						</div>
																					</td>
																				</tr>
																			</>
																		}

																		{(spec.RAM2Capacity) &&
																			<>
																				<tr>
																					<td style={{ width: "140px" }}>
																						<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																							RAM 2
																						</div>
																					</td>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																				</tr>

																				<tr>
																					<td style={{ width: "140px" }}>
																						Capacity:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM2Capacity}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Mfg:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM2Manufacturer}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Model:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM2Model}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Serial:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM2SerialNumber}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Speed:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM2Speed}
																						</div>
																					</td>
																				</tr>
																			</>
																		}

																		{(spec.RAM3Capacity) &&
																			<>
																				<tr>
																					<td style={{ width: "140px" }}>
																						<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																							RAM 3
																						</div>
																					</td>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																				</tr>

																				<tr>
																					<td style={{ width: "140px" }}>
																						Capacity:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM3Capacity}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Mfg:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM3Manufacturer}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Model:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM3Model}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Serial:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM3SerialNumber}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Speed:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM3Speed}
																						</div>
																					</td>
																				</tr>
																			</>
																		}

																		{(spec.RAM4Capacity) &&
																			<>
																				<tr>
																					<td style={{ width: "140px" }}>
																						<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																							RAM 4
																						</div>
																					</td>
																					<td>
																						<React.Fragment>&nbsp;</React.Fragment>
																					</td>
																				</tr>

																				<tr>
																					<td style={{ width: "140px" }}>
																						Capacity:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM4Capacity}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Mfg:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM4Manufacturer}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Model:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM4Model}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Serial:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM4SerialNumber}
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td style={{ width: "140px" }}>
																						Speed:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.RAM4Speed}
																						</div>
																					</td>
																				</tr>
																			</>
																		}






																	</tbody>
																</table>
															</div>
														</Grid>
													</Grid>
												</AccordionDetails>
											</Accordion>
										))}
									</React.Fragment>
								}
							</Grid>
						</Typography>
					}



					{/* AUDIT VIEW */}
					{(localstate.auditview) &&
						<Typography variant="h6">
							<Grid container spacing={0}>
								<div className={classes.itemtableheader} style={{ display: "flex" }}>
									<div style={{ flexGrow: 1 }}>Item Details</div>
									{(userPerms.auditItem === 1 && localstate.historyavailable) &&
										<div style={{ flexGrow: 1, textAlign: "right", paddingRight: "5px" }}>
											<span className={classes.disabledhoverunit + " " + classes.unselectable} >History: </span>
											{(localstate.historyindex === 0 ?
												<a className={classes.disabledhoverunit + " " + classes.unselectable} >&lt;Prev</a> :
												<a className={classes.hoverunit + " " + classes.unselectable} onClick={() => ViewRecord(localstate.historyindex - 1)}>&lt;Prev</a>
											)}

											&nbsp;|&nbsp;

											{(localstate.historyindex < localstate.recordhistory.length ?
												<a className={classes.hoverunit + " " + classes.unselectable} onClick={() => ViewRecord(localstate.historyindex + 1)}>Next &gt;</a> :
												<a className={classes.disabledhoverunit + " " + classes.unselectable} >Next &gt;</a>
											)}
										</div>
									}
								</div>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Serial Number:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.SerialNumber.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.SerialNumber}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Mfg:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Mfg.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Mfg}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Model:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Model.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Model}
														</div>
													</td>
												</tr>
												{(localstate.itemdata.product !== undefined) &&
													<tr>
														<td>
															Product:
														</td>
														<td>
															<div className={classes.flexitemstaticinput + (localstate.itemdiffs.ProductID.dirty ? " " + classes.unsavedhighlight : "")}>
																{(localstate.itemdata.product) &&
																	<>
																		{localstate.itemdata.product.Name}
																	</>
																}
															</div>
														</td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</Grid>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Status:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Status.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Status}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Location:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Location.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Location}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Cost:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Cost.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Cost}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Asset Type:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.AssetType.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.AssetType}
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Grid>



								<div className={classes.itemtableheader}>Condition</div>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Overall:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Grade.dirty ? " " + classes.unsavedhighlight : "")}>
															<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																<Button ref={el => btnGradeA.current = el} key="gradeabtn">A</Button>
																<Button ref={el => btnGradeB.current = el} key="gradebbtn">B</Button>
																<Button ref={el => btnGradeC.current = el} key="gradecbtn">C</Button>
																<Button ref={el => btnGradeRepair.current = el} key="graderepairbtn">Repair</Button>
																<Button ref={el => btnGradeScrap.current = el} key="gradescrapbtn">Scrap</Button>
															</ButtonGroup>
														</div>

													</td>
												</tr>
												{(localstate.itemdata.AssetType === "Laptop" || localstate.itemdata.AssetType === "AIO") &&
													<React.Fragment>
														<tr>
															<td>
																Body Grade:
															</td>
															<td>
																<div className={classes.flexitemstaticinput + (localstate.itemdiffs.BodyGrade.dirty ? " " + classes.unsavedhighlight : "")}>
																	<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																		<Button ref={el => btnBodyGradeA.current = el} key="bodygradeabtn">A</Button>
																		<Button ref={el => btnBodyGradeB.current = el} key="bodygradebbtn">B</Button>
																		<Button ref={el => btnBodyGradeC.current = el} key="bodygradecbtn">C</Button>
																		<Button ref={el => btnBodyGradeBad.current = el} key="bodygradebadbtn">Bad</Button>
																	</ButtonGroup>
																</div>

															</td>
														</tr>
														<tr>
															<td>
																LCD Grade:
															</td>
															<td>
																<div className={classes.flexitemstaticinput + (localstate.itemdiffs.LCDGrade.dirty ? " " + classes.unsavedhighlight : "")}>
																	<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																		<Button ref={el => btnLCDGradeA.current = el} key="lcdgradeabtn">A</Button>
																		<Button ref={el => btnLCDGradeB.current = el} key="lcdgradebbtn">B</Button>
																		<Button ref={el => btnLCDGradeC.current = el} key="lcdgradecbtn">C</Button>
																		<Button ref={el => btnLCDGradeBad.current = el} key="lcdgradebadbtn">Bad</Button>
																	</ButtonGroup>
																</div>
															</td>
														</tr>
													</React.Fragment>
												}
												<tr>
													<td>
														Notes:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Notes.dirty ? " " + classes.unsavedhighlight : "")}>
															<TextareaAutosize disabled
																style={{ minHeight: "50px", backgroundColor: "#EEE" }}
																className={classes.flexiteminput}
																defaultValue={localstate.itemdata.Notes}
																onKeyUp={(event) => onChangeValue(event, "Notes")}
															/>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Grid>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Damaged:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.damagedconditions?.dirty ? " " + classes.unsavedhighlight : "")}>
															{(localstate.itemdata.damagedconditions) &&
																<>
																	{
																		localstate.itemdata.damagedconditions.map((condition) => (
																			<div key={condition.ConditionID} className={classes.damagedcondition}>
																				{condition.ConditionText}&nbsp;
																			</div>
																		))
																	}
																</>
															}
														</div>
													</td>
												</tr>
												<tr>
													<td style={{ width: "140px" }}>
														Major:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.majorconditions?.dirty ? " " + classes.unsavedhighlight : "")}>
															{(localstate.itemdata.majorconditions) &&
																<>
																	{
																		localstate.itemdata.majorconditions.map((condition) => (
																			<div key={condition.ConditionID} className={classes.majorcondition}>
																				{condition.ConditionText}&nbsp;
																			</div>
																		))
																	}
																</>
															}
														</div>
													</td>
												</tr>
												<tr>
													<td style={{ width: "140px" }}>
														Minor:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.minorconditions?.dirty ? " " + classes.unsavedhighlight : "")}>
															{(localstate.itemdata.minorconditions) &&
																<>
																	{
																		localstate.itemdata.minorconditions.map((condition) => (
																			<div key={condition.ConditionID} className={classes.minorcondition}>
																				{condition.ConditionText}&nbsp;
																			</div>
																		))
																	}
																</>
															}
														</div>
													</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td>&nbsp;</td>
												</tr>

												{(localstate.itemdata.Repaired === 1) &&
													<tr>
														<td style={{ width: "140px" }}>

														</td>
														<td style={{ fontSize: "12px", textAlign: "left" }}>
															Most recent repair:<br></br>
															{localstate.itemdata.RepairedByName}: {localstate.itemdata.RepairNote}<br></br>
															Date: {dayjs(localstate.itemdata.RepairDate).format("MMMM D, YYYY [at] h:mma")}
														</td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</Grid>

								<div className={classes.itemtableheader}>Specifications</div>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														CPU:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.CPU.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.CPU}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														RAM:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.RAM.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.RAM}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														HDD 1:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.HDD1Size.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.HDD1Size}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														HDD 2:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.HDD2Size.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.HDD2Size}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Optical:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Optical.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Optical}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Batt 1 Health:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Batt1.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Batt1}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Batt 2 Health:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Batt2.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Batt2}
														</div>
													</td>
												</tr>
												{/* Some values are read-only. Most of this comes from spec dumps. */}
												{/* Keep in mind, Laravel doesn't care for blank strings and will update the DB with NULL - making this conditional way easier. */}
												{(localstate.itemdata.Network1) &&
													<tr>
														<td>
															Network 1:
														</td>
														<td>
															<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Network1.dirty ? " " + classes.unsavedhighlight : "")}>
																{localstate.itemdata.Network1}
															</div>
														</td>
													</tr>
												}
												{(localstate.itemdata.Network2) &&
													<tr>
														<td>
															Network 2:
														</td>
														<td>
															<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Network2.dirty ? " " + classes.unsavedhighlight : "")}>
																{localstate.itemdata.Network2}
															</div>
														</td>
													</tr>
												}
												{(localstate.itemdata.Network3) &&
													<tr>
														<td>
															Network 3:
														</td>
														<td>
															<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Network3.dirty ? " " + classes.unsavedhighlight : "")}>
																{localstate.itemdata.Network3}
															</div>
														</td>
													</tr>
												}
												{(localstate.itemdata.Network4) &&
													<tr>
														<td>
															Network 4:
														</td>
														<td>
															<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Network4.dirty ? " " + classes.unsavedhighlight : "")}>
																{localstate.itemdata.Network4}
															</div>
														</td>
													</tr>
												}
												{(localstate.itemdata.Network5) &&
													<tr>
														<td>
															Network 5:
														</td>
														<td>
															<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Network5.dirty ? " " + classes.unsavedhighlight : "")}>
																{localstate.itemdata.Network5}
															</div>
														</td>
													</tr>
												}
												{(localstate.itemdata.Network6) &&
													<tr>
														<td>
															Network 6:
														</td>
														<td>
															<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Network6.dirty ? " " + classes.unsavedhighlight : "")}>
																{localstate.itemdata.Network6}
															</div>
														</td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</Grid>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Webcam:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Webcam.dirty ? " " + classes.unsavedhighlight : "")}>
															{(localstate.itemdata.Webcam === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
															{(localstate.itemdata.Webcam === 0) &&
																<React.Fragment>No</React.Fragment>
															}
														</div>
													</td>
												</tr>
												<tr>
													<td style={{ width: "140px" }}>
														Battery:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Battery.dirty ? " " + classes.unsavedhighlight : "")}>
															{(localstate.itemdata.Battery === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
															{(localstate.itemdata.Battery === 0) &&
																<React.Fragment>No</React.Fragment>
															}
														</div>
													</td>
												</tr>
												<tr>
													<td style={{ width: "140px" }}>
														Touchscreen:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Touchscreen.dirty ? " " + classes.unsavedhighlight : "")}>
															{(localstate.itemdata.Touchscreen === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
															{(localstate.itemdata.Touchscreen === 0) &&
																<React.Fragment>No</React.Fragment>
															}
														</div>
													</td>
												</tr>
												<tr>
													<td style={{ width: "140px" }}>
														Bluetooth:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Bluetooth.dirty ? " " + classes.unsavedhighlight : "")}>
															{(localstate.itemdata.Bluetooth === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
															{(localstate.itemdata.Bluetooth === 0) &&
																<React.Fragment>No</React.Fragment>
															}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Resolution:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Resolution.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Resolution}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Video 1:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Video1Desc.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Video1Desc}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Video 2:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Video2Desc.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Video2Desc}
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Grid>



								{/* MDM History */}
								{/* Some Record Histories are not going to have checkable values. Check For one of them on this update. */}
								{(localstate.itemdiffs.hasOwnProperty('IntuneStatus')) &&
									<>	
										{/* ### Section 1: Intune */}
										{(localstate.itemdata.IntuneStatus === 1 || localstate.itemdiffs.IntuneStatus.dirty || localstate.itemdiffs.IntuneReported.dirty) &&
											<>
												<div className={classes.itemtableheader}>Intune Status</div>
												<Grid item sm={12} md={6}>
													<div className={classes.flextabledebug}>
														<table className={classes.itemtable}>
															<tbody>
																<tr>
																	<td style={{ width: "140px" }}>
																		Status:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.IntuneStatus.dirty ? " " + classes.unsavedhighlight : "")}>
																			{(localstate.itemdata.IntuneStatus === 0 ? "No" : "Yes")}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Status At:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.IntuneStatusDateTime.dirty ? " " + classes.unsavedhighlight : "")}>
																			{dayjs(localstate.itemdata.IntuneStatusDateTime).format("MMMM D, YYYY [at] h:mma")}
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</Grid>
												<Grid item sm={12} md={6}>
													<div className={classes.flextabledebug}>
														<table className={classes.itemtable}>
															<tbody>
																<tr>
																	<td style={{ width: "140px" }}>
																		Reported:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.IntuneReported.dirty ? " " + classes.unsavedhighlight : "")}>
																			{(localstate.itemdata.IntuneReported === 0 ? "No" : "Yes")}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Reported At:
																	</td>
																	<td>
																		{(localstate.itemdata.IntuneReportedDateTime) &&
																			<div className={classes.flexitemstaticinput + (localstate.itemdiffs.IntuneReportedDateTime.dirty ? " " + classes.unsavedhighlight : "")}>
																				{dayjs(localstate.itemdata.IntuneReportedDateTime).format("MMMM D, YYYY [at] h:mma")}
																			</div>
																		}
																	</td>
																</tr>

																<tr>
																	<td>
																		User ID:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.IntuneReportedByUserID.dirty ? " " + classes.unsavedhighlight : "")}>
																			{localstate.itemdata.IntuneReportedByUserID}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Response:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.IntuneReportResponse.dirty ? " " + classes.unsavedhighlight : "")}>
																			{localstate.itemdata.IntuneReportResponse}
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</Grid>
											</>
										}


										{/* ### Section 2: PanGP */}
										{(localstate.itemdata.PanGPStatus === 1 || localstate.itemdiffs.PanGPStatus.dirty || localstate.itemdiffs.PanGPReported.dirty) &&
											<>
												<div className={classes.itemtableheader}>PanGP Status</div>
												<Grid item sm={12} md={6}>
													<div className={classes.flextabledebug}>
														<table className={classes.itemtable}>
															<tbody>
																<tr>
																	<td style={{ width: "140px" }}>
																		Status:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.PanGPStatus.dirty ? " " + classes.unsavedhighlight : "")}>
																			{(localstate.itemdata.PanGPStatus === 0 ? "No" : "Yes")}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Status At:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.PanGPStatusDateTime.dirty ? " " + classes.unsavedhighlight : "")}>
																			{dayjs(localstate.itemdata.PanGPStatusDateTime).format("MMMM D, YYYY [at] h:mma")}
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</Grid>
												<Grid item sm={12} md={6}>
													<div className={classes.flextabledebug}>
														<table className={classes.itemtable}>
															<tbody>
																<tr>
																	<td style={{ width: "140px" }}>
																		Reported:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.PanGPReported.dirty ? " " + classes.unsavedhighlight : "")}>
																			{(localstate.itemdata.PanGPReported === 0 ? "No" : "Yes")}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Reported At:
																	</td>
																	<td>
																		{(localstate.itemdata.PanGPReportedDateTime) &&
																			<div className={classes.flexitemstaticinput + (localstate.itemdiffs.PanGPReportedDateTime.dirty ? " " + classes.unsavedhighlight : "")}>
																				{dayjs(localstate.itemdata.PanGPReportedDateTime).format("MMMM D, YYYY [at] h:mma")}
																			</div>
																		}
																	</td>
																</tr>

																<tr>
																	<td>
																		User ID:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.PanGPReportedByUserID.dirty ? " " + classes.unsavedhighlight : "")}>
																			{localstate.itemdata.PanGPReportedByUserID}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Response:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.PanGPReportResponse.dirty ? " " + classes.unsavedhighlight : "")}>
																			{localstate.itemdata.PanGPReportResponse}
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</Grid>
											</>
										}




										{/* ### Section 3: AbsPersistence */}
										{(localstate.itemdata.AbsPersistenceStatus === 1 || localstate.itemdiffs.AbsPersistenceStatus.dirty || localstate.itemdiffs.AbsPersistenceReported.dirty) &&
											<>
												<div className={classes.itemtableheader}>AbsPersistence Status</div>
												<Grid item sm={12} md={6}>
													<div className={classes.flextabledebug}>
														<table className={classes.itemtable}>
															<tbody>
																<tr>
																	<td style={{ width: "140px" }}>
																		Status:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.AbsPersistenceStatus.dirty ? " " + classes.unsavedhighlight : "")}>
																			{(localstate.itemdata.AbsPersistenceStatus === 0 ? "No" : "Yes")}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Status At:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.AbsPersistenceStatusDateTime.dirty ? " " + classes.unsavedhighlight : "")}>
																			{dayjs(localstate.itemdata.AbsPersistenceStatusDateTime).format("MMMM D, YYYY [at] h:mma")}
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</Grid>
												<Grid item sm={12} md={6}>
													<div className={classes.flextabledebug}>
														<table className={classes.itemtable}>
															<tbody>
																<tr>
																	<td style={{ width: "140px" }}>
																		Reported:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.AbsPersistenceReported.dirty ? " " + classes.unsavedhighlight : "")}>
																			{(localstate.itemdata.AbsPersistenceReported === 0 ? "No" : "Yes")}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Reported At:
																	</td>
																	<td>
																		{(localstate.itemdata.AbsPersistenceReportedDateTime) &&
																			<div className={classes.flexitemstaticinput + (localstate.itemdiffs.AbsPersistenceReportedDateTime.dirty ? " " + classes.unsavedhighlight : "")}>
																				{dayjs(localstate.itemdata.AbsPersistenceReportedDateTime).format("MMMM D, YYYY [at] h:mma")}
																			</div>
																		}
																	</td>
																</tr>

																<tr>
																	<td>
																		User ID:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.AbsPersistenceReportedByUserID.dirty ? " " + classes.unsavedhighlight : "")}>
																			{localstate.itemdata.AbsPersistenceReportedByUserID}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		Response:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinput + (localstate.itemdiffs.AbsPersistenceReportResponse.dirty ? " " + classes.unsavedhighlight : "")}>
																			{localstate.itemdata.AbsPersistenceReportResponse}
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</Grid>
											</>
										}

									</>
								}









								<div className={classes.itemtableheader}>Record Details</div>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Created At:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.ItemCreationDate.dirty ? " " + classes.unsavedhighlight : "")}>
															{dayjs(localstate.itemdata.ItemCreationDate).format("MMMM D, YYYY [at] h:mma")}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Created By:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.CreatedBy.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.CreatedBy}
														</div>
													</td>
												</tr>

												<tr>
													<td>
														Lot ID:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.LotID.dirty ? " " + classes.unsavedhighlight : "")}>
															<a target="_blank" href={"/inventorymanager?LotID=" + localstate.itemdata.LotID}>{localstate.itemdata.LotID}</a>
															{(localstate.itemdata.SkidNumber) &&
																<React.Fragment>
																	, skid #{localstate.itemdata.SkidNumber}
																</React.Fragment>
															}
														</div>
													</td>
												</tr>

												<tr>
													<td>
														Vendor:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.Vendor.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.Vendor}
														</div>
													</td>
												</tr>
												{(localstate.itemdata.RestoredBool === 1) &&
													<tr>
														<td>
															<span style={{ fontWeight: "bold" }}>Restored From:</span>
														</td>
														<td>
															<div className={classes.flexitemstaticinput + (localstate.itemdiffs.RestoredFromDate.dirty ? " " + classes.unsavedhighlight : "")}>
																{dayjs(localstate.itemdata.RestoredFromDate).format("MMMM D, YYYY [at] h:mma")}
															</div>
														</td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</Grid>
								<Grid item sm={12} md={6}>
									<div className={classes.flextabledebug}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Last Audit:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.LastAudit.dirty ? " " + classes.unsavedhighlight : "")}>
															{dayjs(localstate.itemdata.LastAudit).format("MMMM D, YYYY [at] h:mma")}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Updated at:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.updated_at.dirty ? " " + classes.unsavedhighlight : "")}>
															{dayjs(localstate.itemdata.updated_at).format("MMMM D, YYYY [at] h:mma")}
														</div>
													</td>
												</tr>

												<tr>
													<td>
														Updated By:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.updated_by.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.updated_by}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														Item ID:
													</td>
													<td>
														<div className={classes.flexitemstaticinput + (localstate.itemdiffs.ItemID.dirty ? " " + classes.unsavedhighlight : "")}>
															{localstate.itemdata.ItemID}
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Grid>
							</Grid>
						</Typography>
					}
					{/* End of Audit View */}




					<InventoryToolbar state={state} UpdateState={UpdateState} />
				</div>
			}
		</LocalizationProvider>

	)
}

export default Item;
