//Boilerplate Table

/* ##########################  Configuration Sections  ########################## */	
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions

import { useDispatch } from 'react-redux';
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';


import React, { useState, useEffect, useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import fetch from 'cross-fetch';

//Error Context
//*Can be used for success as well!
//Types: ok, warning, danger, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//Search Tools
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

//Icons
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';



/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:11800,
};

const IntegrationsTable = (props) => {
	document.title="Market Integrations";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Admin Settings"));
	dispatch(setCurrentMenuItem("/integrations"));
	const rowRefs = useRef([]);
	const btnSave = useRef();

	/* ##########################  UseState Variables  ########################## */ 
	const classes = useClasses(flexstyles);
	const [state, setState] = useState({
		dbreload:true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		griditems:[],		//Defaults
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws an error.
		order:"asc",
		orderby:"Vendor",
		selectedcount:0,
		rowsperpage:10,
		selectedindexes:[],
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		searchoptions:{
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs:{
				searchpair1:{type:"Name", value: "", mode:"like", uuid:uuidv4()},
				searchpair2:{type:"Vendor", value: "", mode:"like", uuid:uuidv4()},
				searchpair3:{type:"", value: "", mode:"left", uuid:uuidv4()},
				searchpair4:{type:"", value: "", mode:"right", uuid:uuidv4()},
				searchpair5:{type:"", value: "", mode:"like", uuid:uuidv4()},
				searchpair6:{type:"", value: "", mode:"like", uuid:uuidv4()}
			}
		}
	});


	/* ##########################  Column States  ########################## */	 
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		Vendor:true,
		DefaultCommissionRate:true,
		Name:true,
		UserID:true,
		ApplicationID:true,
		Auth:true,
		SecretKey:true,
		Expires:true,
		RefreshToken:false,
		RefreshTokenExpires:false,
		IconURL:true,
		Endpoint:true,
		created_at:true,
		updated_at:true
	});

	const [showmenuEl, setMenuEl] = useState(null);

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);
	
	function UpdateState(stateobject) {
		setState(stateobject);
	}

	const ToggleColumn = (key) => {
		colstate[key] = ! colstate[key];
		CloseMenu();
	}

	/* ##########################  Reusable Menu Method  ########################## */
	const ShowMenu = (event) => {
		setMenuEl(event.currentTarget);
	}
	const CloseMenu = () => {
		setMenuEl(null);
	}


	const SelectRow = (index) => {
		if (localstate.selectedindexes.indexOf(index)===-1){
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length){
				UpdateState(localstate);			
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex,1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length+1)){
				UpdateState(localstate);			
			}
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i=0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
	};



	/* ##########################  Search Options  ########################## */
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	var mode="";
	const onChangeSearchType = (searchtype, searchnumber) => {
		//Search Mode: Each search type may have a different search mode
		//left, right, like, strict, not
		//Default Mode: LIKE
		mode = "like";

		localstate.searchoptions.searchpairs["searchpair"+searchnumber].type = searchtype;
		localstate.searchoptions.searchpairs["searchpair"+searchnumber].mode = mode;        
        //Provision to add columns if selected for search.
        if (searchtype==="Name"){
            colstate["Name"] = true;
            setMenuEl(null);
        }
		UpdateState(localstate);
	}

	//Set Search Value:
	const onChangeSearchValue = debounce(function(searchvalue, searchnumber) {
		localstate.searchoptions.searchpairs["searchpair"+searchnumber].value = searchvalue;
		localstate.dbreload = true;
		UpdateState(localstate);
	},600);

	//Key-Value Inputs
	const [searchinputs, setSearchInputs] = useState({
		show1:true,
		show2:false,
		show3:false,
		show4:false,
		show5:false,
		show6:false,
		lastsearch:1
	});


	const AddSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show"+(newsearchinputs.lastsearch+1)]=true;
		newsearchinputs.lastsearch++;
		setSearchInputs(newsearchinputs);
	}

	const RemoveSearch = (searchinput) => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show"+(newsearchinputs.lastsearch)]=false;
		newsearchinputs.lastsearch--;
		setSearchInputs(newsearchinputs);
	}


	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		//This evaluates if we're requesting the same property and if it's asc.
		const isAsc = localstate.orderby === property && localstate.order === "asc";	
		//We need to evaluate if we should start back at page 1:
		if (localstate.orderby !== property){
			localstate.page = 0;
		}
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		localstate.pendingsaves = false;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);		
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page=0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems(){  
		localstate.selectedindexes = [];
		localstate.selectedcount=0;
		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:localstate.searchoptions.searchpairs
			}
		};
		axios.post(dbendpoint+"/integrations/get?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					for (var i =0; i<res.data.pagedata.data.length; i++){
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						res.data.pagedata.data[i].GridKey=i;
						res.data.pagedata.data[i].isSelected = false;
						res.data.pagedata.data[i].unsaved = false;
						res.data.pagedata.data[i].ExpandRow = false;
					}
					localstate.griditems = res.data.pagedata.data;
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					localstate.griditems=[];
					UpdateState(localstate);
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}

	useEffect(() => {
		document.title="Integrations";
		if (state.dbreload){
			//Avoid duplicate loades.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
		}
	},);



	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	const AddRow = () => {
		localstate.griditems.unshift({
			ID:uuidv4(),
			PendingItem:true,
			Name:"",
			Vendor:"",
			DefaultCommissionRate:10,
			UserID:"",
			ApplicationID:"",
			Auth:"",
			SecretKey:"",
			Expires:"",
			RefreshToken:"",
			RefreshTokenExpires:"",
			IconURL:"",
			Endpoint:"",
			created_at:"",
			updated_at:""
		});
		//All selected indexes move up by 1.
		for (var i=0; i<localstate.selectedindexes.length; i++){
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		var updatearray = [];
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				updatearray.push(localstate.griditems[i]);
			}
		}
		if (localstate.pendingsaves){
			if (updatearray.length>0){
				i=0;
				var limit = updatearray.length-1;
				var updateitems = setInterval(function(){
					var item = updatearray[i];
					//Do work here, API call.
					const postdata = {					
						item:item
					};
					axios.post(dbendpoint+"/integrations/update", postdata, defaultpostoptions).then(res => {
						//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
						if (res.status===200){
							//If ValidateUser() fails to verify user, it sends back 'login' error. 
							if (res.data.Status==="login"){
								//Not logged in. Reload page causes redirect to /login
								window.location.reload(false);
							}
							if (res.data.Status==="Success"){
								//Success response also includes the item!

								//If we sent new rows, we'll need to reference the old ID.
								var itemindex=0;
								if (res.data.OldID){
									itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.OldID);
									localstate.griditems[itemindex].unsaved = false;
									rowRefs.current[itemindex+'SaveStatus'].classList.remove(classes.unsavedhighlight);
									//Set New ID
									localstate.griditems[itemindex].ID = res.data.item.ID;
								} else {
									itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.item.ID);
									localstate.griditems[itemindex].unsaved = false;
									//Refs allow us to update the grid live!
									rowRefs.current[itemindex+'SaveStatus'].classList.remove(classes.unsavedhighlight);
								}
							}
							if (res.data.Status==="Failure"){
								//Failure error
								errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
							}
						} else {
							//Non-200 message from server.
							errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
						}
					});
					
					//If we have completed all items, clear this interval and update state.
					if (i===limit){
						clearInterval(updateitems);
						UpdateState(localstate);
					}
					i++;
				}, 200);
			}
			btnSave.current.style.color="rgba(0, 0, 0, 0.87)";
			btnSave.current.style.backgroundColor="#DFDFDF";
		} else {
			alert("Nothing to save!");
		}
	}


	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () =>{
		var deleteitemsarray =[];
		//Reflect items to user for confimation.
		for (var i=0; i<state.selectedindexes.length; i++){
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i=0; i<localstate.selectedindexes.length; i++){
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")){
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {				
					item:localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint+"/integrations/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;	
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status===200){
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status==="login"){
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status==="Success"){
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID){
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
							} else {
								errors.NewError({errmsg:"Could not delete one or more items.", errshow:true, errtimeout: 8, errtype:"warning"})
							}
						}
						if (res.data.Status==="Failure"){
							//Failure error
							errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
						}
					} else {
						//Non-200 message from server.
						errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}


	/* ##########################  Cell Interaction  ########################## */
	const taborder = ["Name","Vendor","DefaultCommissionRate","UserID","ApplicationID","Auth","SecretKey","RefreshToken","RefreshTokenExpires", "IconURL", "Endpoint"];

	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown"){
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp"){
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length-1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
		//Handle Right Arrow
		if (event.key === "ArrowRight"){
			event.preventDefault();
			//Same Process as Tab
			var tabindex = taborder.indexOf(column);
			for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
				if (i === taborder.length) {
					i = -1;
					if (rowRefs.current[(index + 1) + column]) {
						index = index + 1;
					} else {
						index = 0;
					}
				} else {
					if (taborder[i]) {
						if (colstate[taborder[i]]) {
							rowRefs.current[index + taborder[i]].select();
							break;
						}
					} else {
						i = -1;
					}
				}
			}
		}
		//Handle Left Arrow
		if (event.key === "ArrowLeft"){
			event.preventDefault();
			var tabindex = taborder.indexOf(column);
			var i;
			var rowincrement=0;
			for (i=(tabindex-1); i>-2; i--){
				if (i===-1){
					rowincrement=-1;
					i=taborder.length; //Go to end of tab order
				} else {
					if (colstate[taborder[i]]) {
						break;
					}
				}
			}
			//If tabindex first and we're in the first row, go to the last element on last row.
			if (tabindex===0 && index===0){
				var lastindex = localstate.griditems.length-1;
				rowRefs.current[lastindex + taborder[i]].select();
			} else {
				rowRefs.current[(index+rowincrement) + taborder[i]].select();
			}
		}
	}

	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) =>{
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (index > 0) {
			var decimalchars = (len-1) - index;
			if (decimalchars>2){
				return oldvalue;
			}
		}
		return newvalue;
	}
	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value===""){
			rowRefs.current[index+column].value="0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		if (event.key !== "Tab" && 
			event.key!=="ArrowDown" && 
			event.key!=="ArrowUp" && 
			event.key!=="ShiftLeft" && 
			event.key!=="ShiftRight"
			){
			//Conditionals for Types:
			//Conditionals for Types:
			if (column==="DefaultCommissionRate"){
				newvalue = RestrictNumber(newvalue, oldvalue);
			}
			if (column==="Cost"){
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Cost changes, so does Margin
				rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
			}
			if (column==="Price"){
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Price changes, so does Margin
				rowRefs.current[index+"Margin"].textContent = (newvalue - rowRefs.current[index+"Cost"].value).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
			}
			//Provision for Booleans that require a re-render. Expensive!
			if (column==="SomeBoolean"){
				if (event.target.checked){
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}
				
			} else {
				//Selects already render the correct and unsaved result of selecting. (but right now they cause rerender via state.... possible change here.)
				if (column==="NewCheckbox") { //MaterialUI Checkbox
					if (event.target.checked){
						localstate.griditems[index][column] = 1;
					} else {
						localstate.griditems[index][column] = 0;
					}
				} else if (column==="SomeSelectable") {
					//Status works similar to booleans - Component takes care of view while localstate has yet to update the DB
					localstate.griditems[index][column] = newvalue;
				} else {
					//Update Refs like usual.
					rowRefs.current[index+column].value=newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
			btnSave.current.style.color="white";
			btnSave.current.style.backgroundColor="#01579B";
		}
	}


	/* ##########################  Button Functions  ########################## */

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ResetSearches = () => {
		//Reset all:
		for (var i=1; i<7; i++){
			localstate.searchoptions.searchpairs["searchpair"+i].type = "";
			localstate.searchoptions.searchpairs["searchpair"+i].value = "";
			localstate.searchoptions.searchpairs["searchpair"+i].mode = "like";
			localstate.searchoptions.searchpairs["searchpair"+i].uuid = uuidv4();
		}
		//Set Defaults:
		localstate.searchoptions.searchpairs.searchpair1.type = "Name";
		localstate.dbreload = true;
		UpdateState(localstate);
	}


	/* ##########################  TABLE HEAD  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "Name", numeric: false, label: "Name", align:"left", allowsort:true, style:{width:"400px"}},
		{ id: "Vendor", numeric: true, label: "Vendor", align:"left", allowsort:true, style:{}},
		{ id: "DefaultCommissionRate", numeric: true, label: "CommRate%", align:"center", allowsort:true, style:{}},
		{ id: "UserID", numeric: true, label: "UserID", align:"left", allowsort:false, style:{}},
		{ id: "ApplicationID", numeric: true, label: "ApplicationID", align:"left", allowsort:false, style:{}},
		{ id: "Auth", numeric: true, label: "Auth / API Key", align:"left", allowsort:true, style:{}},
		{ id: "SecretKey", numeric: false, label: "SecretKey / Password", align:"left", allowsort:true, style:{}},
		{ id: "Expires", numeric: false, label: "Expires", align:"left", allowsort:true, style:{}},
		{ id: "RefreshToken", numeric: false, label: "RefreshToken", align:"center", allowsort:true, style:{}},
		{ id: "RefreshTokenExpires", numeric: false, label: "RefreshTokenExpires", align:"center", allowsort:true, style:{}},
		{ id: "IconURL", numeric: false, label: "IconURL", align:"center", allowsort:true, style:{}},
		{ id: "Endpoint", numeric: false, label: "Endpoint", align:"center", allowsort:true, style:{}},
		{ id: "created_at", numeric: false, label: "created_at", align:"center", allowsort:true, style:{}},
		{ id: "updated_at", numeric: false, label: "updated_at",  align:"left", allowsort:true, style:{}},
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};
	
		return (
			<thead style={{display:"table-header-group"}}>
				<tr style={{border:"1px solid #CCC",
							backgroundColor:"#DDD"}}>
					<td style={{width: "14px", padding:"none", display:"table-cell", padding:"2px 4px 2px 5px"}}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) => 
					colstate[headCell.id] &&
					( 	
						<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}				
							>
								{(headCell.allowsort) && 
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id) 
											? <span style={{fontWeight:"bold"}}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) && 
									<span>{headCell.label}</span>
								}
							</td>
					))}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};


	/* 
	                                                                                         
         _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
        _/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
       _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
      _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
     _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
                                                                                         
                                                                                         
 	*/


	/* ##########################  Render Function  ########################## */
	return (
        <div style={{padding:"8px", overflow:"auto", minWidth:"600px"}}>
			{/* Standard Page Header with right floated error message space */ }
			<div style={{height:"35px"}}>
				<div style={{textAlign:"center"}}>
					<h2>Integrations</h2>
				</div>
				{(errors.currenterror.errshow) &&
					<div style={{position:"relative", float:"right", bottom:"26px", height:"25px", fontSize:"12px"}}>
						<ErrorMessage />
					</div>
				}
			</div>

			{/* /* ##########################  Search Inputs  ########################## */	}
			{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
			{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}
			
			<div>

				{/* Key-Value Searches */}


				{/* Search Pair 1 */}
				{(searchinputs.show1) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
							<Select
								key={localstate.searchoptions.searchpairs.searchpair1.uuid}
								value={localstate.searchoptions.searchpairs.searchpair1.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, 1)}
							>
								<MenuItem value={"Sku"}>Sku</MenuItem>
								<MenuItem value={"Title"}>Title</MenuItem>
								<MenuItem value={"Gtin"}>Gtin</MenuItem>
								<MenuItem value={"Location"}>Location</MenuItem>
								<MenuItem value={"SkuEnding"}>Sku Ending</MenuItem>
								<MenuItem value={"NotSku"}>NotSku</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search1" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair1.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, 1)} />
						<div style={{ width: "30px" }}>&nbsp;</div>
					</div>
				}

				{/* Search Pair 2 */}
				{(searchinputs.show2) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair2.uuid}
								value={localstate.searchoptions.searchpairs.searchpair2.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, 2)}
							>
								<MenuItem value={"Sku"}>Sku</MenuItem>
								<MenuItem value={"Title"}>Title</MenuItem>
								<MenuItem value={"Gtin"}>Gtin</MenuItem>
								<MenuItem value={"Location"}>Location</MenuItem>
								<MenuItem value={"SkuEnding"}>Sku Ending</MenuItem>
								<MenuItem value={"NotSku"}>NotSku</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search2" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair2.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, 2)} />
						<div style={{ width: "30px", float:"right" }}>
							{(searchinputs.show2 && !searchinputs.show3) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch(2)}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}

				{/* Search Pair 3 */}
				{(searchinputs.show3) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair3.uuid}
								value={localstate.searchoptions.searchpairs.searchpair3.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, 3)}
							>
								<MenuItem value={"Sku"}>Sku</MenuItem>
								<MenuItem value={"Title"}>Title</MenuItem>
								<MenuItem value={"Gtin"}>Gtin</MenuItem>
								<MenuItem value={"Location"}>Location</MenuItem>
								<MenuItem value={"SkuEnding"}>Sku Ending</MenuItem>
								<MenuItem value={"NotSku"}>NotSku</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search3" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair3.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, 3)} />
						<div style={{ width: "30px" }}>
							{(searchinputs.show3 && !searchinputs.show4) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch(3)}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}

				{/* Search Pair 4 */}
				{(searchinputs.show4) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair4.uuid}
								value={localstate.searchoptions.searchpairs.searchpair4.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, 4)}
							>
								<MenuItem value={"Sku"}>Sku</MenuItem>
								<MenuItem value={"Title"}>Title</MenuItem>
								<MenuItem value={"Gtin"}>Gtin</MenuItem>
								<MenuItem value={"Location"}>Location</MenuItem>
								<MenuItem value={"SkuEnding"}>Sku Ending</MenuItem>
								<MenuItem value={"NotSku"}>NotSku</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search4" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair4.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, 4)} />
						<div style={{ width: "30px" }}>
							{(searchinputs.show4 && !searchinputs.show5) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch(4)}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}


				{/* Search Pair 5 */}
				{(searchinputs.show5) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair5.uuid}
								value={localstate.searchoptions.searchpairs.searchpair5.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, 5)}
							>
								<MenuItem value={"Sku"}>Sku</MenuItem>
								<MenuItem value={"Title"}>Title</MenuItem>
								<MenuItem value={"Gtin"}>Gtin</MenuItem>
								<MenuItem value={"Location"}>Location</MenuItem>
								<MenuItem value={"SkuEnding"}>Sku Ending</MenuItem>
								<MenuItem value={"NotSku"}>NotSku</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search5" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair5.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, 5)} />
						<div style={{ width: "30px" }}>
							{(searchinputs.show5 && !searchinputs.show6) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch(5)}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}

				{/* Search Pair 6 */}
				{(searchinputs.show6) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair6.uuid}
								value={localstate.searchoptions.searchpairs.searchpair6.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, 6)}
							>
								<MenuItem value={"Sku"}>Sku</MenuItem>
								<MenuItem value={"Title"}>Title</MenuItem>
								<MenuItem value={"Gtin"}>Gtin</MenuItem>
								<MenuItem value={"Location"}>Location</MenuItem>
								<MenuItem value={"SkuEnding"}>Sku Ending</MenuItem>
								<MenuItem value={"NotSku"}>NotSku</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search6" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair6.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, 6)} />
						<div style={{ width: "30px" }}>
							{(searchinputs.show6) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch(6)}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}


				{/* On last search input, don't show! */}
				{(!searchinputs.show6) &&
					<div style={{display:"inline-block", paddingLeft:"15px"}}>
						<IconButton size="small" aria-label="Add Search" onClick={()=>AddSearch()}>
							<AddIcon color="primary" fontSize="large" style={{padding:"5px"}}></AddIcon>
						</IconButton>
					</div>
				}

			</div>


			{/* Top Buttons & Pagination */}
			<div style={{height:"5px"}}>&nbsp;</div>
			<div>
				{(!showdeleteconfirmation) &&
					<React.Fragment>
				
						<Button
                            className={classes.buttonmargin}
                            variant="contained"
							color="secondary"
                            onClick={() => SaveChanges()}
                            ref={el => btnSave.current = el}>
							Save Changes
						</Button>
					
						<Button
                            className={classes.buttonmargin}
                            variant="contained"
							color="secondary"
                            onClick={() => AddRow()}>
							Add Row
						</Button>
					
						<Button
                            className={classes.buttonmargin}
                            variant="contained"
							color="secondary"
                            onClick={() => DeleteSelectedInit()}>
							Delete Selected
						</Button>

						{/* ##########################  Column Toggles  ########################## */}
						<Button
                            className={classes.buttonmargin}
                            variant="contained"
							color="secondary"
                            aria-haspopup="true"
                            onClick={ShowMenu}>
							Columns
						</Button>
						<Menu
							id="simple-menu"
							anchorEl={showmenuEl}
							keepMounted
							open={Boolean(showmenuEl)}
							onClose={CloseMenu}
						>
							<MenuItem onClick={()=>ToggleColumn('Name')} className={colstate.Name ? classes.selectedcolumn : ''}>Name</MenuItem>		
							<MenuItem onClick={()=>ToggleColumn('Vendor')} className={colstate.Vendor ? classes.selectedcolumn : ''}>Vendor</MenuItem>
							<MenuItem onClick={()=>ToggleColumn('DefaultCommissionRate')} className={colstate.DefaultCommissionRate ? classes.selectedcolumn : ''}>Comm Rate</MenuItem>	
							<MenuItem onClick={()=>ToggleColumn('UserID')} className={colstate.UserID ? classes.selectedcolumn : ''}>UserID</MenuItem>	
							<MenuItem onClick={()=>ToggleColumn('ApplicationID')} className={colstate.ApplicationID ? classes.selectedcolumn : ''}>Application ID</MenuItem>	
							<MenuItem onClick={()=>ToggleColumn('Auth')} className={colstate.Auth ? classes.selectedcolumn : ''}>Auth</MenuItem>	
							<MenuItem onClick={()=>ToggleColumn('SecretKey')} className={colstate.SecretKey ? classes.selectedcolumn : ''}>SecretKey</MenuItem>	
							<MenuItem onClick={()=>ToggleColumn('Expires')} className={colstate.Expires ? classes.selectedcolumn : ''}>Expires</MenuItem>	
							<MenuItem onClick={()=>ToggleColumn('RefreshToken')} className={colstate.RefreshToken ? classes.selectedcolumn : ''}>RefreshToken</MenuItem>	
							<MenuItem onClick={()=>ToggleColumn('RefreshTokenExpires')} className={colstate.RefreshTokenExpires ? classes.selectedcolumn : ''}>RefreshTokenExpires</MenuItem>	
							<MenuItem onClick={()=>ToggleColumn('IconURL')} className={colstate.IconURL ? classes.selectedcolumn : ''}>IconURL</MenuItem>	
							<MenuItem onClick={()=>ToggleColumn('Endpoint')} className={colstate.Endpoint ? classes.selectedcolumn : ''}>Endpoint</MenuItem>	
							<MenuItem onClick={()=>ToggleColumn('created_at')} className={colstate.created_at ? classes.selectedcolumn : ''}>Create Date</MenuItem>	
							<MenuItem onClick={()=>ToggleColumn('updated_at')} className={colstate.updated_at ? classes.selectedcolumn : ''}>Update Date</MenuItem>	
						</Menu>
					</React.Fragment>
				}

				{/* Delete Items Confirmation */}
				{(showdeleteconfirmation) &&
					<div>
						<b>Are you sure you want to delete these items?</b>
						<div style={{padding:"10px 0px"}}>
							{deleteitems.map((row, index) => { 
                                if (deleteitems.length === index + 1) {
									return(<span key={index}>{row.Name}</span>)
								} else {
									return(<span key={index}>{row.Name}, </span>)
								}
							})}
						</div>
						<Button className={classes.warningbutton} variant="contained" onClick={()=>DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
						<Button variant="contained" onClick={()=>CancelDelete()}>Cancel</Button>
					</div>
				}


				{(localstate.totalitems>0) &&
					<TablePagination  className={classes.paginationalign}
						style={{display:"inline-flex", float:"right"}}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[10,20]}
					/>
				}
			</div>
			{/* End of Top Buttons & Pagination */}


			{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
			<div className={classes.flexgridcontainerA}>
					{/* ##########################  Start of Table  ########################## */}
					<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{minWidth:"100%", borderCollapse:"collapse", borderColor:"grey"}}>
					<EnhancedTableHead
						numSelected={localstate.selectedcount} 
						classes={classes}
						order={localstate.order}
						orderBy={localstate.orderby}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={state.griditems.length}
					/>
					{/* /* ##########################  Row Design  ########################## */	 }
					{/* If DB reload, don't allow view of table. */}
					{(!localstate.dbreload) &&
						<tbody style={{ display: "table-row-group" }}>
							{(localstate.griditems.length > 0) &&
								localstate.griditems.map((row, index) => {
									//Create all-new refs on each render. Helps avoid issues with grid states.
									rowRefs.current[index + "Checkbox"] = React.createRef();
									rowRefs.current[index + "SaveStatus"] = React.createRef();
									rowRefs.current[index + "Name"] = React.createRef();

									rowRefs.current[index + "Vendor"] = React.createRef();
									rowRefs.current[index + "DefaultCommissionRate"] = React.createRef();
									rowRefs.current[index + "UserID"] = React.createRef();
									rowRefs.current[index + "ApplicationID"] = React.createRef();
									rowRefs.current[index + "Auth"] = React.createRef();
									rowRefs.current[index + "SecretKey"] = React.createRef();
									rowRefs.current[index + "Expires"] = React.createRef();
									rowRefs.current[index + "RefreshToken"] = React.createRef();
									rowRefs.current[index + "RefreshTokenExpires"] = React.createRef();
									rowRefs.current[index + "IconURL"] = React.createRef();
									rowRefs.current[index + "Endpoint"] = React.createRef();
									return (
										<React.Fragment key={row.ID}>
											<tr	className={classes.flexgridrow}>
												{/* Checkbox - Requires inner div to change background color with SaveStatus */}
												<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
													<div style={{ padding: "3px 4px 1px 4px" }}>
														{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
														{/* Don't show checkbox if this is a PendingItem */}
														{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el=>rowRefs.current[index+"Checkbox"]=el} 
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}

													</div>
												</td>

												{/* Name - Optional Expand toggle! Change flexgridinput-30 to flexgridinput to remove icon spacer. */}
												{(colstate.Name) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "Name"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "Name")}
															onKeyUp={(event) => onChangeValue(event, index, "Name")}
															defaultValue={row.Name} />
													</td>
												}


												{/* Vendor */}
												{(colstate.Vendor) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "Vendor"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "Vendor")}
															onKeyUp={(event) => onChangeValue(event, index, "Vendor")}
															defaultValue={row.Vendor} />
													</td>
												}

												{/* DefaultCommissionRate */}
												{(colstate.DefaultCommissionRate) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															type="number" step="0.01"
															ref={el => rowRefs.current[index + "DefaultCommissionRate"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "50px", textAlign: "center" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "DefaultCommissionRate")}
															onKeyUp={(event) => onChangeValue(event, index, "DefaultCommissionRate")}
															onBlur={(event)=> DetectBlankNumber(event, index, "DefaultCommissionRate")}
															defaultValue={row.DefaultCommissionRate} />
													</td>
												}

												{/* UserID */}
												{(colstate.UserID) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "UserID"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "UserID")}
															onKeyUp={(event) => onChangeValue(event, index, "UserID")}
															defaultValue={row.UserID} />
													</td>
												}

												{/* ApplicationID */}
												{(colstate.ApplicationID) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "ApplicationID"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "ApplicationID")}
															onKeyUp={(event) => onChangeValue(event, index, "ApplicationID")}
															defaultValue={row.ApplicationID} />
													</td>
												}

												{/* Auth */}
												{(colstate.Auth) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "Auth"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "Auth")}
															onKeyUp={(event) => onChangeValue(event, index, "Auth")}
															defaultValue={row.Auth} />
													</td>
												}

												{/* SecretKey */}
												{(colstate.SecretKey) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "SecretKey"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "SecretKey")}
															onKeyUp={(event) => onChangeValue(event, index, "SecretKey")}
															defaultValue={row.SecretKey} />
													</td>
												}

												{/* Expires */}
												{(colstate.Expires) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "Expires"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "Expires")}
															onKeyUp={(event) => onChangeValue(event, index, "Expires")}
															defaultValue={row.Expires} />
													</td>
												}

												{/* RefreshToken */}
												{(colstate.RefreshToken) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "RefreshToken"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "RefreshToken")}
															onKeyUp={(event) => onChangeValue(event, index, "RefreshToken")}
															defaultValue={row.RefreshToken} />
													</td>
												}

												{/* RefreshTokenExpires */}
												{(colstate.RefreshTokenExpires) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "RefreshTokenExpires"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "RefreshTokenExpires")}
															onKeyUp={(event) => onChangeValue(event, index, "RefreshTokenExpires")}
															defaultValue={row.RefreshTokenExpires} />
													</td>
												}

												{/* IconURL */}
												{(colstate.IconURL) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "IconURL"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "IconURL")}
															onKeyUp={(event) => onChangeValue(event, index, "IconURL")}
															defaultValue={row.IconURL} />
													</td>
												}

												{/* Endpoint */}
												{(colstate.Endpoint) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "Endpoint"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "Endpoint")}
															onKeyUp={(event) => onChangeValue(event, index, "Endpoint")}
															defaultValue={row.Endpoint} />
													</td>
												}

												{/* Create Date */}
												{(colstate.created_at) &&
													<td className={classes.flexgridstaticcontainer}>
														{row.created_at}
													</td>
												}

												{/* Update Date */}
												{(colstate.updated_at) &&
													<td className={classes.flexgridstaticcontainer}>
														{row.updated_at}
													</td>
												}

											</tr>
											{/* Try: conditional for any render whatsoever! */}
											{(row.ExpandRow === true) &&
												<tr>
													<td colSpan="100%">
														<div style={{ margin: "25px" }}>
															Expanded column container!
													</div>
													</td>
												</tr>
											}
										</React.Fragment>

									)
								}
								)
							}
							{(localstate.griditems.length === 0) &&
								<tr className="flexgridrow"><td colSpan="100%"
									style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
							}
						</tbody>
					}
					{(localstate.dbreload) &&
						<tbody>
							<tr>
								<td colSpan="100%">
									<div style={{padding:"20px", textAlign:"center", margin:"auto"}}>
										<CircularProgress />
									</div>
								</td>
							</tr>
						</tbody>
					}
				</table> 
			</div>
			

			
			{(localstate.totalitems > localstate.rowsperpage) &&
				<TablePagination className={classes.paginationalign}
					component="div"
					count={localstate.totalitems}
					page={localstate.page}
					onPageChange={handleChangePage}
					rowsPerPage={localstate.rowsperpage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPageOptions={[10,20]}
				/>
			}

			<div>
				Name: Custom user generated name for the integration.<br></br>
				Auth: Ebay\Newegg Auth<br></br>
				SecretKey: Newegg<br></br>
				RefreshToken: Ebay<br></br>
				RefreshTokenExpires: Ebay<br></br>
				Walmart: ClientID(UserID), ClientSecret(SecretKey), <br></br>
				Amazon: ClientID(UserID), ClientSecret (ApplicationID), RefreshToken(SecretKey)<br></br>
				BackMarket: User Agent (User ID), Authorization (Auth/API Key)

				<br></br>
				<br></br>
				Initiate some with any valid datetime for Expires.
			</div>

		</div>
    );
}

export default IntegrationsTable;
