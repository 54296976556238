 //Item Search and Quick Edit

/* ##########################  Configuration Sections  ########################## */
//## UseState Variables
//##Column Configuration
//##Column States
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions

//Default Views:
//Cost: admin, manager

//Special Abilities:
//Delete Items: admin, manager


//Inventory Manager App

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

//Datetime formatting
import Moment from 'react-moment';
import 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import dayjs from 'dayjs'; //Used with new datetimepickers

import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	useLocation,
	useHistory
} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

//Redux Features
//ProgressBar
import { useDispatch } from 'react-redux';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
} from '../../features/progressbar/progressbarSlice';

import {
	selectMainMenu,
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
import { AppContext } from "../Auth/contexts/AppContext";

//Datetime formatting
//import moment from 'moment';
//import Moment from 'react-moment';

//Sounds
//import successaudiosrc from '../assets/sounds/success2.wav';
import failureaudiosrc from '../assets/sounds/failure2.wav';

//Column Toggles
import FormGroup from '@mui/material/FormGroup';
//import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

//Search Tools
import SearchInput from "../common/SearchInput";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';


//Tables
//import TablePagination from '@mui/material/TablePagination';
import FlexTablePagination from '../common/FlexTablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';

//ErrorContext
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//App Components
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

//Icons
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import RestartAltIcon from '@mui/icons-material/RestartAlt';


//Inventory Toolbar
import InventoryToolbar from './InventoryToolbar';

//Export
import ExportCSV from '../common/ExportCSV';

//Audio
const failureaudio = new Audio(failureaudiosrc);

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;
var hostbase = process.env.REACT_APP_BASE;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 20000,
};


//Helper Functions

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}

const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function (acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	}, []);
	return results;
}

const InventoryManager = (props) => {
	const dispatch = useDispatch();
	document.title = "Inventory Manager";
	dispatch(setCurrentMenuSection("Inventory"));
	dispatch(setCurrentMenuItem("/inventorymanager"));
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	const classes = useClasses(flexstyles);
	const rowRefs = useRef([]);

	
	//Init with URL search parameters:
	// could be '?Name=Chad'
	const params = new URLSearchParams(useLocation().search);
	var lotnameparameter = params.get('LotName');

	var lotidparameter = params.get('LotID');

	// Try for Order Number (OrderID)
	var orderparameter = params.get('OrderID');

	var modelparameter = params.get('Model');

	var serialparameter = params.get('SerialNumber');

	// These parameters can help auto-fill an autocomplete search input!
	var productparameter = false;
	var productidparameter = params.get('ProductID');
	var productnameparamter = params.get('ProductName');
	if (productidparameter){
		productparameter = [{
				ProductID: productidparameter,
				Name: productnameparamter
			}
		];
	}
	

	//Container and Key Sizes
	const containersizes="260px";
	const keysizes="130px";

	//Search Configuration:
	const activesearchescount = 8;

	//Search Select Inputs
	const defaultselectinputs = [
		{ value: "Model", text: "Model" },
		{ value: "products", text: "Product" },
		{ value: "NotModel", text: "NotModel" },
		{ value: "SerialNumber", text: "Serial" },
		{ value: "Vendor", text: "Vendor" },
		{ value: "CreatedBy", text: "Created By" },
		{ value: "LotName", text: "Lot Name" },
		{ value: "LotID", text: "Lot ID" },
		{ value: "Status", text: "Status" },
		{ value: "OrderID", text: "Order #" },
		{ value: "Location", text: "Location" },
		{ value: "CPU", text: "CPU" },
		{ value: "RAM", text: "RAM" },
		{ value: "HDDSize", text: "HDD Size" },
		{ value: "Resolution", text: "Resolution" },
		{ value: "VideoCard", text: "Video Card" }, //To Do: Split VideoCard!
		{ value: "Grade", text: "Grade" },
		{ value: "BodyGrade", text: "Body" },
		{ value: "LCDGrade", text: "LCD" },
		{ value: "Notes", text: "Notes" },
		{ value: "Cost <", text: "Cost <" },
		{ value: "Cost >", text: "Cost >" },
		{ value: "ItemCreationAfter", text: "Created After" },
		{ value: "ItemCreationBefore", text: "Created Before" },
		{ value: "LastAuditAfter", text: "Audit After" },
		{ value: "LastAuditBefore", text: "Audit Before" },
		{ value: "RepairedByName", text: "Repaired By" },
		{ value: "RepairNote", text: "Repair Note" },
		{ value: "RepairDateAfter", text: "Repaired After" },
		{ value: "RepairDateBefore", text: "Repaired Before" },
		{ value: "updated_by", text: "Updated By" }
	]


	//Set Input Defaults here so we can used them for useState and ResetSearches:
	const inputdefaults = {
		Model: {
			type: "Model",
			value: (modelparameter ? modelparameter : ""),
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		NotModel: {
			type: "NotModel",
			value: "",
			mode: "not",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		//Products AutoComplete
		products: {
			type: "products",
			keyedby: "ProductID", //We'll need a key so that Autocomplete can highlight our value in the drop down after selection
			searchkey: "Name", //This is the column we are searching from the table
			value: (productparameter ? productparameter : []),
			mode: "autocomplete",
			defaultsearchterm: "",
			limit: 10, //Database return limit, keep below ~100
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			multiple: true,
			grow: 1,
			debouncetime:200
		},
		SerialNumber: {
			type: "SerialNumber",
			value: (serialparameter ? serialparameter : ""),
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		Vendor: {
			type: "Vendor",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		CreatedBy: {
			type: "CreatedBy",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		LotName: {
			type: "LotName",
			value: (lotnameparameter ? lotnameparameter : ""),
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		LotID: {
			type: "LotID",
			value: (lotidparameter ? lotidparameter : ""),
			mode: "strict",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		Status: {
			type: "Status",
			value: "Checked In", //Default Checked in always
			defaultValue: "Checked In",
			mode: "select",
			menuitems: [
				{
					value: "",
					key: uuidv4(),
					text: "All",
				},
				{
					value: "Checked In",
					key: uuidv4(),
					text: "Checked In",
				},
				{
					value: "Sold",
					key: uuidv4(),
					text: "Sold",
				}, {
					value: "Scrap",
					key: uuidv4(),
					text: "Scrap",
				}, {
					value: "Returned to Vendor",
					key: uuidv4(),
					text: "Returned to Vendor",
				}, {
					value: "Undetermined or Missing",
					key: uuidv4(),
					text: "Undetermined or Missing",
				},
			],
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			multiple: false,//Change 'value' to array if true.
			center: false,
			grow: 1,
			usechips: false
		},
		OrderID: {
			type: "OrderID",
			value: (orderparameter ? orderparameter : ""),
			mode: "strict",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		Location: {
			type: "Location",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		CPU: {
			type: "CPU",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		RAM: {
			type: "RAM",
			value: "",
			mode: "left",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		HDDSize: {
			type: "HDDSize",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		Resolution: {
			type: "Resolution",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		VideoCard: {
			type: "VideoCard",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		Grade: {
			type: "Grade",
			value: [],
			defaultValue:[],
			mode: "select",
			menuitems: [
				{
					value: "A",
					key: uuidv4(),
					text: "A",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "Grade A",
					chipclass: classes.gradea,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
				{
					value: "B",
					key: uuidv4(),
					text: "B",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "Grade B",
					chipclass: classes.gradeb,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
				{
					value: "C",
					key: uuidv4(),
					text: "C",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "Grade C",
					chipclass: classes.gradec,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
				{
					value: "Repair",
					key: uuidv4(),
					text: "Repair",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "Repair",
					chipclass: classes.graderepair,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
				{
					value: "Scrap",
					key: uuidv4(),
					text: "Scrap",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "Scrap",
					chipclass: classes.gradescrap,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
			],
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			multiple: true,//Change 'value' to array if true.
			center: false,
			grow: 1,
			usechips: true
		},
		BodyGrade: {
			type: "BodyGrade",
			value: [],
			mode: "select",
			menuitems: [
				{
					value: "A",
					key: uuidv4(),
					text: "A",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "A",
					chipclass: classes.gradea,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
				{
					value: "B",
					key: uuidv4(),
					text: "B",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "B",
					chipclass: classes.gradeb,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
				{
					value: "C",
					key: uuidv4(),
					text: "C",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "C",
					chipclass: classes.gradec,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
				{
					value: "Bad",
					key: uuidv4(),
					text: "Bad",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "Bad",
					chipclass: classes.gradebad,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
			],
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			multiple: true,//Change 'value' to array if true.
			center: false,
			grow: 1,
			usechips: true
		},
		LCDGrade: {
			type: "LCDGrade",
			value: [],
			mode: "select",
			menuitems: [
				{
					value: "A",
					key: uuidv4(),
					text: "A",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "A",
					chipclass: classes.gradea,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
				{
					value: "B",
					key: uuidv4(),
					text: "B",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "B",
					chipclass: classes.gradeb,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
				{
					value: "C",
					key: uuidv4(),
					text: "C",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "C",
					chipclass: classes.gradec,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
				{
					value: "Bad",
					key: uuidv4(),
					text: "Bad",
					//Optional usechip - Overrides text
					usechip: true,
					chipsize: "small",
					chiplabel: "Bad",
					chipclass: classes.gradebad,
					//Optional Chip Height and Centering:
					height: "22px",
					center: true,
				},
			],
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			multiple: true,//Change 'value' to array if true.
			center: false,
			grow: 1,
			usechips: true
		},
		Notes: {
			type: "Notes",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		"Cost <": {
			type: "Cost <",
			value: "",
			mode: "lessthan",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		"Cost >": {
			type: "Cost >",
			value: "",
			mode: "greaterthan",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		ItemCreationAfter: {
			type: "ItemCreationAfter",
			value: (() => {
				// let date = new Date();
				// date.setFullYear(date.getFullYear() - 10); //Set to 50 years ago
				// return date;
				return dayjs().subtract(10, 'year');
			})(),
			mode: "datetimeafter",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		ItemCreationBefore: {
			type: "ItemCreationBefore",
			value: dayjs(), // new Date,
			mode: "datetimebefore",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		LastAuditAfter: {
			type: "LastAuditAfter",
			value: (() => {
				// let date = new Date();
				// date.setFullYear(date.getFullYear() - 10); //Set to 50 years ago
				// return date;
				return new dayjs().subtract(10, 'year');
			})(),
			mode: "datetimeafter",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		LastAuditBefore: {
			type: "LastAuditBefore",
			value: new dayjs(), // new Date,
			mode: "datetimebefore",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		RepairDateAfter: {
			type: "RepairDateAfter",
			value: (() => {
				// let date = new Date();
				// date.setFullYear(date.getFullYear() - 10); //Set to 50 years ago
				// return date;
				return dayjs().subtract(10, 'year');
			})(),
			mode: "datetimeafter",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		RepairDateBefore: {
			type: "RepairDateBefore",
			value: dayjs(), //new Date,
			mode: "datetimebefore",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		RepairedByName: {
			type: "RepairedByName",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
		updated_by: {
			type: "updated_by",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1
		},
	}


	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		//Item Search Grid
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		griditems: [],		//Defaults
		totalitems: 0,
		page: 0, //Assume page 0, or else pagination throws an error.
		order: 'desc',
		orderby: 'ItemCreationDate',
		selectedcount: 0,
		rowsperpage: 100,
		maxrowseditmode: 100,
		rowsperpageoptions: [100, 250, 500],
		selectedindexes: [],
		pendingsaves: false,
		expandsearch:false,
		searchoptions: {
			// SEARCH PAIRS: Works with common get() functions on db endpoints. Works with SearchTools to create queries.
			// Also integrates with React <SearchInput /> functional component which can also take parameters for AutoComplete.
			//Modes: like, left, right, strict, not, bool, datetimeafter, datetimebefore
			//These values are initiated similar to ResetSearches!
			//Remember to setup a <SearchInput /> container for each of these.
			searchpairs: {
				searchpair1: inputdefaults.Model,	//Can be fed Model
				searchpair2: (lotidparameter ? inputdefaults.LotID : inputdefaults.products), //Can be fed ProductID
				searchpair3: inputdefaults.SerialNumber, //Can be fed SerialNumber
				searchpair4: (lotnameparameter ? inputdefaults.LotName : (
					orderparameter ? inputdefaults.OrderID : inputdefaults.Status)), //Can be fed LotName parameter or default to OrderID with potential parameter.
				searchpair5: inputdefaults.CPU,
				searchpair6: inputdefaults.Grade,
				searchpair7: inputdefaults.BodyGrade,
				//searchpair8: inputdefaults.LCDGrade
				searchpair8: inputdefaults.LCDGrade
			},
			showconditions: true,
			allowoldrecords: false,
			itemlist: ""
		},
		showlocations: false,
		locations: "Default"
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Each SearchInput will need a key that is refreshed to force a re-render.
	//Required to drive the control of the SearchInput from the parent.
	const [key1, setKey1] = useState(uuidv4());
	const [key2, setKey2] = useState(uuidv4());
	const [key3, setKey3] = useState(uuidv4());
	const [key4, setKey4] = useState(uuidv4());
	const [key5, setKey5] = useState(uuidv4());
	const [key6, setKey6] = useState(uuidv4());
	const [key7, setKey7] = useState(uuidv4());
	const [key8, setKey8] = useState(uuidv4());


	/* ##########################  Top Button Refs and Functions  ########################## */
	const btnSave = useRef();
	const btnPendingSave = useRef();
	const btnEditMode = useRef();
	const btnExpandAll = useRef();
	const btnSerialList = useRef();
	const btnResetSearches = useRef();
	const btnExport = useRef();
	const btnAddItem = useRef();


	//Disable Buttons
	const DisableButtons = () => {
		btnSave.current.setAttribute("disabled", true);
		btnPendingSave.current.setAttribute("disabled", true);
		btnEditMode.current.setAttribute("disabled", true);
		btnSerialList.current.setAttribute("disabled", true);
		btnResetSearches.current.setAttribute("disabled", true);
		btnExport.current.setAttribute("disabled", true);
		btnAddItem.current.setAttribute("disabled", true);
	}

	//Enable Buttons
	const EnableButtons = () => {
		btnSave.current.removeAttribute("disabled");
		btnPendingSave.current.removeAttribute("disabled");
		btnEditMode.current.removeAttribute("disabled");
		btnSerialList.current.removeAttribute("disabled");
		btnResetSearches.current.removeAttribute("disabled");
		btnExport.current.removeAttribute("disabled");
		btnAddItem.current.removeAttribute("disabled");
	}




	//Some requests might take longer
	const longpostoptions = {
		withCredentials: true,
		withXSRFToken: true,
		crossDomain: true,
		mode: "no-cors",
		timeout: 300000,
	};


	//Reusable test to be used with useState bool variables
	//Invalid for none selected or pending changes
	//Bulk Edit Selected
	//Export Selected
	//Print Selected

	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length === 0) {
			//Lock in potential localstate.pendingsaves
			// if (localstate.pendingsaves){
			// 	UpdateState(localstate);
			// }
			errors.NewError({ errmsg: "No items selected.", errshow: true, errtimeout: 5, errtype: 'neutral' });
		} else {
			//If all tests pass, use callback
			fnCallback(value);
		}
	}


	//Provision to use AppState Variables for searchpairs
	/*
	if (localappstate.itemsearch.usevars){
		localstate.searchoptions.searchpairs = localappstate.itemsearch.searchpairs;
		//Unset usevars so on next visit, it isn't something from last time.
		localappstate.itemsearch.usevars = false;
		localstate.dbreload = true;
		UpdateState(localstate);
	}*/

	/* ##########################  Column States  ########################## */
	//User Settings:
	//Cost
	var costdefault = false;
	if (userRole === "admin" || userRole === "manager") {
		costdefault = true;
	}
	const [colstate, setColState] = useState({
		created_at: false, //This could be the record update creation date which is misleading.
		ItemCreationDate: true,
		LastAudit: false,
		SerialNumber: true,
		Vendor: false,
		LotID: false,
		Model: true,
		//Repairs Here
		RepairDate: false,
		RepairNote: false,
		RepairedByName: false,
		Grade: true,
		BodyGrade: true,
		LCDGrade: true,
		Conditions: true,
		CPU: true,
		RAM: true,
		HDD1Size: true,
		HDD2Size: false,
		Optical: false,
		Resolution: true,
		Webcam: true,
		Bluetooth: true,
		Touchscreen: true,
		Batt1: true,
		Batt2: false,
		Video1Desc: false,
		Video2Desc: false,
		Notes: false,
		Cost: costdefault,
		Status: true,
		IntuneStatus:false,
		IntuneStatusDateTime:false,
		IntuneReported:false,
		IntuneReportedDateTime:false,
		Row: false,
		Location: true,
		updated_at: false,
		//Old Record Columns
		RecordType: false,
		updated_by: false

	});

	const UpdateColState = (colstate) => {
		setColState(colstate);
	}


	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);
	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = !newcolstate[key];
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={() => { ToggleColumn(columnvalue) }} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={() => { ToggleColumn(columnvalue) }} />} label={props.label} className={classes.columnselecthover} />
				}
			</React.Fragment>
		)
	}

		/* View Options Menu */
		const [showViewOptionsMenu, setViewOptionsMenu] = useState(null); 
		const ShowViewOptionsMenu = (event) => {
			setViewOptionsMenu(event.currentTarget); 
		}
		const CloseViewOptionsMenu = () => {
			setViewOptionsMenu(null);
		}
	
		/* Edit Menu */
		const [showViewEditMenu, setViewEditMenu] = useState(null);
		const ShowViewEditMenu = (event) => {
			setViewEditMenu(event.currentTarget); 
		}
		const CloseViewEditMenu = () => {
			setViewEditMenu(null);
		}
		
	
		/* Export Menu */
		const [showexportmenu, setExportMenu] = useState(null);
		const ShowExportMenu = (event) => {
			setExportMenu(event.currentTarget);
		}
		const CloseExportMenu = () => {
			setExportMenu(null);
		}



	/* ##########################  Selected Rows  ########################## */
	//Explanation: Since we're using <Checkbox> from materialui in an uncontrolled manner (avoiding state, avoiding rerenders...)
	//we are assuming the only way to show a "checked" value of the checkbox is by a user clicking the actual checkbox.

	//Simply using rowRefs.current[index+"Checkbox"].checked can be set to "true", but the view WILL NOT REFLECT THE CHANGE.
	//So for manual clicks on <Checkbox> we register in localstate
	//For SelectAll, we have to run through all items and set isSelected, then rerender.
	//Non-direct interaction with checkbox components will result in difficulty trying to access it through some kind
	//of ref/DOM manipulation. Therefore we pass changes to these components through state changes, mutating localstate.

	const SelectRow = (index) => {
		if (localstate.selectedindexes.indexOf(index) === -1) {
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length) {
				UpdateState(localstate);
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex, 1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length + 1)) {
				UpdateState(localstate);
			}
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i = 0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	};




	/* ##########################  Search Options  ########################## */


	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	//Used for <SearchInput />
	const onChangeSearchType = (searchpair, searchnumber) => {
		//Only rerender table if searchvalue wasn't blank!
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'object'){
			if (localstate.searchoptions.searchpairs["searchpair" + searchnumber].value.length>0){
				ResetPendingSaves();
				localstate.page = 0;
				localstate.dbreload = true;
			}
		}
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'string'){
			console.log(localstate.searchoptions.searchpairs["searchpair" + searchnumber].value);
			if (localstate.searchoptions.searchpairs["searchpair" + searchnumber].value!==""){
				ResetPendingSaves();
				localstate.page = 0;
				localstate.dbreload = true;
			}
		}
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		UpdateState(localstate);
		setKey1(uuidv4());
		setKey2(uuidv4());
		setKey3(uuidv4());
		setKey4(uuidv4());
		setKey5(uuidv4());
		setKey6(uuidv4());
		setKey7(uuidv4());
		setKey8(uuidv4());
	};

	//Set Search Value:
	const onChangeSearchValue = debounce(function (searchvalue, searchpair, searchnumber) {
		//Clears out changes because table is about to be reloaded:
		ResetPendingSaves();
		//Possible search validation here such as proper date format or int/float...
		//For AutoComplete, you will likely need to pull some kind of ID or name before setting the searchvalue!		
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = searchvalue;
		localstate.page = 0;
		localstate.dbreload = true;
		UpdateState(localstate);
	}, 1500);

	//AutoComplete Still needs to send an update so that our inputValue can remain between multiple selections:
	const onChangeAutoCompleteInput = (searchpair, searchnumber) => {
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		UpdateState(localstate);
	};

	// const onChangeSearchValue = debounce(function (searchvalue, searchnumber) {
	// 	//Clears out changes because table is about to be reloaded:
	// 	ResetPendingSaves();
	// 	localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = searchvalue;
	// 	//Provision to warn user about a bad datetime format:
	// 	//On blank datetime, proceed to load data

	// 	//Don't think we need this anymore:
	// 	// if ((localstate.searchoptions.searchpairs["searchpair" + searchnumber].type === "LastAuditAfter" || localstate.searchoptions.searchpairs["searchpair" + searchnumber].type === "LastAuditBefore") && searchvalue !== "") {
	// 	// 	var count = (searchvalue.match(/-/g) || []).length;
	// 	// 	if (searchvalue.length === 10 && count === 2) {
	// 	// 		let isValidDate = Date.parse(searchvalue);
	// 	// 		if (isNaN(isValidDate)) {
	// 	// 			errors.NewError({ errmsg: "Invalid date.", errshow: true, errtimeout: 5, errtype: "warning" });
	// 	// 		} else {
	// 	// 			localstate.page = 0;
	// 	// 			localstate.dbreload = true;
	// 	// 			UpdateState(localstate);
	// 	// 		}
	// 	// 	} else {
	// 	// 		errors.NewError({ errmsg: "Use YYYY-MM-DD format for dates.", errshow: true, errtimeout: 5, errtype: "ok" });
	// 	// 	}
	// 	// } else {
	// 	// 	localstate.page = 0;
	// 	// 	localstate.dbreload = true;
	// 	// 	UpdateState(localstate);
	// 	// }
	// 	localstate.page = 0;
	// 	localstate.dbreload = true;
	// 	UpdateState(localstate);
	// }, 800);

	//Key-Value Inputs
	const [searchinputs, setSearchInputs] = useState({
		show1: true,
		show2: true,
		show3: true,
		show4: true,
		show5: true,
		show6: true,
		show7: true,
		show8: true,
		lastsearch: 8
	});


	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		//This evaluates if we're requesting the same property and if it's asc.
		const isAsc = localstate.orderby === property && localstate.order === "asc";	
		//We need to evaluate if we should start back at page 1:
		if (localstate.orderby !== property){
			localstate.page = 0;
		}
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page = 0;
		UpdateState(localstate);
	};

	let history = useHistory();

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems() {
		localstate.selectedindexes = [];
		localstate.selectedcount = 0;

		//Need to DEEP clone:
		var newsearchpairs = JSON.parse(JSON.stringify(localstate.searchoptions.searchpairs));

		//Optional Search Handlers:

		//AUTOCOMPLETE AND SELECT Handler
		//Here, we may want to convert our SearchInput searchpairs of mode "autocomplete" to things
		//that our PHP controller SearchTools can use such as modes "like" or "strict".
		//Keep in mind, if two searchpairs have the same type and the mode is "like", it will normally morph
		//into an OR statement. There are only a few reserved non-OR types: Sku, Model, Name, NotSku, NotModel, SkuStarting
		//This list may be expanded or we may possibly write something to override non-OR types when needed.
		for (var i = 1; i < (activesearchescount + 1); i++) {
			//Products Autocomplete
			if (newsearchpairs["searchpair" + i].mode === "autocomplete" && newsearchpairs["searchpair" + i].type === "products" && typeof newsearchpairs["searchpair" + i].value === 'object') {
				newsearchpairs["searchpair" + i].type = "ProductID";
			}
			//Status Select
			if (newsearchpairs["searchpair" + i].mode === "select" && newsearchpairs["searchpair" + i].type === "Status") {
				newsearchpairs["searchpair" + i].mode = "like";
			}

			//.... More Rules
			//Some selects may be converted to "like"
		}


		const postdata = {
			searchoptions: {
				limit: localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs: newsearchpairs,
				showconditions: localstate.searchoptions.showconditions,
				getlocationdata: false,
				allowoldrecords: localstate.searchoptions.allowoldrecords
			}
		};
		var postoptions = {
			withCredentials: true,
			withXSRFToken: true,
			crossDomain: true,
			mode: 'no-cors',
			timeout: 11800,
		};

		if (localstate.searchoptions.itemlist.length > 0) {
			postdata.searchoptions.itemlist = itemlistsearch;
		}

		//Determine if we need location data:
		for (var i = 1; i < 7; i++) {
			if (localstate.searchoptions.searchpairs["searchpair" + i].type === "Model" && localstate.searchoptions.searchpairs["searchpair" + i].value !== "") {
				postdata.searchoptions.getlocationdata = true;
			}
		}

		axios.post(dbendpoint + "/items/getitems?page=" + (localstate.page + 1), postdata, postoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === 'login') {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === 'Success') {
					//We should now have a non-0 result from the API
					//Add variables for use with table
					for (var i = 0; i < res.data.pagedata.data.length; i++) {
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						res.data.pagedata.data[i].GridKey = i;
						res.data.pagedata.data[i].isSelected = false;
						res.data.pagedata.data[i].unsaved = false;
						res.data.pagedata.data[i].ExpandRow = false;
						//Format Cost as Float
						res.data.pagedata.data[i].Cost = parseFloat(res.data.pagedata.data[i].Cost).toFixed(2);

						//Set Defaults for selectables:
						res.data.pagedata.data[i].GradeDefault = res.data.pagedata.data[i].Grade;
						res.data.pagedata.data[i].BodyGradeDefault = res.data.pagedata.data[i].BodyGrade;
						res.data.pagedata.data[i].LCDGradeDefault = res.data.pagedata.data[i].LCDGrade;
					}
					localstate.griditems = res.data.pagedata.data;
					localstate.totalitems = res.data.pagedata.total;
					if (localstate.searchoptions.showconditions) {
						colstate.Conditions = true;
					} else {
						colstate.Conditions = false;
					}

					//console.log("DetermineLocations");
					//Default turn locations off:
					localstate.showlocations = false;
					if (res.data.hasOwnProperty('LocationData')) {
						DetermineLocations(res.data.LocationData);
					}

					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status === 'Failure') {
					//Failure error
					localstate.griditems = [];
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' });
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' });
			}
		});
	}

	//Locations! Function to find out if we need to provide the user with a location summary
	//If searching by SKU, we are able to derive a Model, CPU, Resolution, and others....
	//Method....
	const DetermineLocations = (locationdata) => {
		var locationsenabled = false;
		//To Do: Build logic if there is some flaw here
		console.log(locationdata);
		localstate.locations = "";
		localstate.locations = locationdata[0].Location + " (" + locationdata[0].LocationCount + ")";
		for (var k = 1; k < locationdata.length && k < 4; k++) {
			if (locationdata[k]['Location']) {
				localstate.locations += ", " + locationdata[k]['Location'] + " (" + locationdata[k]['LocationCount'] + ")";
			}
		}

		locationsenabled = true;
		if (locationsenabled) {
			localstate.showlocations = true;
		} else {
			localstate.showlocations = false;
		}
	}




	/* ############### RESIZER ####################*/
	const createResizableColumn = function (col, resizer) {
		// Track the current position of mouse
		let x = 0;
		let w = 0;

		const mouseDownHandler = function (e) {
			// Get the current mouse position
			x = e.clientX;
			// Calculate the current width of column
			const styles = window.getComputedStyle(col);
			w = parseInt(styles.width, 10);
			// Attach listeners for document's events
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};

		const mouseMoveHandler = function (e) {
			// Determine how far the mouse has been moved
			const dx = e.clientX - x;
			// Update the width of column
			col.style.width = `${w + dx}px`;
		};

		// When user releases the mouse, remove the existing event listeners
		const mouseUpHandler = function () {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};
		resizer.addEventListener('mousedown', mouseDownHandler);
	};

	const InitColumnResizers = () => {
		//Runs after rerender
		// Query the table
		const table = document.getElementById('resizeMe');
		// Query thead:
		const thead = document.querySelector('thead');
		const cols = thead.querySelectorAll('td');
		// Loop over them
		[].forEach.call(cols, function (col) {
			// Create a resizer element
			const resizer = document.createElement('div');
			resizer.classList.add([classes.resizer]);
			// Set the height
			resizer.style.height = `${table.offsetHeight - 2}px`;
			// Add a resizer element to the column
			col.appendChild(resizer);
			createResizableColumn(col, resizer);
		});
	}

	/* ############### END OF RESIZER ####################*/




	/* ##########################  App Init  ########################## */
	//Run once, don't clean up until controller dismount (unchanging init variable)
	const [appinit] = useState(true);
	useEffect(() => {
		document.title = "Inventory Manager";
		//Cleanup
		return function cleanup() {
			dispatch(setProgressTimeout(0));
		}
	}, [appinit]);


	useEffect(() => {
		if (state.dbreload) {
			//Avoid duplicate loades.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
			//Boostrap a resizer for the columns:
			InitColumnResizers();
		}
	});



	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	//UNUSED AddRow
	// const AddRow = () => {
	// 	localstate.griditems.unshift({
	// 		ItemID:uuidv4(),
	// 		SerialNumber:'New Item',
	// 		Vendor:'',
	// 		PendingItem:true,
	// 	});
	// 	//All selected indexes move up by 1.
	// 	for (var i=0; i<localstate.selectedindexes.length; i++){
	// 		localstate.selectedindexes[i] += 1;
	// 	}
	// 	UpdateState(localstate);
	// }

	const requestcount = useRef(0);

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);

		//Configuration
		requestcount.current = 0;
		var timeout = 300; //Seconds before closing final ProgressBar results - To Do: Add option to NOT timeout at all
		var requestinterval = 100; //Milliseconds

		var updatearray = [];
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				localstate.griditems[i]['loadhistory'] = false;
				updatearray.push(localstate.griditems[i]);
			}
		}
		if (updatearray.length > 0) {
			DisableButtons();
			dispatch(newProgress({
				msg: 'Saving items...',
				show: true,
				settimeout: true, //This option allows for a timeout after the final item is pass/failed
				timeout: timeout, //Initiated on final item 
				type: 'items', //Labels your save types such as items, products, skus, parts
				finished: 0,
				percent: "0%",
				total: updatearray.length,
				pass: 0,
				fail: 0,
				faillist: [], //Can take simple values,
				faillinks: [], //Can take links to things like items or products.
				timeoutid: false,
				errors: [] //Can take strings
			}));
			i = 0;
			var limit = updatearray.length - 1;
			/* INTERVAL */
			var updateitems = setInterval(function () {
				var item = updatearray[i];
				const postdata = {
					item: item
				};
				axios.post(dbendpoint + "/items/updateitem", postdata, longpostoptions).then(res => {
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//New rows get a made up "OldID"
							//We also get back an OldID if we're updating record keeping and inline rows without rerenders!
							var itemindex = 0;
							if (res.data.OldID) {
								itemindex = localstate.griditems.map(function (o) { return o.ItemID; }).indexOf(res.data.OldID);
								localstate.griditems[itemindex].unsaved = false;
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
								//Assign an UpdateID to batch up updates.
								localstate.griditems[itemindex].UpdateID = res.data.item.ItemID;
								//Set New ID
								localstate.griditems[itemindex].ItemID = res.data.item.ItemID;
							} else {
								itemindex = localstate.griditems.map(function (o) { return o.ItemID; }).indexOf(res.data.item.ItemID);
								localstate.griditems[itemindex].unsaved = false;
								//Refs allow us to update the grid live!
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
							}
							dispatch(incrementPass());
						}
						if (res.data.Status === "Failure") {
							//Failure error - To Do: Dispatch failure messages to the ProgressBar
							dispatch(incrementFail());
						}
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);

					} else {
						//Non-200 message from server.
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
						dispatch(incrementFail());
					}
				}).catch(err => {
					//Non-200, 500 Error, timeout?
					requestcount.current++;
					FinalizeRequest(requestcount.current, limit, timeout);
					dispatch(incrementFail());
				});
				//If we have completed all items, clear this interval. No longer update state.
				if (i === limit) {
					clearInterval(updateitems);
				}
				i++;
			}, requestinterval);
		} else {
			errors.NewError({ errmsg: "Nothing to save.", errshow: true, errtimeout: 5, errtype: "neutral" })
			ResetPendingSaves();
		}
	}



	//Clean up View
	const FinalizeRequest = (i, limit, timeout) => {
		//The itterator after responses are incremented to n+1. Reversed below:
		if ((i - 1) === limit) {
			EnableButtons();
			dispatch(setProgressTimeout(timeout));
			MarkErrorItems();
			btnSave.current.style.display = "";
			btnPendingSave.current.style.display = "none";
		}
	}


	//Mark Remaining Items as Warning Items
	const MarkErrorItems = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				rowRefs.current[i + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
				rowRefs.current[i + 'SaveStatus'].classList.add(classes.errorhighlight);
			} else {
				//Resolve previously unresolved
				rowRefs.current[i + 'SaveStatus'].classList.remove(classes.errorhighlight);
			}
		}
	}

	const ResetPendingSaves = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				localstate.griditems[i].unsaved = false;
			}
		}
		EnableButtons();
		btnSave.current.style.display = "";
		btnPendingSave.current.style.display = "none";
	}




	{/* Bulk Edits Drawer state and functions */ }
	const [showbulkeditdrawer, setShowBulkEditDrawer] = useState(false);
	const btnApplyBulkEdit = useRef();

	const CloseBulkEditDrawer = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		setShowBulkEditDrawer(false);
		setShowListingsBtn(false);
		//Reload DB?
		localstate.dbreload = true;
		//Update State?
		UpdateState(localstate);
	}

	const [changetype, setChangeType] = React.useState('');
	const [changeistext, setChangeIsText] = React.useState(false);
	const [changeisint, setChangeIsInt] = React.useState(false);
	const [changeisfloat, setChangeIsFloat] = React.useState(false);
	const [changeisproduct, setChangeIsProduct] = React.useState(false);
	const [changeisstatus, setChangeIsStatus] = React.useState(false);
	const [changeisdate, setChangeIsDate] = React.useState(false);
	const [productselection, setProductSelection] = React.useState();
	const [changeisflagmdm, setChangeIsFlagMDM] = React.useState(false);
	const [changeisflagmdmreported, setChangeIsFlagMDMReported] = React.useState(false);


	//Seems to reset on error... try useRef
	const changevalue = React.useRef();
	//These 2 refs are used to update a number input (RestrictNumber() will limit what can be put into input box)
	const changevaluefloat = React.createRef();
	const changevalueint = React.createRef();


	const handleChangeType = (event) => {
		//Reset change value
		changevalue.current = "";
		//Reset Inputs:
		setChangeIsText(false);
		setChangeIsInt(false);
		setChangeIsFloat(false);
		setChangeIsProduct(false);
		setChangeIsStatus(false);
		setChangeIsDate(false);
		setChangeIsFlagMDM(false);
		setChangeIsFlagMDMReported(false);

		//Reset Btn
		btnApplyBulkEdit.current.classList.remove(['MuiButton-containedPrimary']);
		btnApplyBulkEdit.current.classList.add(['Mui-disabled']);

		setChangeType(event.target.value);
		/*
		<MenuItem value={'Location'}>Location</MenuItem>
							<MenuItem value={'Status'}>Status</MenuItem>
							<MenuItem value={'Mfg'}>Mfg</MenuItem>
							<MenuItem value={'Product'}>Product</MenuItem>
							<MenuItem value={'Grade'}>Grade</MenuItem>
							<MenuItem value={'Cost'}>Cost</MenuItem>
							<MenuItem value={'Salesperson'}>Salesperson</MenuItem>
							<MenuItem value={'SKU'}>SKU</MenuItem>
							<MenuItem value={'Order'}>Order Number</MenuItem>
							<MenuItem value={'Vendor'}>Vendor</MenuItem>
							<MenuItem value={'AuditDate'}>Audit Date</MenuItem>
							*/

		if (event.target.value === 'Location' ||
			event.target.value === 'Mfg' ||
			event.target.value === 'Grade' ||
			event.target.value === 'BodyGrade' ||
			event.target.value === 'LCDGrade' ||
			event.target.value === 'Agent' ||
			event.target.value === 'Vendor' ||
			event.target.value === 'OrderID' ||
			event.target.value === "CPU"
		) {
			setChangeIsText(true);
		}

		if (event.target.value === "Status") {
			setChangeIsStatus(true);
		}

		if (event.target.value === "Cost") {
			setChangeIsFloat(true);
		}

		if (event.target.value === "LotID" ||
			event.target.value === 'SkidNumber') {
			setChangeIsInt(true);
		}

		if (event.target.value === 'Product') {
			setChangeIsProduct(true);
		}
		if (event.target.value === 'LastAudit') {
			setChangeIsDate(true);
		}

		// {/* MDM Items */}
		// <MenuItem value={'FlagMDMStatus-Intune'}>Intune Status</MenuItem>
		// 						<MenuItem value={'FlagMDMReported-Intune'}>Intune Reported to Vendor</MenuItem>

		// 						<MenuItem value={'FlagMDMStatus-PanGP'}>PanGP Status</MenuItem>
		// 						<MenuItem value={'FlagMDMReported-PanGP'}>PanGP Reported to Vendor</MenuItem>

		// 						<MenuItem value={'FlagMDMStatus-AbsPersistence'}>Absolute Persistence Status</MenuItem>
		// 						<MenuItem value={'FlagMDMReported-AbsPersistence'}>Absolute Persistence Reported to Vendor</MenuItem>


		if (event.target.value === "Create Draft Listing") {
			//Do nothing!
			//Orrrr... Allow button.
			btnApplyBulkEdit.current.classList.remove(['Mui-disabled']);
			btnApplyBulkEdit.current.classList.add(['MuiButton-containedPrimary']);

		}

		//MDM Statuses
		if (event.target.value === "IntuneStatus" ||
			event.target.value === "PanGPStatus" ||
			event.target.value === "AbsPersistenceStatus") {
			setChangeIsFlagMDM(true);
		}


		//MDM Reported
		if (event.target.value === "IntuneReported" ||
			event.target.value === "PanGPReported" ||
			event.target.value === "AbsPersistenceReported") {
				setChangeIsFlagMDMReported(true);
		}

	};

	const handleChangeValue = (event) => {
		//Currency
		if (changetype === 'Cost') {
			changevalue.current = RestrictNumber(event.target.value, changevalue.current);
			changevaluefloat.current.value = changevalue.current;
			//Integers	
		} else if (changetype === 'LotID' || changetype === 'SkidNumber') {
			changevalue.current = RestrictNumberInteger(event.target.value, changevalue.current);
			changevalueint.current.value = changevalue.current;
			//Raw Values
		} else if (changetype === "LastAudit") {
			//Right now we can pass this, but check it on submit.
			changevalue.current = event.target.value;
		} else {
			changevalue.current = event.target.value;
		}


		//Verify changes can be saved in current form.
		//Avoid certain items being saved as a blank string:
		if (changetype === 'Location' && event.target.value === "") {
			btnApplyBulkEdit.current.classList.remove(['MuiButton-containedPrimary']);
			btnApplyBulkEdit.current.classList.add(['Mui-disabled']);
			errors.NewError({ errmsg: "Value cannot be blank.", errshow: true, errtimeout: 3, errtype: 'neutral' });

			//Allow bulk change to be saved
		} else {
			btnApplyBulkEdit.current.classList.remove(['Mui-disabled']);
			btnApplyBulkEdit.current.classList.add(['MuiButton-containedPrimary']);
		}
	}

	const handleChangeStatus = (event) => {
		changevalue.current = event.target.value;
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeProduct = (event, product) => {
		setProductSelection(product);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(['Mui-disabled']);
		btnApplyBulkEdit.current.classList.add(['MuiButton-containedPrimary']);
	}

	//Search for Products:
	//Helpers - Blank icons for pre-set keys
	//const BlankIcon = () => (<ArrowDropDownIcon style={{display:"none"}} />);

	const [autocompletesearchtype1, setSearchKey1] = useState("Name");
	const [openoptions1, openOptions1] = React.useState(false);
	const [options1, setOptions1] = React.useState([]);
	const [loadingoptions1, setLoadingOptions1] = useState(false);
	const InitOptions1 = () => {
		if (options1.length === 0) {
			//Load (up to limit) options
			AutoCompleteSearch1("");
		}
		openOptions1(true);
	}

	const onChangeOption1 = (event, newvalue) => {
		// Derive new product value
		console.log(newvalue);
	}

	const AutoCompleteSearch1 = debounce(function (searchvalue) {
		setLoadingOptions1(true);
		const postdata = {
			searchoptions: {
				search: searchvalue,
				searchtype: "Name",
				currentsort: "Name",
				currentsortdir: "ASC"
			}
		};
		axios.post(dbendpoint + "/products", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				} else {
					setOptions1(res.data.data);
					console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
			setLoadingOptions1(false);
		});
	}, 600);


	const ApplyBulkEdit = () => {
		//First verify if LastAudit is valid:
		if (changeisdate) {
			var count = (changevalue.current.match(/-/g) || []).length;
			if (changevalue.current.length === 10 && count === 2) {
				let isValidDate = Date.parse(changevalue.current);
				if (isNaN(isValidDate)) {
					failureaudio.play();
					errors.NewError({ errmsg: "Not a valid date for audit. Use YYYY-MM-DD.", errshow: true, errtimeout: 5, errtype: "warning" });
					return;
				} else {
					//Function Continues to post
				}
			} else {
				failureaudio.play();
				errors.NewError({ errmsg: "Not a valid date for audit. Use YYYY-MM-DD.", errshow: true, errtimeout: 5, errtype: "warning" });
				return;
			}
		}
		//Adjust Date
		//We don't need this for audit dates anymore?
		// if (changeisdate) {
		// 	changevalue.current = changevalue.current + " 00:00:00";
		// }

		var items = [];
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.griditems[localstate.selectedindexes[i]]['loadhistory'] = false;
			if (changeisdate) {
				localstate.griditems[localstate.selectedindexes[i]['BulkEditDate']] = true;
			}
			items.push(localstate.griditems[localstate.selectedindexes[i]]);
		}

		//Simple key-value pairs:
		if (changetype !== "Product") {
			for (i = 0; i < items.length; i++) {
				console.log("ChangeType: " + changetype + "      ChangeValue:" + changevalue.current);
				items[i][changetype] = changevalue.current;
				items[i]['loadhistory'] = false;
				//Send Bulk Edit Token for LastAudit - Avoids the automatic LastAudit assignment.
				if (changetype === "LastAudit") {
					items[i]['LastAuditBulkEdit'] = true;
				}
			}
		}

		//Database Item Selection (potential multiple values to change)
		if (changetype === "Product") {
			//Place your specific column values here:
			for (i = 0; i < items.length; i++) {
				items[i]["Model"] = productselection.Name;
				items[i]["ProductID"] = productselection.ProductID;
				items[i]["Mfg"] = productselection.Mfg;
			}
		}

		var postdata = {
			items: items
		}

		//Provision for special change types - Then handled very differently in Items Controller
		if (changetype === "OrderID" || changetype === "Create Draft Listing") {
			postdata.edittype = changetype;
		}


		//Disable Apply Btn - Avoid double post
		btnApplyBulkEdit.current.classList.remove(['MuiButton-containedPrimary']);
		btnApplyBulkEdit.current.classList.add(['Mui-disabled']);



		axios.post(dbendpoint + "/items/bulkedititems", postdata, longpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === 'login') {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status === 'Success') {
					//console.log(res.data);
					//If no Fails, give green response
					if (res.data['SuccessCount'] > 0 || res.data['FailCount'] === 0) {
						errors.NewError({
							errmsg: "Items Changed: " + res.data['SuccessCount'] + ", Failures: " + res.data['FailCount'],
							errshow: true,
							errtimeout: 300,
							errtype: 'ok'
						})
					}

					//Give warning if failed
					if (res.data['FailCount'] > 0) {
						errors.NewError({
							errmsg: "Items Changed: " + res.data['SuccessCount'] + ", Failures: " + res.data['FailCount'],
							errshow: true,
							errtimeout: 300,
							errtype: 'warning'
						})

					}

					//Give option to go to items in Listings view.....
					if (res.data['ReturnIDs'].length > 0) {
						setShowListingsBtn(true);
						setListingIDs(res.data['ReturnIDs']);
					}
				}

				if (res.data.Status === 'Failure') {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
			}
		});
	}


	//Create Listing - Follow Up Button to go to Listings.
	const [showlistingsbtn, setShowListingsBtn] = useState(false);
	const [listingids, setListingIDs] = useState("");

	const GoToListings = (listingids) => {
		history.push("/listings?ids=" + listingids);
	}




	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () => {
		var deleteitemsarray = [];
		//Reflect items to user for confimation.
		for (var i = 0; i < state.selectedindexes.length; i++) {
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	//UNUSED
	const DeleteSelected = () => {
		var finishedrequests = 0;

		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty('PendingItem')) {
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {
					item: localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint + "/basictable/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === 'login') {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === 'Success') {
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID) {
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function (o) { return o.ItemID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
							} else {
								errors.NewError({ errmsg: "Could not delete one or more items.", errshow: true, errtimeout: 8, errtype: 'warning' })
							}
						}
						if (res.data.Status === 'Failure') {
							//Failure error
							errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' })
						}
					} else {
						//Non-200 message from server.
						errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}


	{/* Item List */ }
	const [showitemlist, setShowItemList] = useState(false);
	//const [itemlist, setItemList] = useState('');
	//We keep our original item list intact, use itemlistsearch for new DB reload
	const [itemlistsearch, setItemListSearch] = useState("");
	const [dupeitems, setDupeItems] = useState('');
	const CloseItemList = () => {
		//Get unique items
		const result = localstate.searchoptions.itemlist.split(/\r?\n/).filter(element => element);
		const newitemlist = GetUniqueArray(result);
		setItemListSearch(newitemlist);
		setShowItemList(false);
		localstate.dbreload = true;
		UpdateState(localstate);
	}
	const onChangeItemList = (event) => {
		const result = event.target.value.split(/\r?\n/).filter(element => element);
		const dupes = GetDupeArray(result);
		if (dupes.length > 0) {
			setDupeItems(dupes);
			console.log(dupes);
		} else {
			setDupeItems('');
		}
		//setItemList(event.target.value);
		localstate.searchoptions.itemlist = event.target.value;
	}


	/* ##########################  Cell Interaction  ########################## */

	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	//Non-booleans and non-selects?
	const taborder = ['CPU', 'RAM', 'HDD1Size', 'HDD2Size', 'Optical', 'Resolution', 'Batt1', 'Batt2', 'Video1Desc', 'Video2Desc', 'Notes', 'Cost', 'Row', 'Location'];

	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown") {
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].select();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].select();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp") {
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].select();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length - 1;
				rowRefs.current[(lastelement + column)].select();
			}
		}
	}

	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) => {
		console.log("New:" + newvalue + "    Old Value:" + oldvalue);
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (index > 0) {
			var decimalchars = (len - 1) - index;
			if (decimalchars > 2) {
				return oldvalue;
			}
		}
		return newvalue;
	}

	//Restrict Number to Integer - Don't accept any decimal.
	const RestrictNumberInteger = (newvalue, oldvalue) => {
		console.log(newvalue);
		if (newvalue < 0) {
			return 0;
		} else {
			return parseInt(newvalue);
		}
	}


	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value === "") {
			console.log("Blank number found!");
			rowRefs.current[index + column].value = "0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		//If a value has been accepted into localstate, we assume it's valid and can be referenced here.
		var newvalue = event.target.value;
		if (event.key !== "Tab" && event.key !== "ArrowDown" && event.key !== "ArrowUp" && event.key !== "ArrowLeft" && event.key !== "ArrowUp" && event.key !== "ArrowRight") {
			//Provision for Checkboxes Controlled by MaterialUI Components
			if (column === 'Webcam' || column === 'Bluetooth' || column === 'Touchscreen') {
				if (event.target.checked) {
					localstate.griditems[index][column] = 1;
					//rowRefs.current[index+column].checked = true;
				} else {
					localstate.griditems[index][column] = 0;
					//rowRefs.current[index+column].checked = false;
				}
			} else if (column === "Status" || column === "Grade" || column === "BodyGrade" || column === "LCDGrade") {
				//Status works similar to booleans
				localstate.griditems[index][column] = newvalue;
			} else {
				//Restrict Numbers, Cost
				if (column === "Cost") {
					var oldvalue = localstate.griditems[index][column];
					newvalue = RestrictNumber(newvalue, oldvalue);
				}
				rowRefs.current[index + column].value = newvalue;
				localstate.griditems[index][column] = newvalue;
			}

			rowRefs.current[index + 'SaveStatus'].classList.add(classes.unsavedhighlight);
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			//Update localstate
			localstate.griditems[index].unsaved = true;
			// if (!localstate.pendingsaves){
			// 	localstate.pendingsaves = true;
			// 	UpdateState(localstate);
			// }
			//Update Save button to show pending saves
			//btnSave.current.style.color="white";
			//btnSave.current.style.backgroundColor="#01579B";
		}
	}


	/* ##########################  Button Functions  ########################## */

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ShowConditions = () => {
		localstate.searchoptions.showconditions = !localstate.searchoptions.showconditions;
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const InspectByUser = () => {
		localstate.searchoptions.searchpairs.searchpair2.type = "updated_by";
		localstate.searchoptions.searchpairs.searchpair2.value = "";
		colstate.updated_by = true;
		colstate.updated_at = true;
		localstate.order = 'desc';
		localstate.orderby = "updated_at";
		localstate.dbreload = true;
		UpdateState(localstate);
	}


	const ViewRepairs = () => {
		let newcolstate = colstate;
		newcolstate['RepairDate'] = true;
		newcolstate['RepairedByName'] = true;
		newcolstate['RepairNote'] = true;
		UpdateColState(newcolstate);
	}

	const ResetSearches = () => {
		//Now would be a good time to reset parameters too if needed!
		//Something with this many parameters... we might be best just refreshing the page!
		history.push('/inventorymanager');


		//POSSIBLY DEPRECATE, SERIOUSLY, WHAT ARE WE DOING HERE.

		// //Reset all:
		// localstate.searchoptions.searchpairs.searchpair1 = inputdefaults.Model;
		// localstate.searchoptions.searchpairs.searchpair2 = inputdefaults.products;
		// localstate.searchoptions.searchpairs.searchpair3 = inputdefaults.SerialNumber;
		// localstate.searchoptions.searchpairs.searchpair4 = inputdefaults.Status;
		// localstate.searchoptions.searchpairs.searchpair5 = inputdefaults.CPU;
		// localstate.searchoptions.searchpairs.searchpair6 = inputdefaults.Grade;
		// localstate.searchoptions.searchpairs.searchpair7 = inputdefaults.BodyGrade;
		// localstate.searchoptions.searchpairs.searchpair8 = inputdefaults.LCDGrade;

		// //Set Defaults:
		// setItemListSearch('');
		// localstate.dbreload = true;
		// UpdateState(localstate);

		// //Forcefully rerender search inputs!
		// setKey1(uuidv4());
		// setKey2(uuidv4());
		// setKey3(uuidv4());
		// setKey4(uuidv4());
		// setKey5(uuidv4());
		// setKey6(uuidv4());
		// setKey7(uuidv4());
		// setKey8(uuidv4());
	}

	//Expand Search
	const ToggleExpandSearch = () => {
		localstate.expandsearch = !localstate.expandsearch;

		if (!localstate.expandsearch){
			for (var i=5; i<=activesearchescount; i++){
				//Only rerender if one of the searches wasn't blank!
				if (typeof localstate.searchoptions.searchpairs["searchpair" + i].value === 'object'){
					if (localstate.searchoptions.searchpairs["searchpair" + i].value.length>0){
						ResetPendingSaves();
						localstate.page = 0;
						localstate.dbreload = true;
					}
				}
				if (typeof localstate.searchoptions.searchpairs["searchpair" + i].value === 'string'){
					console.log(localstate.searchoptions.searchpairs["searchpair" + i].value);
					if (localstate.searchoptions.searchpairs["searchpair" + i].value!==""){
						ResetPendingSaves();
						localstate.page = 0;
						localstate.dbreload = true;
					}
				}
			}
			//Proceed to Reset Partial Searches 5-8:
			localstate.searchoptions.searchpairs.searchpair5 = inputdefaults.CPU;
			localstate.searchoptions.searchpairs.searchpair6 = inputdefaults.Grade;
			localstate.searchoptions.searchpairs.searchpair7 = inputdefaults.BodyGrade;
			localstate.searchoptions.searchpairs.searchpair8 = inputdefaults.LCDGrade;
		}
		CloseViewOptionsMenu();
		UpdateState(localstate);
	}


	//Export Confirmation and Exports
	const [showexportconfirmation, setShowExportConfirmation] = useState(false);
	//Chooses between "selected" or "searchresults"
	const [exporttype, setExportType] = useState("");
	//Export Message
	const [exportmessage, setExportMessage] = useState("");
	var exportmode = "";
	var exportlimit = 20000;
	var exportfilename = "Inventory Items CSV";

	const InitExport = (exporttype) => {
		if (exporttype === "selected" && localstate.selectedindexes.length === 0) {
			//Do nothing
		} else {
			//Show export type selection
			setShowExportConfirmation(true);
			//Set export by "selected" or "searchresults"
			setExportType(exporttype);
			if (exporttype === "selected") {
				setExportMessage("");
			}
			if (exporttype === "searchresults") {
				setExportMessage("Exports of search results will be limited to " + exportlimit + " rows.");
			}
		}
	}

	const PrepareExport = (mode) => {
		//Set global
		exportmode = mode;
		var exportdata = [];
		var clonedata = [];
		if (exporttype === "selected") {
			//Need to DEEP clone:
			var clonedata = JSON.parse(JSON.stringify(localstate.griditems))
			for (var i = 0; i < localstate.selectedindexes.length; i++) {
				exportdata.push(clonedata[localstate.selectedindexes[i]]);
			}
			Export(exportdata);
		}
		if (exporttype === "searchresults") {

			//Prepare Searchpairs - You may need to follow this pattern in PrepareExport()
			//Need to DEEP clone:
			var newsearchpairs = JSON.parse(JSON.stringify(localstate.searchoptions.searchpairs));

			//AUTOCOMPLETE AND SELECT Handler
			//The SearchTools controller will treat any search with multiple=true as an ormode with merged array values. 
			for (var i=1; i<(activesearchescount+1); i++){
				//Products Autocomplete
				if (newsearchpairs["searchpair"+i].mode==="autocomplete" && newsearchpairs["searchpair"+i].type==="products"){
					newsearchpairs["searchpair"+i].type="ProductID";
					//Note: You may change the "mode" and possibly rely on SearchTools to handle an array (if detected!)
					//We don't normally change the mode, instead allowing our TableController to handle special auto-completed values, normally relationship based values.
					//To simplify same-layer table values such as "ProductID", we might leverage our already working ormode code to handle an array value!
					//Test a new non-short circuited ProductID search using mode="like" in SkusTable.js
				}
				//.... More Rules
				//Some select mode items may be converted to "like"
			}

			const postdata = {
				searchoptions: {
					//Set Max Limit Here
					limit: exportlimit,
					currentsort: localstate.orderby,
					currentsortdir: localstate.order,
					searchpairs: newsearchpairs,
					showconditions: true
				}
			};
			axios.post(dbendpoint + "/items/getitems?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						exportdata = res.data.pagedata.data;
						Export(exportdata);
					}
					if (res.data.Status === "Failure") {
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
					}
				} else {
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				}
			});
		}
	}







	const Export = (exportdata) => {
		//Remove Metas and Unused/Deprecated Columns
		for (var i = 0; i < exportdata.length; i++) {
			//Metas
			removeProp(exportdata[i], "isSelected");
			removeProp(exportdata[i], "unsaved");
			removeProp(exportdata[i], "ExpandRow");
			removeProp(exportdata[i], "GridKey");
			//DB Items
			removeProp(exportdata[i], "AssetTag");
			removeProp(exportdata[i], "Shelf");
			removeProp(exportdata[i], "Sellable");
			removeProp(exportdata[i], "OrderID");
			removeProp(exportdata[i], "OrderItemID");
			removeProp(exportdata[i], "Agent");
			removeProp(exportdata[i], "Market");
			removeProp(exportdata[i], "SKU");
			removeProp(exportdata[i], "URL");
			removeProp(exportdata[i], "HDStatus");
			removeProp(exportdata[i], "R2");
			removeProp(exportdata[i], "SoftDelete");
			removeProp(exportdata[i], "CreatedBy");
			removeProp(exportdata[i], "DeletedBy");
			removeProp(exportdata[i], "DeleteDate");
			removeProp(exportdata[i], "RTS");
			removeProp(exportdata[i], "HDD");
			removeProp(exportdata[i], "BattCond");
			removeProp(exportdata[i], "Touchscreen");
		}

		//Mutate Data
		//Conditions
		var minorconditions;
		var majorconditions;
		var damagedconditions;
		for (var i = 0; i < exportdata.length; i++) {
			minorconditions = "";
			majorconditions = "";
			damagedconditions = "";
			for (var j = 0; j < exportdata[i].minorconditions.length; j++) {
				minorconditions += exportdata[i].minorconditions[j].ConditionText + ", ";
			}
			//Remove last two uneeded ", "
			exportdata[i].minorconditions = minorconditions.slice(0, -2);
			for (var j = 0; j < exportdata[i].majorconditions.length; j++) {
				majorconditions += exportdata[i].majorconditions[j].ConditionText + ", ";
			}
			exportdata[i].majorconditions = majorconditions.slice(0, -2);
			for (var j = 0; j < exportdata[i].damagedconditions.length; j++) {
				damagedconditions += exportdata[i].damagedconditions[j].ConditionText + ", ";
			}
			exportdata[i].damagedconditions = damagedconditions.slice(0, -2);
		}

		//Use Export Mode to pare data
		if (exportmode === "simple") {
			console.log("Simple Export");
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "ItemCreationDate");
				removeProp(exportdata[i], "updated_at");
				removeProp(exportdata[i], "updated_by");
				removeProp(exportdata[i], "LastAudit");
				removeProp(exportdata[i], "Video2Desc");
				removeProp(exportdata[i], "Bluetooth");
				removeProp(exportdata[i], "WifiAdapter");
				removeProp(exportdata[i], "Batt2");
				removeProp(exportdata[i], "Network3");
				removeProp(exportdata[i], "Network4");
				removeProp(exportdata[i], "Network5");
				removeProp(exportdata[i], "Network6");
				removeProp(exportdata[i], "LotID");
				removeProp(exportdata[i], "SkidNumber");
				removeProp(exportdata[i], "ProductID");
				removeProp(exportdata[i], "RecordType");
				removeProp(exportdata[i], "Current");
				removeProp(exportdata[i], "PreviousID");
				removeProp(exportdata[i], "NextID");
				removeProp(exportdata[i], "InternalOrderID");
			}
		}

		if (exportmode === "expanded") {
			console.log("Expanded Export");
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "WifiAdapter");
				removeProp(exportdata[i], "Network3");
				removeProp(exportdata[i], "Network4");
				removeProp(exportdata[i], "Network5");
				removeProp(exportdata[i], "Network6");
				removeProp(exportdata[i], "LotID");
				removeProp(exportdata[i], "SkidNumber");
				removeProp(exportdata[i], "ProductID");
				removeProp(exportdata[i], "RecordType");
				removeProp(exportdata[i], "Current");
				removeProp(exportdata[i], "PreviousID");
				removeProp(exportdata[i], "NextID");
				removeProp(exportdata[i], "InternalOrderID");
			}
		}

		if (exportmode === "exhaustive") {
			console.log("Exhaustive Export");
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "RecordType");
				removeProp(exportdata[i], "Current");
				removeProp(exportdata[i], "PreviousID");
				removeProp(exportdata[i], "NextID");
			}
			//Remove nothing else
		}

		if (exportmode === "quarterly") {
			console.log("Quarterly Export");
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "RecordType");
				removeProp(exportdata[i], "Current");
				removeProp(exportdata[i], "PreviousID");
				removeProp(exportdata[i], "NextID");

				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "ItemCreationDate");
				removeProp(exportdata[i], "updated_at");
				removeProp(exportdata[i], "updated_by");
				removeProp(exportdata[i], "LastAudit");
				removeProp(exportdata[i], "Video2Desc");
				removeProp(exportdata[i], "Bluetooth");
				removeProp(exportdata[i], "WifiAdapter");
				removeProp(exportdata[i], "Batt2");
				removeProp(exportdata[i], "Optical");
				removeProp(exportdata[i], "Webcam");
				removeProp(exportdata[i], "Battery");
				removeProp(exportdata[i], "Batt1");
				removeProp(exportdata[i], "WifiAdapter");
				removeProp(exportdata[i], "Video1Desc");
				removeProp(exportdata[i], "Video2Desc");
				removeProp(exportdata[i], "Bluetooth");
				removeProp(exportdata[i], "Batt2");
				removeProp(exportdata[i], "Network3");
				removeProp(exportdata[i], "Network4");
				removeProp(exportdata[i], "Network5");
				removeProp(exportdata[i], "Network6");
				removeProp(exportdata[i], "SkidNumber");
				removeProp(exportdata[i], "ProductID");
				removeProp(exportdata[i], "InternalOrderID");
				removeProp(exportdata[i], "Row");


				removeProp(exportdata[i], "Repaired");
				removeProp(exportdata[i], "RepairNote");
				removeProp(exportdata[i], "RepairedByUserID");
				removeProp(exportdata[i], "RepairedByName");
				removeProp(exportdata[i], "RepairDate");
				removeProp(exportdata[i], "UserID");
				removeProp(exportdata[i], "RepairID");
				removeProp(exportdata[i], "CreatedByUserID");
				removeProp(exportdata[i], "Adjusted");
				removeProp(exportdata[i], "SavedKey");
				removeProp(exportdata[i], "minorconditions");
				removeProp(exportdata[i], "majorconditions");
				removeProp(exportdata[i], "damagedconditions");
			}
			//Remove nothing else
		}
		ExportCSV(exportdata, exportfilename);
		setShowExportConfirmation(false);
		CloseExportMenu();
	}


	//Chips
	const ReturnGradeClass = (grade) => {
		if (grade === 'A') {
			return classes.gradea;
		}
		if (grade === 'B') {
			return classes.gradeb;
		}
		if (grade === 'C') {
			return classes.gradec;
		}
		if (grade === 'Repair') {
			return classes.graderepair;
		}
		if (grade === 'Scrap') {
			return classes.gradescrap;
		}
		if (grade === 'Bad') {
			return classes.gradebad;
		}
		return classes.gradenone;
	}

	//Edit Mode
	const [editmode, setEditMode] = useState(false);

	const EditMode = () => {
		setEditMode(!editmode);
		CloseViewOptionsMenu();
	}


	//Add Item
	//Method creates a new item in the DB and returns the ID so we can then load it in item view
	const AddItem = () => {
		axios.post(dbendpoint + "/items/addnewitem", defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === 'login') {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status === 'Success') {
					window.open('/item/' + res.data.ItemID);
				}
				if (res.data.Status === 'Failure') {
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
			}
		});
	}


	//Allow Old Records
	const AllowOldRecords = () => {
		localstate.searchoptions.allowoldrecords = !localstate.searchoptions.allowoldrecords;
		localstate.dbreload = true;
		colstate.RecordType = true;
		colstate.updated_by = true;
		setEditMode(false);
		UpdateState(localstate);
	}



	/* ##########################  TABLE HEAD  ########################## */
	/* ########################## Column Configuration  ########################## */
	const headCells = [
		{ id: 'created_at', numeric: false, label: 'Date', align: 'left', allowsort: true, style: { minWidth: "70px" } },
		{ id: 'ItemCreationDate', numeric: false, label: 'Date', align: 'left', allowsort: true, style: { minWidth: "70px" } },
		{ id: 'updated_by', numeric: false, label: 'Updated By', align: 'left', allowsort: true, style: { minWidth: "70px" } },
		{ id: 'updated_at', numeric: false, label: 'Last Update', align: 'left', allowsort: true, style: {} },
		{ id: 'RecordType', numeric: false, label: 'Record Type', align: 'left', allowsort: true, style: { minWidth: "70px" } },
		{ id: 'LastAudit', numeric: false, label: 'Audit Date', align: 'left', allowsort: true, style: { minWidth: "70px" } },
		{ id: 'SerialNumber', numeric: false, label: 'Serial', align: 'left', allowsort: true, style: {} },
		{ id: 'Vendor', numeric: false, label: 'Vendor', align: 'left', allowsort: true, style: {} },
		{ id: 'CreatedBy', numeric: false, label: 'Created By', align: 'left', allowsort: true, style: {} },
		{ id: 'LotID', numeric: false, label: 'LotID', align: 'left', allowsort: true, style: {} },
		{ id: 'Model', numeric: false, label: 'Model', align: 'left', allowsort: true, style: { minWidth: "140px" } },
		{ id: 'RepairDate', numeric: false, label: 'Repair Date', align: 'left', allowsort: true, style: { minWidth: "140px" } },
		{ id: 'RepairedByName', numeric: false, label: 'Repair User', align: 'left', allowsort: true, style: { minWidth: "140px" } },
		{ id: 'RepairNote', numeric: false, label: 'Repair Note', align: 'left', allowsort: true, style: { minWidth: "140px" } },
		{ id: 'Grade', numeric: false, label: 'Grade', align: 'center', allowsort: true, style: { width: "10px" } },
		{ id: 'BodyGrade', numeric: false, label: 'Body', align: 'center', allowsort: true, style: { width: "10px" } },
		{ id: 'LCDGrade', numeric: false, label: 'LCD', align: 'center', allowsort: true, style: { width: "10px" } },
		{ id: 'Conditions', numeric: false, label: 'Conditions', align: 'left', allowsort: false, style: {} },
		{ id: 'CPU', numeric: false, label: 'CPU', align: 'left', allowsort: true, style: {} },
		{ id: 'RAM', numeric: false, label: 'RAM', align: 'left', allowsort: true, style: { width: "80px" } },
		{ id: 'HDD1Size', numeric: false, label: 'HDD', align: 'left', allowsort: true, style: { width: "80px" } },
		{ id: 'HDD2Size', numeric: false, label: 'HDD2', align: 'left', allowsort: true, style: { width: "80px" } },
		{ id: 'Optical', numeric: false, label: 'Optical', align: 'left', allowsort: true, style: { width: "60px" } },
		{ id: 'Resolution', numeric: false, label: 'Resolution', align: 'left', allowsort: true, style: {} },
		{ id: 'Webcam', numeric: false, label: 'WC', align: 'center', allowsort: true, style: { width: "12px", textAlign: "center" } },
		{ id: 'Bluetooth', numeric: false, label: 'BT', align: 'center', allowsort: true, style: { width: "12px", textAlign: "center" } },
		{ id: 'Touchscreen', numeric: false, label: 'Tch', align: 'center', allowsort: true, style: { width: "12px", textAlign: "center" } },
		{ id: 'Batt1', numeric: false, label: 'Batt', align: 'left', allowsort: true, style: { width: "10px" } },
		{ id: 'Batt2', numeric: false, label: 'Batt2', align: 'left', allowsort: true, style: { width: "10px" } },
		{ id: 'Video1Desc', numeric: false, label: 'Video', align: 'left', allowsort: true, style: {} },
		{ id: 'Video2Desc', numeric: false, label: 'Video2', align: 'left', allowsort: true, style: {} },
		{ id: 'Notes', numeric: false, label: 'Notes', align: 'left', allowsort: true, style: {} },
		{ id: 'Cost', numeric: false, label: 'Cost', align: 'center', allowsort: true, style: { width: "60px" } },
		{ id: 'Status', numeric: false, label: 'Status', align: 'left', allowsort: true, style: {} },
		{ id: 'Row', numeric: false, label: 'Row', align: 'left', allowsort: true, style: {} },
		{ id: 'Location', numeric: false, label: 'Location', align: 'left', allowsort: true, style: {} },
		{ id: 'IntuneStatus', numeric: false, label: 'Intune Status', align: 'left', allowsort: true, style: {} },
		{ id: 'IntuneStatusDateTime', numeric: false, label: 'Intune Status Date', align: 'left', allowsort: true, style: {} },
		{ id: 'IntuneReported', numeric: false, label: 'Intune Reported', align: 'left', allowsort: true, style: {} },
		{ id: 'IntuneReportedDateTime', numeric: false, label: 'Intune Reported Date', align: 'left', allowsort: true, style: {} },
		{ id: 'PanGPStatus', numeric: false, label: 'PanGP Status', align: 'left', allowsort: true, style: {} },
		{ id: 'PanGPStatusDateTime', numeric: false, label: 'PanGP Status Date', align: 'left', allowsort: true, style: {} },
		{ id: 'PanGPReported', numeric: false, label: 'PanGP Reported', align: 'left', allowsort: true, style: {} },
		{ id: 'PanGPReportedDateTime', numeric: false, label: 'PanGP Reported Date', align: 'left', allowsort: true, style: {} },
		{ id: 'AbsPersistenceStatus', numeric: false, label: 'Abs. Persistence Status', align: 'left', allowsort: true, style: {} },
		{ id: 'AbsPersistenceStatusDateTime', numeric: false, label: 'Abs. Persistence Status Date', align: 'left', allowsort: true, style: {} },
		{ id: 'AbsPersistenceReported', numeric: false, label: 'Abs. Persistence Reported', align: 'left', allowsort: true, style: {} },
		{ id: 'AbsPersistenceReportedDateTime', numeric: false, label: 'Abs. Persistence Reported Date', align: 'left', allowsort: true, style: {} },
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};

		return (
			<thead style={{ display: "table-header-group" }}>
				<tr style={{ border: '1px solid #CCC', backgroundColor: '#DDD' }}>
					<td style={{ width: '14px', padding: "none", display: "table-cell", padding: "2px 4px 2px 5px" }}>
						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>
					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] &&
						(
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>

								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : 'asc'}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* This is a conditional for headCell where the id is "Name". This allows us to put a spacer in here for the expand icon button */}
										{/* The expand button is optional, this can be removed if needed! */}
										{(headCell.id === "Name") &&
											<div style={{ width: "30px", display: "inline-block" }}></div>
										}
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
							</td>
						))}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(['asc', 'desc']).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};





	/* 
																						 
	 _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
	_/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
   _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
  _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
 _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
																					 
																					 
  */
	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<div style={{ overflow: "auto", padding: "10px", marginBottom: "60px", minWidth: "700px" }}>

				{/* Item List - used to load only items placed in text area. */}
				<Drawer open={showitemlist} style={{ width: "600px" }}>
					<div style={{ height: "47px" }}></div>
					<Typography variant="h4" gutterBottom align="center">
						Serial List
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							Serial lists can be useful for checkout, audits, duplicate checks, and bulk edits.<br></br>
						</Typography>

						{/* You may want access to errors here */}
						{(errors.currenterror.errshow) &&
							<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
								<ErrorMessage />
							</div>
						}

						<TextareaAutosize style={{ width: "100%" }} minRows={10} placeholder="Paste from spreadsheet or scan serials into here."
							onChange={(event) => onChangeItemList(event)}
							defaultValue={localstate.searchoptions.itemlist}
						/>

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseItemList()}>
							Close
						</Button>

						{(dupeitems.length > 0) &&
							<div>
								Duplicates found:
								{dupeitems.map(function (dupe, i) {
									console.log('test');
									return <li key={i}>{dupe}</li>
								})}
							</div>
						}

					</div>
				</Drawer>

				{/* Bulk Edit Drawer */}
				<Drawer open={showbulkeditdrawer} style={{ width: "600px" }}>
					<div style={{ height: "47px" }}></div>
					<Typography variant="h4" gutterBottom align="center">
						Bulk Edit Selected Items
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							Changes apply to all checkbox selected items in the list view. You may perform multiple changes before returning to the list. Bulk changes may affect search results in the grid view. Select change type to get started.<br></br>
						</Typography>

						{/* You may want access to errors here - create parent container to hold position for you. */}
						<div style={{ height: "45px" }}>
							{(errors.currenterror.errshow) &&
								<div style={{ textAlign: "center", height: "35px", fontSize: "12px" }}>
									<ErrorMessage />
								</div>
							}
						</div>


						<FormControl className={classes.formControl} style={{ width: "300px" }}>
							<InputLabel id="Change type">Change Type</InputLabel>
							<Select variant="standard"
								labelId="change-type"
								id="change-type"
								value={changetype}
								onChange={handleChangeType}
							>
								<MenuItem value={'Location'}>Location</MenuItem>
								<MenuItem value={'Status'}>Status</MenuItem>
								<MenuItem value={'Mfg'}>Mfg</MenuItem>
								<MenuItem value={'Product'}>Product</MenuItem>
								<MenuItem value={'Grade'}>Overall Grade</MenuItem>
								<MenuItem value={'BodyGrade'}>Body Grade</MenuItem>
								<MenuItem value={'LCDGrade'}>LCD Grade</MenuItem>
								<MenuItem value={'CPU'}>CPU</MenuItem>
								<MenuItem value={'Cost'}>Cost</MenuItem>
								<MenuItem value={'Vendor'}>Vendor</MenuItem>
								<MenuItem value={'LastAudit'}>Audit Date</MenuItem>
								<MenuItem value={'LotID'}>Lot ID</MenuItem>
								<MenuItem value={'SkidNumber'}>Skid Number</MenuItem>
								<MenuItem value={'OrderID'}>Assign to Order</MenuItem>
								<div style={{fontWeight:"bold", marginTop:"15px"}}>&nbsp;&nbsp;MDM Items</div>
								<hr></hr>
								<MenuItem value={'IntuneStatus'}>Intune Status</MenuItem>
								<MenuItem value={'IntuneReported'}>Intune Reported to Vendor</MenuItem>

								<MenuItem value={'PanGPStatus'}>PanGP Status</MenuItem>
								<MenuItem value={'PanGPReported'}>PanGP Reported to Vendor</MenuItem>

								<MenuItem value={'AbsPersistenceStatus'}>Abs. Persistence Status</MenuItem>
								<MenuItem value={'AbsPersistenceReported'}>Abs. Persistence Reported to Vendor</MenuItem>

								{/* Listing system (not finished, paused) */}
								{/*
								<MenuItem value={'Create Draft Listing'}>Create Draft Listing</MenuItem>
								*/}

							</Select>
						</FormControl>

						{/* To do: Set change values. Most are just text, so we'll start with that: */}
						{(changeistext) &&
							<div>
								<TextField variant="standard" style={{ width: "300px" }} required id="standard-required" defaultValue="" onChange={handleChangeValue} />
							</div>
						}


						{/* Status */}
						{(changeisstatus) &&
							<div>
								<FormControl className={classes.formControl}>
									<Select variant="standard"
										defaultValue={""}
										onChange={(event) => handleChangeStatus(event)}
										style={{ width: "300px" }}
									>
										<MenuItem value={"Checked In"}>Checked In</MenuItem>
										<MenuItem value={"Sold"}>Sold</MenuItem>
										<MenuItem value={"Scrap"}>Scrap</MenuItem>
										<MenuItem value={"Returned to Vendor"}>Returned to Vendor</MenuItem>
										<MenuItem value={"Undetermined or Missing"}>Undetermined or Missing</MenuItem>
									</Select>
								</FormControl>
							</div>
						}

						{/* Float: Cost */}
						{(changeisfloat) &&
							<div>
								<TextField variant="standard" type="number" step="0.01" inputRef={el => changevaluefloat.current = el} style={{ width: "300px" }} required id="standard-required" defaultValue="Value" onChange={handleChangeValue} />
							</div>
						}

						{/* Integer: LotID or SkidNumber */}
						{(changeisint) &&
							<div>
								<TextField variant="standard" type="number" step="1"
									inputRef={el => changevalueint.current = el}
									style={{ width: "300px" }}
									required
									id="standard-required"
									defaultValue=""
									onChange={handleChangeValue} />
							</div>
						}

						{/* Product */}
						{(changeisproduct) &&
							<div>
								<Autocomplete freeSolo forcePopupIcon={false} disableClearable style={{ width: "300px", padding: "0px" }}
									open={openoptions1} onOpen={() => { InitOptions1(); }} onClose={() => { openOptions1(false); }}
									//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
									//* onChange={(event, newValue) => onChangeOption1(event, newValue)} */}
									onChange={(event, newValue) => handleChangeProduct(event, newValue)}
									onInputChange={(event) => AutoCompleteSearch1(event.target.value)}
									isOptionEqualToValue={(option, value) => option[autocompletesearchtype1] === value}
									getOptionLabel={(option) => option[autocompletesearchtype1]}
									options={options1}
									loading={loadingoptions1}
									renderInput={(params) => (
										<TextField variant="standard"
											className={classes.autocompleteinput}
											style={{ padding: "0px" }}
											{...params}
											InputProps={{
												disableUnderline: false,
												...params.InputProps,
												autoComplete: "new-password",
												endAdornment: (
													<React.Fragment>
														{loadingoptions1 ? <CircularProgress color="inherit" size={20} /> : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
							</div>
						}

						{/* Date */}
						{(changeisdate) &&
							<TextField variant="standard" style={{ width: "300px" }} required id="standard-required" placeholder="Format: YYYY-MM-DD" onChange={handleChangeValue} />
						}

						{/* FlagMDM
						
						//Intune/PanGP/AbsPersistence Status:
							//	0: Unknown
							//	1: Confirmed Clear (probably not available without evidence, this is a placeholder in case we need to use it one day)
							//	2: Likely Enabled
							//	3: Confirmed Enabled
							//	4: Resolved Automatically *(When inventory item is checked out to an order and IntuneStatus=3)
							//     This option should not be available in front end.
							//	5: Resolved by Vendor *(Editable individually or bulk by purchasing agent)
							//	6: Resolved Despite Vendor *(When inventory item IntuneStatus=5,
							//     then iPXE set Confirmed Enabled, Intune is marked resolved despite vendor statement of removal.)
							//	   Also send risk record for when this happens.
							//     This option should not be available in front end.	
							//	7: Unable to Resolve - Vendor could not resolve.
							// 
							// 
							// 
							// */}


						{(changeisflagmdm) &&
							<FormControl className={classes.formControl}>
								<Select variant="standard"
									defaultValue={""}
									onChange={(event) => handleChangeStatus(event)}
									style={{ width: "300px" }}
								>
									<MenuItem value={2}>Likely Enabled</MenuItem>
									<MenuItem value={5}>Resolved by Vendor</MenuItem>
									<MenuItem value={7}>Unable to Resolve</MenuItem>
								</Select>
							</FormControl>
						}

						{/* FlagMDMReported */}
						{(changeisflagmdmreported) &&
							<FormControl className={classes.formControl}>
								<Select variant="standard"
									defaultValue={""}
									onChange={(event) => handleChangeStatus(event)}
									style={{ width: "300px" }}
								>
									<MenuItem value={0}>No</MenuItem>
									<MenuItem value={1}>Yes</MenuItem>
								</Select>
							</FormControl>
						}


						<div style={{ textAlign: "center" }}>
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => ApplyBulkEdit()}
								ref={el => btnApplyBulkEdit.current = el}>
								Apply Change
							</Button>

							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => CloseBulkEditDrawer()}>
								Close
							</Button>


							{(showlistingsbtn) &&

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => GoToListings(listingids)}>
									Go To New Listings
								</Button>

							}
						</div>

					</div>
				</Drawer>



				<div style={{ height: "35px" }}>
					<div style={{ textAlign: 'center' }}>
						<h2>Inventory Manager</h2>
					</div>
					{/* Optional Conditional to hide errors while drawer is using errors */}
					{(errors.currenterror.errshow && !showbulkeditdrawer) &&
						<div style={{ position: "relative", float: "right", bottom: "26px", height: "0px", fontSize: "12px" }}>
							<ErrorMessage />
						</div>
					}

					<div style={{ position: "relative", float: "right", bottom: "30px", height: "0px" }}>
						<ProgressBar />
					</div>

				</div>


				{/* ##########################  Search Inputs  ########################## */}
				{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
				{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}


				<>
					{/* Inputs can be Grouped inside of new flex containers: */}
					{/* Key-Value Searches - Optionally add a grid for better resizing look */}
					<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap" }}>
						{/* Group 1 */}
						<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-evenly" }}>
						
								{(searchinputs.show1) &&
									<SearchInput
										searchinput={1}
										key={key1}
										localstate={state}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
								}
								{(searchinputs.show2) &&
									<SearchInput
										searchinput={2}
										key={key2}
										localstate={state}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
								}
						
								{(searchinputs.show3) &&
									<SearchInput
										searchinput={3}
										key={key3}
										localstate={localstate}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
								}
								{(searchinputs.show4) &&
									<SearchInput
										searchinput={4}
										key={key4}
										localstate={localstate}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
								}
						</div>
						
						{/* Group 2 */}
						{(localstate.expandsearch) &&
								<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-evenly" }}>
									<SearchInput
										searchinput={5}
										key={key5}
										localstate={localstate}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
									<SearchInput
										searchinput={6}
										key={key6}
										localstate={localstate}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
									<SearchInput
										searchinput={7}
										key={key7}
										localstate={localstate}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
									<SearchInput
										searchinput={8}
										key={key8}
										localstate={localstate}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
								</div>
							}
					</div>
				</>

				{/* End of Search Inputs */}


				{/* Top Buttons & Pagination */}
				<div style={{ height: "5px" }}>&nbsp;</div>
				<div>
					{(!showdeleteconfirmation && !showexportconfirmation) &&
						<React.Fragment>

							{(userPerms.updateItem === 1) &&
								<Button
									className={(userPerms.updateItem === 1) ? classes.bluebtn : classes.hidden}
									color="primary" variant="contained"
									onClick={() => SaveChanges()}
									ref={el => btnSave.current = el}>
									<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
								</Button>
							}

							{(userPerms.updateItem === 1) &&
								<Button
									className={(userPerms.updateItem === 1) ? classes.bluebtn : classes.hidden}
									color="primary" variant="contained"
									style={{ display: "none" }}
									onClick={() => SaveChanges()}
									ref={el => btnPendingSave.current = el}>
									<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
								</Button>
							}

							<Button
								className={(userPerms.createItem === 1) ? classes.bluebtn : classes.hidden}
								color="primary" variant="contained"
								onClick={() => AddItem()}
								ref={el => btnAddItem.current = el}>
								<AddIcon />&nbsp;Add Item
							</Button>

							{/* New View Options Menu */}
							{/* Compact a lot of table view options into this one menu to save space! */}
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								aria-haspopup="true"
								onClick={ShowViewOptionsMenu}>
								View Options
							</Button>

							<Menu
								className={classes.bluebtn}
								sx={{ zIndex: 3000 }} // Adjust the z-index here
								color="primary"
								id="view-options-menu"
								anchorEl={showViewOptionsMenu}
								keepMounted
								open={Boolean(showViewOptionsMenu)}
								onClose={CloseViewOptionsMenu}
							>
								<div style={{ display: "inline-block", overflow: "auto", verticalAlign: "top", zIndex:3000 }}>
									<div style={{ fontWeight: "bold", textAlign: "center" }}>Columns</div>
									<MenuItem disableRipple className={classes.columnmenu}>
										<div style={{verticalAlign:"top"}}> {/* Optional container for 2 column menu! */}
											<div style={{display:"inline-block", maxHeight:"600px", overflow:"auto", verticalAlign:"top"}}>
											<div style={{display:"inline-block", verticalAlign:"top"}}>
												<FormGroup>
													{/* ##########################  Column Toggles  ########################## */}
													<FlexColumnOption value="ItemCreationDate" label="Creation Date" />
													<FlexColumnOption value="CreatedBy" label="Created By" />
													<FlexColumnOption value="LastAudit" label="Last Audit Date" />
													<FlexColumnOption value="Vendor" label="Vendor" />
													<FlexColumnOption value="LotID" label="Lot ID" />
													<FlexColumnOption value="BodyGrade" label="GRADE: Body" />
													<FlexColumnOption value="LCDGrade" label="GRADE: LCD" />
													<FlexColumnOption value="HDD1Size" label="HDD1" />
													<FlexColumnOption value="HDD2Size" label="HDD2" />
													<FlexColumnOption value="Optical" label="Optical" />
													<FlexColumnOption value="Resolution" label="Resolution" />
													<FlexColumnOption value="Bluetooth" label="Bluetooth" />
													<FlexColumnOption value="Touchscreen" label="Touchscreen" />
													<FlexColumnOption value="Batt1" label="Batt1" />
													<FlexColumnOption value="Batt2" label="Batt2" />
													<FlexColumnOption value="Video1Desc" label="Video Card 1" />
													<FlexColumnOption value="Video2Desc" label="Video Card 2" />

												</FormGroup>
											</div>
											<div style={{display:"inline-block", verticalAlign:"top"}}>
											<FormGroup>
													{/* ##########################  Column Toggles  ########################## */}
													<FlexColumnOption value="Notes" label="Notes" />
													<FlexColumnOption value="Cost" label="Cost" />
													<FlexColumnOption value="Status" label="Status" />
													<FlexColumnOption value="updated_at" label="Last Update" />
													<FlexColumnOption value="updated_by" label="Last Updated By" />
													<FlexColumnOption value="RepairDate" label="Repair Date" />
													<FlexColumnOption value="RepairedByName" label="Repair User" />
													<FlexColumnOption value="RepairNote" label="Repair Note" />
													<FlexColumnOption value="IntuneStatus" label="Intune Status" />
													<FlexColumnOption value="IntuneStatusDateTime" label="Intune Status Date" />
													<FlexColumnOption value="IntuneReported" label="Intune Reported" />
													<FlexColumnOption value="IntuneReportedDateTime" label="Intune Reported Date Time" />
													<FlexColumnOption value="PanGPStatus" label="PanGP Status" />
													<FlexColumnOption value="PanGPStatusDateTime" label="PanGP Status Date" />
													<FlexColumnOption value="PanGPReported" label="PanGP Reported" />
													<FlexColumnOption value="PanGPReportedDateTime" label="PanGP Reported Date Time" />
													<FlexColumnOption value="AbsPersistenceStatus" label="Abs. Persistence Status" />
													<FlexColumnOption value="AbsPersistenceStatusDateTime" label="Abs. Persistence Status Date" />
													<FlexColumnOption value="AbsPersistenceReported" label="Abs. Persistence Reported" />
													<FlexColumnOption value="AbsPersistenceReportedDateTime" label="Abs. Persistence Reported Date Time" />
												</FormGroup>
												</div>
											</div>
										</div>
									</MenuItem>
								</div>
									<div style={{ display: "inline-block", maxHeight: "600px", overflow: "auto", verticalAlign: "top", maxWidth: "200px", padding: "0px 8px" }}>
										<div style={{ fontWeight: "bold", textAlign: "center" }}>Search Options</div>
										<Button
											className={classes.bluebtn}
											sx={{ width: "100%" }}
											color="primary" variant="contained"
											onClick={() => ToggleExpandSearch()}
										>
											{(!localstate.expandsearch) &&
												<>Expand Search</>
											}
											{(localstate.expandsearch) &&
												<>Collapse Search</>
											}
										</Button>
										<br></br><br></br>
										<div style={{ fontWeight: "bold", textAlign: "center" }}>Table Options</div>
										
										<Button
											className={(userPerms.updateDemoData === 1) ? classes.bluebtn : classes.hidden}
											sx={{ width: "100%" }}
											color="primary" variant="contained"
											{...(editmode ? {
												style: {
													textDecoration: "underline"
												}
											} :
												{
													style: {
														textDecoration: "none"
													},
													disabled: (localstate.rowsperpage > localstate.maxrowseditmode ? true : false)
												})}
											onClick={() => EditMode()}
											ref={el => btnEditMode.current = el}
										>
											Edit Mode
										</Button>
								</div>
							</Menu>
							{/* END OF VIEW OPTIONS MENU */}








							<Button
								className={(userPerms.updateItem === 1) ? classes.bluebtn : classes.hidden}
								color="primary" variant="contained"
								onClick={() => RejectIfInvalidSelected(true, setShowBulkEditDrawer)}>
								Edit Selected
							</Button>


							{/* Optional Btns
                        <div style={{ textAlign: 'left', display: "inline-flex", padding: "8px 8px 10px 0px" }}>
                            <Button variant="contained" color="default" onClick={() => AddRow()} >
                                Add Row
							</Button>
                        </div>
						
						<div style={{ textAlign: 'left', display: "inline-flex", padding: "8px 8px 10px 0px" }}>
							<Button variant="contained" color="default" onClick={() => DeleteSelectedInit()} >
								Delete Selected
							</Button>
						</div>
						*/}

						

							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => setShowItemList(true)}
								{...(localstate.searchoptions.itemlist !== '' ? {
									style: {
										textDecoration: "underline"
									}
								} :
									{
										style: {
											textDecoration: "none"
										}
									})}
								ref={el => btnSerialList.current = el}>
								Serial List
							</Button>


							{/* Optional Expand All
                        <div style={{ textAlign: 'left', display: "inline-flex", padding: "8px 8px 10px 0px" }}>
								<Button variant="contained" color="default" onClick={() => ExpandAll()} >
									Expand All
								</Button>
                        </div>
						*/}

							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								aria-haspopup="true"
								onClick={ShowExportMenu}
								ref={el => btnExport.current = el}>
								Export
							</Button>
							<Menu id="simple-menu"
								className={classes.bluebtn}
								color="primary"
								anchorEl={showexportmenu}
								keepMounted
								open={Boolean(showexportmenu)}
								onClose={CloseExportMenu}>
								<MenuItem onClick={() => RejectIfInvalidSelected("selected", InitExport)}>Export Selected</MenuItem>
								<MenuItem onClick={() => InitExport("searchresults")}>Export Search Results (limit 20000)</MenuItem>
							</Menu>

							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => ResetSearches()}
								ref={el => btnResetSearches.current = el}>
								<RestartAltIcon />&nbsp;Reset
							</Button>	

							{/* 
							{(userRole === "admin") &&
								<Button
									className={(userRole === "admin") ? classes.bluebtn : classes.hidden}
									color="primary" variant="contained"
									onClick={() => AllowOldRecords()}
									{...(localstate.searchoptions.allowoldrecords) ? {
										style: {
											textDecoration: "underline"
										}
									} :
										{
											style: {
												textDecoration: "none"
											}
										}}>
									Allow Old Records
								</Button>
							} */}


						</React.Fragment>
					}

					{/* Confirmation Space */}
					{(showdeleteconfirmation) &&
						<div>
							<b>Are you sure you want to delete these items?</b>
							<div style={{ padding: "10px 0px" }}>
								{deleteitems.map((row, index) => {
									if (deleteitems.length === index + 1) {
										return (<span key={index}>{row.Name}</span>)
									} else {
										return (<span key={index}>{row.Name}, </span>)
									}
								})}
							</div>
							<Button className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
							<Button className={classes.bluebtn} color="primary" variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
						</div>
					}

					{/* Export Mode Confirmation */}
					{(showexportconfirmation) &&
						<div>
							<b>Export Mode</b><br></br>
							In most cases a simple export is appropriate as to avoid revealing information to potential buyers. {exportmessage}
							<div style={{ paddingTop: "10px" }}>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrepareExport("simple")}>
									Simple
								</Button>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrepareExport("expanded")}>
									Expanded
								</Button>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrepareExport("exhaustive")}>
									Exhaustive
								</Button>
								{(userRole === "admin") &&
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => PrepareExport("quarterly")}>
										Quarterly
									</Button>
								}
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => setShowExportConfirmation(false)}>
									Cancel
								</Button>
							</div>
						</div>
					}

					{(localstate.totalitems > 0) &&
						<FlexTablePagination
							localstate={localstate}
							classes={classes}
							editmode={editmode}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					}
				</div>

				{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
				<div className={classes.flexgridcontainerA}>
					{/* ##########################  Start of Table  ########################## */}
					<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{ minWidth: "100%", borderCollapse: "collapse", borderColor: "grey" }}>
						<EnhancedTableHead
							numSelected={localstate.selectedcount}
							classes={classes}
							order={localstate.order}
							orderBy={localstate.orderby}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={state.griditems.length}
						/>

						{/* /* ##########################  Row Design  ########################## */}
						{/* If DB reload, don't allow view of table. */}
						{(!localstate.dbreload) &&
							<tbody style={{ display: "table-row-group" }}>
								{(localstate.griditems.length > 0) &&
									localstate.griditems.map((row, index) => {
										//Create all-new refs on each render. Helps avoid issues with grid states.
										//Refs are needed mostly for editable inputs
										rowRefs.current[index + 'Checkbox'] = React.createRef();
										rowRefs.current[index + 'SaveStatus'] = React.createRef();
										rowRefs.current[index + 'CPU'] = React.createRef();
										rowRefs.current[index + 'RAM'] = React.createRef();
										rowRefs.current[index + 'HDD1Size'] = React.createRef();
										rowRefs.current[index + 'HDD2Size'] = React.createRef();
										rowRefs.current[index + 'Optical'] = React.createRef();
										rowRefs.current[index + 'Resolution'] = React.createRef();
										rowRefs.current[index + 'Batt1'] = React.createRef();
										rowRefs.current[index + 'Batt2'] = React.createRef();
										rowRefs.current[index + 'Video1Desc'] = React.createRef();
										rowRefs.current[index + 'Video2Desc'] = React.createRef();
										rowRefs.current[index + 'Notes'] = React.createRef();
										rowRefs.current[index + 'Cost'] = React.createRef();
										rowRefs.current[index + 'Row'] = React.createRef();
										rowRefs.current[index + 'Location'] = React.createRef();

										return (
											<React.Fragment key={row.ItemID}>
												<tr className={classes.flexgridrow}>
													{/* Checkbox - Requires inner div to change background color with SaveStatus */}
													<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + 'SaveStatus'] = el}>
														<div style={{ padding: "3px 4px 1px 4px" }}>
															{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
															{/* Don't show checkbox if this is a PendingItem */}
															{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el => rowRefs.current[index + "Checkbox"] = el}
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}
														</div>
													</td>

													{/* Date */}
													{(colstate.created_at) &&
														<td className={classes.flexgridstaticcontainer}>
															<Moment element='span' format="YYYY-MM-DD h:mmA">{row.created_at}</Moment>
														</td>
													}

													{/* Creation Date */}
													{(colstate.ItemCreationDate) &&
														<td className={classes.flexgridstaticcontainer}>
															<Moment element='span' format="YYYY-MM-DD h:mmA">{row.ItemCreationDate}</Moment>
														</td>
													}

													{/* Record Date - Old Record View */}
													{(colstate.updated_by) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.updated_by}
														</td>
													}


													{/* Updated By */}
													{(colstate.updated_at) &&
														<td className={classes.flexgridstaticcontainer}>
															<Moment element='span' format="YYYY-MM-DD h:mmA">{row.updated_at}</Moment>
														</td>
													}


													{/* Record Type */}
													{(colstate.RecordType) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.RecordType}
														</td>
													}

													{/* Audit Date */}
													{(colstate.LastAudit) &&
														<td className={classes.flexgridstaticcontainer}>
															<Moment element='span' format="YYYY-MM-DD h:mmA">{row.LastAudit}</Moment>
														</td>
													}

													{/* SerialNumber */}
													{(colstate.SerialNumber) &&
														<td className={classes.flexgridstaticcontainer}>
															{/* May be expensive on front end....
														{(!row.ExpandRow) &&
															<div style={{width:"30px", display:"inline-block", textAlign:"center", fontSize:"18px", lineHeight:"15px", verticalAlign:"middle"}}>
																<ExpandMoreIcon className={classes.transparenticon} color="primary" fontSize="inherit"></ExpandMoreIcon>
															</div>
														}
														{(row.ExpandRow) &&
															<div style={{width:"30px", display:"inline-block", textAlign:"center", fontSize:"18px", lineHeight:"15px", verticalAlign:"middle"}}>
																<ExpandLessIcon className={classes.transparenticon} color="primary" fontSize="inherit"></ExpandLessIcon>
															</div>
														}
												
													*/}
															<a href={hostbase + "/item/" + row.ItemID} target="_blank">{row.SerialNumber}</a>
														</td>
													}

													{/* Vendor */}
													{(colstate.Vendor) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Vendor}
														</td>
													}

													{/* CreatedBy */}
													{(colstate.CreatedBy) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.CreatedBy}
														</td>
													}


													{/* LotID */}
													{(colstate.LotID) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.LotID}
														</td>
													}


													{/* Model */}
													{(colstate.Model) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Model}
														</td>
													}

													{/* RepairDate */}
													{(colstate.RepairDate) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.RepairDate) &&
																<Moment element='span' format="YYYY-MM-DD h:mmA">{row.RepairDate}</Moment>
															}
														</td>
													}

													{/* Repair User */}
													{(colstate.RepairedByName) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.RepairedByName}
														</td>
													}

													{/* Repair Note */}
													{(colstate.RepairNote) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.RepairNote}
														</td>
													}

													{/* Grade 
											Try: Replace drop down here with static - do the same with Grades, see if speed is any better.
											
											DISABLED DROP DOWNS:
											*/}
													{(editmode && colstate.Grade) &&
														<td style={{ textAlign: "center" }}>
															<FormControl className={classes.formControl}>
																<Select variant="standard"
																	defaultValue={row.GradeDefault ? row.GradeDefault : "-"}
																	disableUnderline
																	onChange={(event) => onChangeValue(event, index, 'Grade')}
																	classes={{
																		icon: classes.nodisplay,
																		select: classes.selectpadding
																	}}
																>
																	<MenuItem value={"-"}>
																		<Chip size="small" label="N" variant="outlined" clickable className={classes.gradenone} />
																	</MenuItem>
																	<MenuItem value={"A"}>
																		<Chip size="small" label="A" clickable className={classes.gradea} />
																	</MenuItem>
																	<MenuItem value={"B"}>
																		<Chip size="small" label="B" clickable className={classes.gradeb} />
																	</MenuItem>
																	<MenuItem value={"C"}>
																		<Chip size="small" label="C" clickable className={classes.gradec} />
																	</MenuItem>
																	<MenuItem value={"Repair"}>
																		<Chip size="small" label="Repair" clickable className={classes.graderepair} />
																	</MenuItem>
																	<MenuItem value={"Scrap"}>
																		<Chip size="small" label="Scrap" clickable className={classes.gradescrap} />
																	</MenuItem>
																</Select>
															</FormControl>
														</td>
													}

													{/* Body Grade - With Conditional for Desktops, Workstations*/}
													{(editmode && colstate.BodyGrade) &&
														<td style={{ textAlign: "center" }}>
															{(row.AssetType !== "Desktop") &&
																<FormControl className={classes.formControl}>
																	<Select variant="standard"
																		defaultValue={row.BodyGradeDefault ? row.BodyGradeDefault : "-"}
																		disableUnderline
																		onChange={(event) => onChangeValue(event, index, 'BodyGrade')}
																		classes={{
																			icon: classes.nodisplay,
																			select: classes.selectpadding
																		}}
																	>
																		<MenuItem value={"-"}>
																			<Chip size="small" label="N" variant="outlined" clickable className={classes.gradenone} />
																		</MenuItem>
																		<MenuItem value={"A"}>
																			<Chip size="small" label="A" clickable className={classes.gradea} />
																		</MenuItem>
																		<MenuItem value={"B"}>
																			<Chip size="small" label="B" clickable className={classes.gradeb} />
																		</MenuItem>
																		<MenuItem value={"C"}>
																			<Chip size="small" label="C" clickable className={classes.gradec} />
																		</MenuItem>
																		<MenuItem value={"Bad"}>
																			<Chip size="small" label="Bad" clickable className={classes.gradebad} />
																		</MenuItem>
																	</Select>
																</FormControl>
															}
														</td>
													}

													{/* LCD Grade */}
													{(editmode && colstate.LCDGrade) &&
														<td style={{ textAlign: "center" }}>
															{(row.AssetType !== "Desktop") &&
																<FormControl className={classes.formControl}>
																	<Select variant="standard"
																		defaultValue={row.LCDGradeDefault ? row.LCDGradeDefault : "-"}
																		disableUnderline
																		onChange={(event) => onChangeValue(event, index, 'LCDGrade')}
																		classes={{
																			icon: classes.nodisplay,
																			select: classes.selectpadding
																		}}
																	>
																		<MenuItem value={"-"}>
																			<Chip size="small" label="N" variant="outlined" clickable className={classes.gradenone} />
																		</MenuItem>
																		<MenuItem value={"A"}>
																			<Chip size="small" label="A" clickable className={classes.gradea} />
																		</MenuItem>
																		<MenuItem value={"B"}>
																			<Chip size="small" label="B" clickable className={classes.gradeb} />
																		</MenuItem>
																		<MenuItem value={"C"}>
																			<Chip size="small" label="C" clickable className={classes.gradec} />
																		</MenuItem>
																		<MenuItem value={"Bad"}>
																			<Chip size="small" label="Bad" clickable className={classes.gradebad} />
																		</MenuItem>
																	</Select>
																</FormControl>
															}
														</td>
													}


													{/* Non-Editable: */}
													{(!editmode && colstate.Grade) &&
														<td style={{ textAlign: "center" }}>
															<Chip size="small" label={row.Grade} className={ReturnGradeClass(row.Grade)} />
														</td>
													}

													{/* Body Grade - With Conditional for Desktops, Workstations*/}
													{(!editmode && colstate.BodyGrade) &&
														<td style={{ textAlign: "center" }}>
															<Chip size="small" label={row.BodyGrade} className={ReturnGradeClass(row.BodyGrade)} />
														</td>
													}

													{/* LCD Grade */}
													{(!editmode && colstate.LCDGrade) &&
														<td style={{ textAlign: "center" }}>
															<Chip size="small" label={row.LCDGrade} className={ReturnGradeClass(row.LCDGrade)} />
														</td>
													}


													{/* Conditions */}
													{(colstate.Conditions) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.minorconditions.length > 0) &&
																<div>Minor:&nbsp;
																	{row.minorconditions.map((condition, index) => {
																		return (
																			<React.Fragment key={condition.ConditionID}>
																				{condition.ConditionText}
																				{(index + 1 !== row.minorconditions.length) &&
																					<>, </>
																				}
																			</React.Fragment>
																		)
																	})}
																</div>
															}
															{(row.majorconditions.length > 0) &&
																<div>Major:&nbsp;
																	{row.majorconditions.map((condition, index) => {
																		return (
																			<React.Fragment key={condition.ConditionID}>
																				{condition.ConditionText}
																				{(index + 1 !== row.majorconditions.length) &&
																					<>, </>
																				}
																			</React.Fragment>
																		)
																	})}
																</div>
															}
															{(row.damagedconditions.length > 0) &&
																<div>Damaged:&nbsp;
																	{row.damagedconditions.map((condition, index) => {
																		return (
																			<React.Fragment key={condition.ConditionID}>
																				{condition.ConditionText}
																				{(index + 1 !== row.damagedconditions.length) &&
																					<>, </>
																				}
																			</React.Fragment>
																		)
																	})}
																</div>
															}
														</td>
													}

													{/* CPU */}
													{(editmode && colstate.CPU) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'CPU'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'CPU')}
																onKeyUp={(event) => onChangeValue(event, index, 'CPU')}
																defaultValue={row.CPU} />
														</td>
													}

													{(!editmode && colstate.CPU) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.CPU}
														</td>
													}


													{/* RAM */}
													{(editmode && colstate.RAM) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'RAM'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'RAM')}
																onKeyUp={(event) => onChangeValue(event, index, 'RAM')}
																defaultValue={row.RAM} />
														</td>
													}

													{(!editmode && colstate.RAM) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.RAM}
														</td>
													}

													{/* HDD1 */}
													{(editmode && colstate.HDD1Size) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'HDD1Size'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'HDD1Size')}
																onKeyUp={(event) => onChangeValue(event, index, 'HDD1Size')}
																defaultValue={row.HDD1Size} />
														</td>
													}

													{(!editmode && colstate.HDD1Size) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.HDD1Size}
														</td>
													}

													{/* HDD2Size */}
													{(editmode && colstate.HDD2Size) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'HDD2Size'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'HDD2Size')}
																onKeyUp={(event) => onChangeValue(event, index, 'HDD2Size')}
																defaultValue={row.HDD2Size} />
														</td>
													}

													{(!editmode && colstate.HDD2Size) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.HDD2Size}
														</td>
													}

													{/* Optical */}
													{(editmode && colstate.Optical) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Optical'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Optical')}
																onKeyUp={(event) => onChangeValue(event, index, 'Optical')}
																defaultValue={row.Optical} />
														</td>
													}

													{(!editmode && colstate.Optical) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Optical}
														</td>
													}

													{/* Resolution */}
													{(editmode && colstate.Resolution) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Resolution'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Resolution')}
																onKeyUp={(event) => onChangeValue(event, index, 'Resolution')}
																defaultValue={row.Resolution} />
														</td>
													}

													{(!editmode && colstate.Resolution) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Resolution}
														</td>
													}

													{/* Webcam */}
													{(editmode && colstate.Webcam) &&
														<td>{/* defaultChecked={row.Webcam === 1 ? true : false} */}
															<div style={{ padding: "3px 4px 1px 4px", textAlign: "center" }}>
																<Checkbox
																	className={classes.gridcheckbox}
																	disableRipple
																	color="default"
																	defaultChecked={(!row.Webcam) ? false : ((row.Webcam === 1) ? true : false)}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onChange={(event) => onChangeValue(event, index, 'Webcam')}
																/>
															</div>
														</td>
													}

													{(!editmode && colstate.Webcam) &&
														<td className={classes.flexgridstaticcontainer}>
															<CheckCircleIcon color="success" className={(row.Webcam === 0) ? classes.hidden : ''} />
														</td>
													}

													{/* Bluetooth */}
													{(editmode && colstate.Bluetooth) &&
														<td>
															<div style={{ padding: "3px 4px 1px 4px", textAlign: "center" }}>
																<Checkbox
																	className={classes.gridcheckbox}
																	disableRipple
																	color="default"
																	defaultChecked={row.Bluetooth === 1 ? true : false}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onChange={(event) => onChangeValue(event, index, 'Bluetooth')}
																/>
															</div>
														</td>
													}

													{(!editmode && colstate.Bluetooth) &&
														<td className={classes.flexgridstaticcontainer}>
															<CheckCircleIcon color="success" className={(row.Bluetooth === 0) ? classes.hidden : ''} />
														</td>
													}

													{/* Touchscreen */}
													{(editmode && colstate.Touchscreen) &&
														<td>
															<div style={{ padding: "3px 4px 1px 4px", textAlign: "center" }}>
																<Checkbox
																	className={classes.gridcheckbox}
																	disableRipple
																	color="default"
																	defaultChecked={row.Touchscreen === 1 ? true : false}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onChange={(event) => onChangeValue(event, index, 'Touchscreen')}
																/>
															</div>
														</td>
													}

													{(!editmode && colstate.Touchscreen) &&
														<td className={classes.flexgridstaticcontainer}>
															<CheckCircleIcon color="success" className={(row.Touchscreen === 0) ? classes.hidden : ''} />
														</td>
													}

													{/* Batt1 */}
													{(editmode && colstate.Batt1) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Batt1'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px' }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Batt1')}
																onKeyUp={(event) => onChangeValue(event, index, 'Batt1')}
																defaultValue={row.Batt1} />
														</td>
													}

													{(!editmode && colstate.Batt1) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Batt1}
														</td>
													}

													{/* Batt2 */}
													{(editmode && colstate.Batt2) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Batt2'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px' }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Batt2')}
																onKeyUp={(event) => onChangeValue(event, index, 'Batt2')}
																defaultValue={row.Batt2} />
														</td>
													}

													{(!editmode && colstate.Batt2) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Batt2}
														</td>
													}

													{/* Video1Desc */}
													{(editmode && colstate.Video1Desc) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Video1Desc'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px' }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Video1Desc')}
																onKeyUp={(event) => onChangeValue(event, index, 'Video1Desc')}
																defaultValue={row.Video1Desc} />
														</td>
													}

													{(!editmode && colstate.Video1Desc) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Video1Desc}
														</td>
													}


													{/* Video2Desc */}
													{(editmode && colstate.Video2Desc) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Video2Desc'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px' }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Video2Desc')}
																onKeyUp={(event) => onChangeValue(event, index, 'Video2Desc')}
																defaultValue={row.Video2Desc} />
														</td>
													}

													{(!editmode && colstate.Video2Desc) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Video2Desc}
														</td>
													}

													{/* Notes */}
													{(editmode && colstate.Notes) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Notes'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px' }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Notes')}
																onKeyUp={(event) => onChangeValue(event, index, 'Notes')}
																defaultValue={row.Notes} />
														</td>
													}

													{(!editmode && colstate.Notes) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Notes}
														</td>
													}

													{/* Cost */}
													{(editmode && colstate.Cost) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + 'Cost'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Cost')}
																onKeyUp={(event) => onChangeValue(event, index, 'Cost')}
																onBlur={(event) => DetectBlankNumber(event, index, "Cost")}
																defaultValue={row.Cost} />
														</td>
													}

													{(!editmode && colstate.Cost) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Cost}
														</td>
													}

													{/* Status - Disabled Edit */}
													{(editmode && colstate.Status) &&
														<td>
															<FormControl className={classes.formControl}>
																<Select variant="standard"
																	defaultValue={row.Status ? row.Status : ""}
																	disableUnderline
																	onChange={(event) => onChangeValue(event, index, 'Status')}
																	classes={{
																		icon: classes.nodisplay,
																		select: classes.selectpadding
																	}}
																>
																	<MenuItem value={"Checked In"}>Checked In</MenuItem>
																	<MenuItem value={"Sold"}>Sold</MenuItem>
																	<MenuItem value={"Scrap"}>Scrap</MenuItem>
																	<MenuItem value={"Returned to Vendor"}>Returned to Vendor</MenuItem>

																</Select>
															</FormControl>
														</td>
													}

													{/* Status */}
													{(!editmode && colstate.Status) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Status}
														</td>
													}

													
													{/* Row */}
													{(editmode && colstate.Row) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Row'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px' }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Row')}
																onKeyUp={(event) => onChangeValue(event, index, 'Row')}
																defaultValue={row.Row} />
														</td>
													}

													{(!editmode && colstate.Row) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Row}
														</td>
													}

													{/* Location */}
													{(editmode && colstate.Location) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Location'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px' }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Location')}
																onKeyUp={(event) => onChangeValue(event, index, 'Location')}
																defaultValue={row.Location} />
														</td>
													}

													{(!editmode && colstate.Location) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Location}
														</td>
													}



													{/* Intune Status */}
													{/* //	0: Unknown
																//	1: Confirmed Clear (probably not available without evidence, this is a placeholder in case we need to use it one day)
																//	2: Likely Enabled
																//	3: Confirmed Enabled
																//	4: Resolved Automatically *(When inventory item is checked out to an order and IntuneStatus=3)
																//     This option should not be available in front end.
																//	5: Resolved by Vendor *(Editable individually or bulk by purchasing agent)
																//	6: Resolved Despite Vendor *(When inventory item IntuneStatus=5,
																//     then iPXE set Confirmed Enabled, Intune is marked resolved despite vendor statement of removal.)
																//	   Also send risk record for when this happens.
																//     This option should not be available in front end.
																//	7: Unable to Resolve - Vendor could not resolve. */}

													{(colstate.IntuneStatus) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.IntuneStatus === 0) &&
																<>Unknown</>
															}
															{(row.IntuneStatus === 1) &&
																<>Confirmed Clear</>
															}
															{(row.IntuneStatus === 2) &&
																<>Likely Enabled</>
															}
															{(row.IntuneStatus === 3) &&
																<>Confirmed Enabled</>
															}
															{(row.IntuneStatus === 4) &&
																<>Resolved Automatically</>
															}
															{(row.IntuneStatus === 5) &&
																<>Resolved by Vendor</>
															}
															{(row.IntuneStatus === 6) &&
																<>Resolved Despite Vendor</>
															}
															{(row.IntuneStatus === 7) &&
																<>Unable to Resolve</>
															}
														</td>
													}

													{/* Intune Status Date Time */}
													{(colstate.IntuneStatusDateTime) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.IntuneStatusDateTime) &&
																<>
																	<Moment element='span' format="YYYY-MM-DD h:mmA">{row.IntuneStatusDateTime}</Moment>
																</>
															}
														</td>
													}

													{/* Intune Reported */}
													{(colstate.IntuneReported) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.IntuneReported === 1 ? "Yes" : "No"}
														</td>
													}

													{/* Intune Reported Date Time */}
													{(colstate.IntuneReportedDateTime) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.IntuneReportedDateTime) &&
																<>
																	<Moment element='span' format="YYYY-MM-DD h:mmA">{row.IntuneReportedDateTime}</Moment>
																</>
															}
														</td>
													}


													{/* PanGP Status */}

													{(colstate.PanGPStatus) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.PanGPStatus === 0) &&
																<>Unknown</>
															}
															{(row.PanGPStatus === 1) &&
																<>Confirmed Clear</>
															}
															{(row.PanGPStatus === 2) &&
																<>Likely Enabled</>
															}
															{(row.PanGPStatus === 3) &&
																<>Confirmed Enabled</>
															}
															{(row.PanGPStatus === 4) &&
																<>Resolved Automatically</>
															}
															{(row.PanGPStatus === 5) &&
																<>Resolved by Vendor</>
															}
															{(row.PanGPStatus === 6) &&
																<>Resolved Despite Vendor</>
															}
															{(row.PanGPStatus === 7) &&
																<>Unable to Resolve</>
															}
														</td>
													}

													{/* PanGP Status Date Time */}
													{(colstate.PanGPStatusDateTime) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.PanGPStatusDateTime) &&
																<>
																	<Moment element='span' format="YYYY-MM-DD h:mmA">{row.PanGPStatusDateTime}</Moment>
																</>
															}
														</td>
													}

													{/* PanGP Reported */}
													{(colstate.PanGPReported) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.PanGPReported === 1 ? "Yes" : "No"}
														</td>
													}

													{/* PanGP Reported Date Time */}
													{(colstate.PanGPReportedDateTime) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.PanGPReportedDateTime) &&
																<>
																	<Moment element='span' format="YYYY-MM-DD h:mmA">{row.PanGPReportedDateTime}</Moment>
																</>
															}
														</td>
													}



													{/* AbsPersistence Status */}
													{/* //	0: Unknown
																//	1: Confirmed Clear (probably not available without evidence, this is a placeholder in case we need to use it one day)
																//	2: Likely Enabled
																//	3: Confirmed Enabled
																//	4: Resolved Automatically *(When inventory item is checked out to an order and AbsPersistenceStatus=3)
																//     This option should not be available in front end.
																//	5: Resolved by Vendor *(Editable individually or bulk by purchasing agent)
																//	6: Resolved Despite Vendor *(When inventory item AbsPersistenceStatus=5,
																//     then iPXE set Confirmed Enabled, AbsPersistence is marked resolved despite vendor statement of removal.)
																//	   Also send risk record for when this happens.
																//     This option should not be available in front end.
																//	7: Unable to Resolve - Vendor could not resolve. */}

{(colstate.AbsPersistenceStatus) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.AbsPersistenceStatus === 0) &&
																<>Unknown</>
															}
															{(row.AbsPersistenceStatus === 1) &&
																<>Confirmed Clear</>
															}
															{(row.AbsPersistenceStatus === 2) &&
																<>Likely Enabled</>
															}
															{(row.AbsPersistenceStatus === 3) &&
																<>Confirmed Enabled</>
															}
															{(row.AbsPersistenceStatus === 4) &&
																<>Resolved Automatically</>
															}
															{(row.AbsPersistenceStatus === 5) &&
																<>Resolved by Vendor</>
															}
															{(row.AbsPersistenceStatus === 6) &&
																<>Resolved Despite Vendor</>
															}
															{(row.AbsPersistenceStatus === 7) &&
																<>Unable to Resolve</>
															}
														</td>
													}

													{/* AbsPersistence Status Date Time */}
													{(colstate.AbsPersistenceStatusDateTime) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.AbsPersistenceStatusDateTime) &&
																<>
																	<Moment element='span' format="YYYY-MM-DD h:mmA">{row.AbsPersistenceStatusDateTime}</Moment>
																</>
															}
														</td>
													}

													{/* AbsPersistence Reported */}
													{(colstate.AbsPersistenceReported) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.AbsPersistenceReported === 1 ? "Yes" : "No"}
														</td>
													}

													{/* AbsPersistence Reported Date Time */}
													{(colstate.AbsPersistenceReportedDateTime) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.AbsPersistenceReportedDateTime) &&
																<>
																	<Moment element='span' format="YYYY-MM-DD h:mmA">{row.AbsPersistenceReportedDateTime}</Moment>
																</>
															}
														</td>
													}



												</tr>
												{/* Try: conditional for any render whatsoever! */}
												{(row.ExpandRow === true) &&
													<tr>
														<td colSpan="100%">
															<div style={{ margin: "25px" }}>
																Expanded column container!
															</div>
														</td>
													</tr>
												}
											</React.Fragment>

										)
									}
									)
								}
								{(localstate.griditems.length === 0) &&
									<tr className="flexgridrow"><td colSpan="100%"
										style={{ padding: '12px', fontSize: "18px" }}>No Results</td></tr>
								}
							</tbody>
						}
						{(localstate.dbreload) &&
							<tbody>
								<tr>
									<td colSpan="100%">
										<div style={{ padding: "20px", textAlign: "center", margin: "auto" }}>
											<CircularProgress />
										</div>
									</td>
								</tr>
							</tbody>
						}
					</table>
				</div>

				{(localstate.totalitems > localstate.rowsperpage) &&
					<FlexTablePagination
						localstate={localstate}
						classes={classes}
						editmode={editmode}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				}

				<InventoryToolbar state={state} UpdateState={UpdateState} />
			</div>
		</LocalizationProvider>

	);
}

export default InventoryManager;