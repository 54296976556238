//User Table

/* ##########################  Configuration Sections  ########################## */	
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions


/* ##########################  Notes on Configuration  ########################## */	
// Edit Mode: Controls if we render inputs on the grid (renders take longer). Edit mode button disabled if more than 100 (configurable on button) items.

//Warnings like "Each child in a list should have a unique key prop": Check that your table row IDs match: row.ID might need to be row.InternalID or row.InternalOrderID, etc.


//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import {useHistory} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//Redux Features
import { useDispatch } from 'react-redux';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

//Datetime formatting
import Moment from 'react-moment';

//MaterialUI
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

//Icons
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PreviewIcon from '@mui/icons-material/Preview';

//Datetime Pickers
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';



/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:11800,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find Duplicate Example:
//This will short-circuit once some() finds a truthy value.
var values = [
	{ name: 'someName1' },
	{ name: 'someName2' },
	{ name: 'someName4' },
	{ name: 'someName1' }
];

var valueArr = values.map(function (item) { return item.name });
var isDuplicate = valueArr.some(function (item, idx) {
	return valueArr.indexOf(item) !== idx
});
//console.log(isDuplicate);

//Simple Find Duplicates (simple array of values).
const input = [1,1,2,3,3];
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function(acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	  }, []);
	return results;
}
const dupearray = GetDupeArray(input);
//console.log("Duplicates2: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}


//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};



const UserTable = (props) => {
	document.title="User Management";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Admin Settings"));
	dispatch(setCurrentMenuItem("/users"));
	let history = useHistory();
	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");

	const rowRefs = useRef([]);
	const btnSave = useRef();

	/* ##########################  UseState Variables  ########################## */ 
	const [state, setState] = useState({
		dbreload:true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection:true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems:[],		//Empty object
		defaultvalues:[],   //Default values: Useful for initial render.
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws an error.
		order:"asc",
		orderby:"firstname",
		selectedcount:0,
		rowsperpage:25,
		selectedindexes:[],
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		searchoptions:{
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs:{
				//Reserved for basic searchpairs. Possible injection of search parameter 'nameparameter' here.
				//Modes: like, left, right, strict, not
				searchpair1:{type:"firstname", value: "", mode:"like", uuid:uuidv4()},
				searchpair2:{type:"lastname", value: "", mode:"like", uuid:uuidv4()},
				searchpair3:{type:"username", value: "", mode:"left", uuid:uuidv4()},
				searchpair4:{type:"role", value: "", mode:"right", uuid:uuidv4()},
				searchpair5:{type:"", value: "", mode:"like", uuid:uuidv4()},
				searchpair6:{type:"", value: "", mode:"like", uuid:uuidv4()},
				//Reserved for AutoComplete searchpairs.
				searchpair7:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair8:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair9:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair10:{type:"", value: "", mode:"strict", uuid:uuidv4()},
			},
			nestedrelationships:{
				
			},
			itemlist:""
		}
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Reusable test to be used with useState bool variables
	//Invalid for none selected or pending changes
	//Bulk Edit Selected
	//Export Selected
	//Print Selected
	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length===0){
			//Lock in potential localstate.pendingsaves
			if (localstate.pendingsaves){
				UpdateState(localstate);
			}
			errors.NewError({errmsg:"No items selected.", errshow:true, errtimeout: 5, errtype:'neutral'});
		} else if (localstate.pendingsaves) {
			errors.NewError({errmsg:"One or more items have a pending save.", errshow:true, errtimeout: 5, errtype:'warning'});
		} else {
			//If all tests pass, use callback
			fnCallback(value);
		}
	}

	//Edit Mode
	const [editmode, setEditMode] = useState(false);
	const EditMode = () => {
		setEditMode(!editmode);
	}


	/* ##########################  Column States - ColStates  ########################## */	 
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		firstname:true,
		lastname:true,
		username:true,
		role:true,
		email:true,
		disabled:true,
		lastlogin:true,
		created_at:true,
		id: true
	});


	

	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);

	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		colstate[key] = ! colstate[key];
		CloseColumnMenu();
	}

	

	/* ##########################  Selected Rows  ########################## */
	const SelectRow = (index) => {
		//alert(index);
		if (localstate.selectedindexes.indexOf(index)===-1){
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Requires Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length){
				UpdateState(localstate);			
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex,1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length+1)){
				UpdateState(localstate);			
			}
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i=0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
	};



	/* ##########################  Search Options  ########################## */

	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	//Pre-set Key
	//Deprecate? Move to AutoComplete?
	const onChangeSearch = debounce(function(searchtype, searchvalue, itembool, searchpair) {
		//If an item is selected, then deselected, itembool is still true, but the searchvalue becomes null.
		//If item selected from list (itembool), grab it's key's value instead 'name'
		if (itembool) {
			if (searchvalue !== null){
				searchvalue = searchvalue.name;
			} else {
				searchvalue="";
			}
		}
		//New API:
		console.log(searchtype);
		console.log(searchvalue);
		switch (searchpair) {
			case "searchpair1":
				localstate.searchoptions.searchpairs.searchpair1.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair1.value = searchvalue;
				break;
			case "searchpair2":
				localstate.searchoptions.searchpairs.searchpair2.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair2.value = searchvalue;
				break;
			case "searchpair3":
				localstate.searchoptions.searchpairs.searchpair3.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair3.value = searchvalue;
				break;
			case "searchpair4":
				localstate.searchoptions.searchpairs.searchpair4.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair4.value = searchvalue;
				break;
			default:
				break;
		}
		localstate.dbreload=true;
		UpdateState(localstate);
	}, 800);


	//API Build-out: 
	//	Old: Types [searchtype, sub1type, sub2type] , Search [search, sub1search, sub2search]
	//	New: Types [searchtype1, searchtype2, searchtype3], Search [search1, search2, search3]
	//Set Search Key:
	var mode="";
	const onChangeSearchType = (searchtype, searchnumber) => {
		//Blank the value:
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = "";

		//Search Mode: Each search type may have a different search mode
		//left, right, like, strict, not
		//Default Mode: LIKE
		mode = "like";
		//Provision for automatically switching search mode
		if (searchtype === "Location") {
			mode = "strict";
		}

		//Not Conditionals: Convert NotName to Name in BasicTableController
		if (searchtype === "NotName") {
			mode = "not";
		}

		//Date Searches - Simple string input and validation
		if (searchtype === "DateAfter") {
			mode = "dateafter";
		}
		if (searchtype === "DateBefore") {
			mode = "datebefore";
		}

		//DateTime Searches
		if (searchtype === "DateTimeAfter") {
			mode = "datetimeafter";
		}
		if (searchtype === "DateTimeBefore") {
			mode = "datetimebefore";
		}

		//Init date time:
		if (searchtype === "DateTimeBefore" || searchtype === "DateTimeAfter") {
			localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = new Date;
		}

		if (searchtype === "Cost >") {
			mode = "greaterthan";
		}
		if (searchtype === "Cost <") {
			mode = "lessthan";
		}

		localstate.searchoptions.searchpairs["searchpair" + searchnumber].type = searchtype;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].mode = mode;
		//Provision to add columns if selected for search.
		if (searchtype === "Name") {
			colstate["Name"] = true;
			setColumnMenu(null);
		}
		UpdateState(localstate);
	}


	const [datetimevalue, setDateTimeValue] = useState(new Date);
	//Set Search Value:
	const onChangeSearchValue = debounce(function(searchvalue, searchnumber) {
		

		//Provision to warn user about a bad datetime format:
		//On blank datetime, proceed to load data

		if ((localstate.searchoptions.searchpairs["searchpair"+searchnumber].type==="DateAfter" || localstate.searchoptions.searchpairs["searchpair"+searchnumber].type==="DateBefore") && searchvalue!==""){
			var count = (searchvalue.match(/-/g) || []).length;
			if (searchvalue.length===10 && count===2){
				let isValidDate = Date.parse(searchvalue);
				if (isNaN(isValidDate)) {
					errors.NewError({ errmsg: "Invalid date.", errshow: true, errtimeout: 5, errtype: "warning" });
				} else {
					localstate.page=0;
					localstate.dbreload = true;
					UpdateState(localstate);
				}
			} else {
				errors.NewError({ errmsg: "Use YYYY-MM-DD format for dates.", errshow: true, errtimeout: 5, errtype: "ok" });
			}
		} 
		
		localstate.searchoptions.searchpairs["searchpair"+searchnumber].value = searchvalue;
		localstate.page=0;
		localstate.dbreload = true;
		UpdateState(localstate);
	},1200);

	//Key-Value Inputs
	const [searchinputs, setSearchInputs] = useState({
		show1:true,
		show2:true,
		show3:true,
		show4:true,
		show5:false,
		show6:false,
		lastsearch:4
	});


	const AddSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show"+(newsearchinputs.lastsearch+1)]=true;
		newsearchinputs.lastsearch++;
		setSearchInputs(newsearchinputs);
	}

	const RemoveSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show"+(newsearchinputs.lastsearch)]=false;
		newsearchinputs.lastsearch--;
		setSearchInputs(newsearchinputs);
	}

	/* ##########################  Auto Complete  ########################## */
	// Similar to regular searchpairs, but loads results from DB - needs stateful.

	//Helpers - Blank icons for pre-set keys
	const BlankIcon = () => (<ArrowDropDownIcon style={{display:"none"}} />);

	const [autocompletesearchtype1, setKey1] = useState("Name");
	const [openoptions1, openOptions1] = React.useState(false);
	const [options1, setOptions1] = React.useState([]);
	const [loadingoptions1, setLoadingOptions1] = useState(false);
	const InitOptions1 = () => {
		if (options1.length===0){
			//Load (up to limit) options
			AutoCompleteSearch1("");
		}
		openOptions1(true);
	}

	const onChangeOption1 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair7.type=autocompletesearchtype1;
		localstate.searchoptions.searchpairs.searchpair7.value=newvalue[autocompletesearchtype1];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch1 = debounce(function(searchvalue){
		setLoadingOptions1(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype1,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/basictable/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions1(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions1(false);
		});
	},600);


	const [autocompletesearchtype2, setKey2] = useState("Name");
	const [openoptions2, openOptions2] = React.useState(false);
	const [options2, setOptions2] = React.useState([]);
	const [loadingoptions2, setLoadingOptions2] = useState(false);
	const InitOptions2 = () => {
		if (options2.length===0){
			//Load (up to limit) options
			AutoCompleteSearch2("");
		}
		openOptions2(true);
	}

	const onChangeOption2 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair8.type=autocompletesearchtype2;
		localstate.searchoptions.searchpairs.searchpair8.value=newvalue[autocompletesearchtype2];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch2 = debounce(function(searchvalue){
		setLoadingOptions2(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype2,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/basictable/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions2(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions2(false);
		});
	},600);


	const [autocompletesearchtype3, setKey3] = useState("Name");
	const [openoptions3, openOptions3] = React.useState(false);
	const [options3, setOptions3] = React.useState([]);
	const [loadingoptions3, setLoadingOptions3] = useState(false);
	const InitOptions3 = () => {
		if (options3.length===0){
			//Load (up to limit) options
			AutoCompleteSearch3("");
		}
		openOptions3(true);
	}

	const onChangeOption3 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair9.type=autocompletesearchtype3;
		localstate.searchoptions.searchpairs.searchpair9.value=newvalue[autocompletesearchtype3];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch3 = debounce(function(searchvalue){
		setLoadingOptions3(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype3,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/basictable/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions3(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions3(false);
		});
	},600);


	const [autocompletesearchtype4, setKey4] = useState("Name");
	const [openoptions4, openOptions4] = React.useState(false);
	const [options4, setOptions4] = React.useState([]);
	const [loadingoptions4, setLoadingOptions4] = useState(false);
	const InitOptions4 = () => {
		if (options4.length===0){
			//Load (up to limit) options
			AutoCompleteSearch4("");
		}
		openOptions4(true);
	}

	const onChangeOption4 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair10.type=autocompletesearchtype4;
		localstate.searchoptions.searchpairs.searchpair10.value=newvalue[autocompletesearchtype4];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch4 = debounce(function(searchvalue){
		setLoadingOptions4(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype4,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/basictable/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions4(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions4(false);
		});
	},600);


	/* ##########################  Auto Complete - Preset Keys, No usage of searchpairs from here on out. ########################## */
	const [openoptions5, openOptions5] = React.useState(false);
	const [options5, setOptions5] = React.useState([]);
	const [loadingoptions5, setLoadingOptions5] = useState(false);
	const InitOptions5 = () => {
		if (options5.length===0){
			//Load (up to limit) options
			AutoCompleteSearch5("");
		}
		openOptions5(true);
	}
	const onChangeOption5 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch5 = debounce(function(searchvalue){
		setLoadingOptions5(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/basictable/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions5(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions5(false);
		});
	},600);


	const [openoptions6, openOptions6] = React.useState(false);
	const [options6, setOptions6] = React.useState([]);
	const [loadingoptions6, setLoadingOptions6] = useState(false);
	const InitOptions6 = () => {
		if (options6.length===0){
			//Load (up to limit) options
			AutoCompleteSearch6("");
		}
		openOptions6(true);
	}
	const onChangeOption6 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch6 = debounce(function(searchvalue){
		setLoadingOptions6(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/basictable/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions6(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 6, errtype:"warning"})
			}
			setLoadingOptions6(false);
		});
	},600);


	const [openoptions7, openOptions7] = React.useState(false);
	const [options7, setOptions7] = React.useState([]);
	const [loadingoptions7, setLoadingOptions7] = useState(false);
	const InitOptions7 = () => {
		if (options7.length===0){
			//Load (up to limit) options
			AutoCompleteSearch7("");
		}
		openOptions7(true);
	}
	const onChangeOption7 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch7 = debounce(function(searchvalue){
		setLoadingOptions7(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/basictable/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions7(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 7, errtype:"warning"})
			}
			setLoadingOptions7(false);
		});
	},600);


	const [openoptions8, openOptions8] = React.useState(false);
	const [options8, setOptions8] = React.useState([]);
	const [loadingoptions8, setLoadingOptions8] = useState(false);
	const InitOptions8 = () => {
		if (options8.length===0){
			//Load (up to limit) options
			AutoCompleteSearch8("");
		}
		openOptions8(true);
	}
	const onChangeOption8 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch8 = debounce(function(searchvalue){
		setLoadingOptions8(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/basictable/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions8(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 8, errtype:"warning"})
			}
			setLoadingOptions8(false);
		});
	},600);





	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		//This evaluates if we're requesting the same property and if it's asc.
		const isAsc = localstate.orderby === property && localstate.order === "asc";	
		//We need to evaluate if we should start back at page 1:
		if (localstate.orderby !== property){
			localstate.page = 0;
		}
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		localstate.pendingsaves = false;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);		
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page=0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems(){  
		if (localstate.clearselection){
			localstate.selectedindexes = [];
			localstate.selectedcount=0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection=true;
		}
		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:localstate.searchoptions.searchpairs,
				nestedrelationships:localstate.searchoptions.nestedrelationships
			}
		};

		if (itemlistsearch.length>0){
			//Serials exist in serial list.
			postdata.searchoptions.itemlist = itemlistsearch;
		}

		axios.post(dbendpoint+"/users/getusers?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var resultdata = res.data.pagedata.data;
					for (var i =0; i<resultdata.length; i++){
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey=i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						if (localstate.selectedindexes.includes(i)){
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}

						//Set Defaults for selectables:
						resultdata[i].SomeSelectableDefault = resultdata[i].SomeSelectable;
					}
					localstate.griditems = resultdata;
					//Hold items for default values
					localstate.defaultvalues = Object.assign({}, resultdata);
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					localstate.griditems=[];
					UpdateState(localstate);
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}


	/* ############### RESIZER ####################*/
	const createResizableColumn = function (col, resizer) {
		// Track the current position of mouse
		let x = 0;
		let w = 0;
	
		const mouseDownHandler = function (e) {
			// Get the current mouse position
			x = e.clientX;
			// Calculate the current width of column
			const styles = window.getComputedStyle(col);
			w = parseInt(styles.width, 10);
			// Attach listeners for document's events
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};
	
		const mouseMoveHandler = function (e) {
			// Determine how far the mouse has been moved
			const dx = e.clientX - x;
			// Update the width of column
			col.style.width = `${w + dx}px`;
		};
	
		// When user releases the mouse, remove the existing event listeners
		const mouseUpHandler = function () {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};
		resizer.addEventListener('mousedown', mouseDownHandler);
	};

	const InitColumnResizers = () => {
		//Runs after rerender
		// Query the table
		const table = document.getElementById('resizeMe');
		// Query thead:
		const thead = document.querySelector('thead');
		const cols = thead.querySelectorAll('td');
		// Loop over them
		[].forEach.call(cols, function (col) {
			// Create a resizer element
			const resizer = document.createElement('div');
			resizer.classList.add([classes.resizer]);
			// Set the height
			resizer.style.height = `${table.offsetHeight-2}px`;
			// Add a resizer element to the column
			col.appendChild(resizer);
			createResizableColumn(col, resizer);
		});
	}

	/* ############### END OF RESIZER ####################*/


	useEffect(() => {
		document.title="Users";
		if (state.dbreload){
			//Avoid duplicate loads.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
			//Boostrap a resizer for the columns:
			InitColumnResizers();
		}
	},);


	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	const AddRow = () => {
		localstate.griditems.unshift({
			PendingItem:true,
			ID:uuidv4(),
			Name:"",
			Cost:0.00,
			Price:0.00,
			Margin:0.00,
			SomeBoolean:0,
			SomeSelectable:"",
			NewCheckbox:false
		});
		//All selected indexes move up by 1.
		for (var i=0; i<localstate.selectedindexes.length; i++){
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		var updatearray = [];
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				updatearray.push(localstate.griditems[i]);
			}
		}
		if (localstate.pendingsaves){
			if (updatearray.length>0){
				i=0;
				var limit = updatearray.length-1;
				var updateitems = setInterval(function(){
					var item = updatearray[i];
					//Do work here, API call.
					const postdata = {					
						item:item
					};
					axios.post(dbendpoint+"/users/update", postdata, defaultpostoptions).then(res => {
						//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
						if (res.status===200){
							//If ValidateUser() fails to verify user, it sends back 'login' error. 
							if (res.data.Status==="login"){
								//Not logged in. Reload page causes redirect to /login
								window.location.reload(false);
							}
							if (res.data.Status==="Success"){
								//Success response also includes the item!
								//If we sent new rows, we'll need to reference the old ID.
								var itemindex=0;
								if (res.data.OldID){
									itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.OldID);
									localstate.griditems[itemindex].unsaved = false;
									rowRefs.current[itemindex+'SaveStatus'].classList.remove(classes.unsavedhighlight);
									//Set New ID
									localstate.griditems[itemindex].ID = res.data.item.ID;
								} else {
									itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.item.ID);
									localstate.griditems[itemindex].unsaved = false;
									//Refs allow us to update the grid live!
									rowRefs.current[itemindex+'SaveStatus'].classList.remove(classes.unsavedhighlight);
								}
							}
							if (res.data.Status==="Failure"){
								//Failure error
								errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"warning"})
							}
						} else {
							//Non-200 message from server.
							errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
						}
					});
					//If we have completed all items, clear this interval and update state.
					if (i===limit){
						localstate.pendingsaves = false;
						clearInterval(updateitems);
						UpdateState(localstate);
					}
					i++;
				}, 200);
			}
			btnSave.current.style.color="rgba(0, 0, 0, 0.87)";
			btnSave.current.style.backgroundColor="#DFDFDF";
		} else {
			alert("Nothing to save!");
		}
	}


	{/* BULK EDIT DRAWER STATE AND FUNCTIONS */}
	const [showbulkeditdrawer, setShowBulkEditDrawer] = useState(false);
	const btnApplyBulkEdit = useRef();

	const CloseBulkEditDrawer = () =>{
		setShowBulkEditDrawer(false);
		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}

	//Bulk edits may incur many different types of changes.
	//Using state variables, we can allow the view to change between them.
	const [changetype, setChangeType] = React.useState('');



	//Try to replace all this?
	//In render: {(changetype==='text')}, etc. NO. We need changetype for when we go back to the DB for the update.


	//// CHANGE TYPES - Controls change type and input viewables
	const [changeistext, setChangeIsText] = React.useState(false);
	const [changeisfloat, setChangeIsFloat] = React.useState(false);
	const [changeissomeselectable, setChangeIsSomeSelectable] = React.useState(false);
	const [changeisdbitem, setChangeIsDBItem] = React.useState(false);
	const [changeisdate, setChangeIsDate] = React.useState(false);
	const [changeisbool, setChangeIsBool] = React.useState(false);


	const [changeisint, setChangeIsInt] = React.useState(false);
	const [changeisstatus, setChangeIsStatus] = React.useState(false);
	



	//// VALUES - Controls input values and value validation
	const changevalue = React.createRef();
	//These 2 refs are used to update a number input (RestrictNumber() will limit what can be put into input box)
	const changevaluefloat = React.createRef();
	const changevalueint = React.createRef();
	const changevalueselectable = React.createRef();
	const [dbitemselection, setDBItemSelection] = React.useState();


	const handleChangeEditType = (event) => {
		//Reset change value
		changevalue.current="";
		//Reset Inputs:
		setChangeIsText(false);
		setChangeIsFloat(false);
		setChangeIsSomeSelectable(false);
		setChangeIsDBItem(false);
		setChangeIsBool(false);

		//Examples
		setChangeIsInt(false);
		setChangeIsStatus(false);
		setChangeIsDate(false);

		//Reset Btn
		btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
		btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);

		setChangeType(event.target.value);

		if (
			//Text values here.
			event.target.value==="Name"
		) {
			setChangeIsText(true);
		}

		if (event.target.value==="Cost" || event.target.value==="Price"){
			setChangeIsFloat(true);
		}

		if (event.target.value==="SomeSelectable"){
			setChangeIsSomeSelectable(true);
		}

		if (event.target.value==="NewCheckbox"){
			setChangeIsBool(true);
		}

		if (event.target.value==="DBItem"){
			setChangeIsDBItem(true);
		}

		if (event.target.value==="Date"){
			setChangeIsDate(true);
		}
		

		//Unused Examples:
		if (event.target.value==="LotID" || event.target.value==="SkidNumber"){
			setChangeIsInt(true);
		}

		if (event.target.value==="Status"){
			setChangeIsStatus(true);
		}		

		if (event.target.value==="LotID" || event.target.value==="SkidNumber"){
			setChangeIsInt(true);
		}

	};

	const handleChangeBulkValue = (event) => {
		//Currency
		if (changetype==="Cost" || changetype==="Price"){
			changevalue.current = RestrictNumber(event.target.value, changevalue.current);
			//Updates view:
			changevaluefloat.current.value = changevalue.current;
		//Integers	
		} else if (changetype==="LotID" || changetype==="SkidNumber") {
			changevalue.current = RestrictNumberInteger(event.target.value, changevalue.current);
			//Updates view:
			changevalueint.current.value = changevalue.current;
		
		} else if (changetype==="NewCheckbox"){
			// placeholder
			changevalue.current = event.target.value;
		} else {
			//Raw Values
			changevalue.current = event.target.value;
		}


		//Verify changes can be saved in current form.
		//Avoid certain items being saved as a blank string:
		if (changetype==="Location" && event.target.value===""){
			btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
			btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);
			errors.NewError({errmsg:"Value cannot be blank.", errshow:true, errtimeout: 3, errtype:"neutral"});

		//Allow bulk change to be saved
		} else {
			btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
			btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	const handleChangeDBItem = (event, item) => {
		setDBItemSelection(item);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeSomeSelectable = (event) => {
		changevalue.current = event.target.value;
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}


	//Search for DB Item (autocomplete) - Attempt to make this reusable! Setup endpoints as (env db dbendpoint/table/getautocomplete), see also ApplyBulkEdit
	const [dbitemkey, setDBItemKey] = useState("Name");
	const [opendbitemoptions, openDBItemOptions] = React.useState(false);
	const [dbitemoptions, setDBItemOptions] = React.useState([]);
	const [loadingdbitemoptions, setLoadingDBItemOptions] = useState(false);
	const InitDBItemOptions = () => {
		if (dbitemoptions.length===0){
			//Load (up to limit) options
			DBItemSearch("");
		}
		openDBItemOptions(true);
	}

	const DBItemSearch = debounce(function(searchvalue){
		setLoadingDBItemOptions(true);
		const postdata = {					
			key:dbitemkey,
			search:searchvalue,
			limit:20
		};
		//Provisions to reuse this search:
		var table = "";
		if (changetype==="Product"){
			table="products";
		}
		//Error such as "TypeError: Cannot read property 'filter' of undefined" means we aren't handling the response correctly or the response is malformed.
		axios.post(dbendpoint+"/"+table+"/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				} else {
					setDBItemOptions(res.data.items);
					console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingDBItemOptions(false);
		});
	},600);


	const ApplyBulkEdit = () => {
		var items = [];
		for (var i=0; i<localstate.selectedindexes.length; i++){
			items.push(localstate.griditems[localstate.selectedindexes[i]]);
		}

		//Simple key-value pairs:
		if (changetype==="Name" ||
			changetype==="Price" ||
			changetype==="Cost" ||
			changetype==="SomeSelectable" ||
			changetype==="Date" ||
			changetype==="NewCheckbox"
		){
			for (i=0; i<items.length; i++){
				items[i][changetype] = changevalue.current;
			}
		}

		//Database Item Selection (potential multiple values to change)
		if (changetype==="DBItem"){
			//Place your specific column values here:
			for (i=0; i<items.length; i++){
				items[i]["Name"] = dbitemselection.Name;
				//Example: items[i]["ProductID"] = dbitemselection.ProductID;
			}
		}

		//Reassign other item values if needed before pushing them to postdata.items.
		//Example provision to change Margin if price or cost change
		if (changetype==="Price"){
			for (i=0; i<items.length; i++){
				//items[i].Price = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		if (changetype==="Cost"){
			for (i=0; i<items.length; i++){
				//items[i].Cost = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		var postdata={
			items:items
		}

		

		axios.post(dbendpoint+"/basictable/bulkedititems", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==='login'){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status==='Success'){
					//console.log(res.data);
					if (res.data['SuccessCount']>0){
						errors.NewError({
							errmsg:"Items Changed: "+res.data['SuccessCount']+", Failures: "+res.data['FailCount'],
							errshow:true,
							errtimeout: 5,
							errtype:'ok'})
						}
					}
					
				if (res.data.Status==='Failure'){
					//Failure error
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:'warning'})
			}
		});
	}



	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () =>{
		var deleteitemsarray =[];
		//Reflect items to user for confimation.
		for (var i=0; i<state.selectedindexes.length; i++){
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i=0; i<localstate.selectedindexes.length; i++){
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")){
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {				
					item:localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint+"/basictable/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;	
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status===200){
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status==="login"){
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status==="Success"){
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID){
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
							} else {
								errors.NewError({errmsg:"Could not delete one or more items.", errshow:true, errtimeout: 8, errtype:"warning"})
							}
						}
						if (res.data.Status==="Failure"){
							//Failure error
							errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
						}
					} else {
						//Non-200 message from server.
						errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}


	/* ##########################  Cell Interaction  ########################## */


	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	const taborder = ["Name", "Cost", "Price"];


	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown"){
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp"){
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length-1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
		//Handle Right Arrow - Special cases:
		/*
		if (event.key === "ArrowRight"){
			event.preventDefault();
			//Same Process as Tab
			var tabindex = taborder.indexOf(column);
			for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
				if (i === taborder.length) {
					i = -1;
					if (rowRefs.current[(index + 1) + column]) {
						index = index + 1;
					} else {
						index = 0;
					}
				} else {
					if (taborder[i]) {
						if (colstate[taborder[i]]) {
							rowRefs.current[index + taborder[i]].select();
							break;
						}
					} else {
						i = -1;
					}
				}
			}
		}
		//Handle Left Arrow
		if (event.key === "ArrowLeft"){
			event.preventDefault();
			var tabindex = taborder.indexOf(column);
			var i;
			var rowincrement=0;
			for (i=(tabindex-1); i>-2; i--){
				if (i===-1){
					rowincrement=-1;
					i=taborder.length; //Go to end of tab order
				} else {
					if (colstate[taborder[i]]) {
						break;
					}
				}
			}
			//If tabindex first and we're in the first row, go to the last element on last row.
			if (tabindex===0 && index===0){
				var lastindex = localstate.griditems.length-1;
				rowRefs.current[lastindex + taborder[i]].select();
			} else {
				rowRefs.current[(index+rowincrement) + taborder[i]].select();
			}
		}
		*/
	}

	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) =>{
		console.log("Restrict Number:"+newvalue);
		//45.
		var len = newvalue.length;
		//len=3
		var index = newvalue.indexOf('.');
		//index=2
		if (index > 0) {
			//true
			var decimalchars = (len-1) - index;
			//decimalchars = 3-1  - 2 = 0
			if (decimalchars>2){
				return oldvalue;
			}
		}
		return newvalue;
	}

	//Restrict Number to Integer - Don't accept any decimal.
	const RestrictNumberInteger = (newvalue, oldvalue) => {
		console.log(newvalue);
		if (newvalue <0 ){
			return 0;
		} else {
			return parseInt(newvalue);
		}
	}

	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value===""){
			rowRefs.current[index+column].value="0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		console.log("Event value:"+event.target.value);
		console.log("Raw:"+newvalue);
		if (event.key !== "Tab" && 
			event.key!=="ArrowDown" && 
			event.key!=="ArrowUp" && 
			event.key!=="ArrowLeft" && 
			event.key!=="ArrowRight" && 
			event.key!=="ShiftLeft" && 
			event.key!=="ShiftRight"
			){
			//Conditionals for Types:
			if (column==="Cost"){
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Cost changes, so does Margin
				rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
				//Backspace on a decimal should yield same value for old and new
				if (oldvalue.indexOf(".")>-1 && newvalue.indexOf(".")===-1){
					//No need to change front end, simply update griditem
					localstate.griditems[index][column] = newvalue;
				} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)){
					rowRefs.current[index+column].value=newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			if (column==="Price"){
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Price changes, so does Margin
				rowRefs.current[index+"Margin"].textContent = (newvalue - rowRefs.current[index+"Cost"].value).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
				//Backspace on a decimal should yield same value for old and new
				if (oldvalue.indexOf(".")>-1 && newvalue.indexOf(".")===-1){
					//No need to change front end, simply update griditem
					localstate.griditems[index][column] = newvalue;
				} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)){
					rowRefs.current[index+column].value=newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			//Provision for Booleans that require a re-render. Expensive!
			if (column==="SomeBoolean"){
				if (event.target.checked){
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}
				
			} else {
				//Selects already render the correct and unsaved result of selecting. (but right now they cause rerender via state.... possible change here.)
				if (column==="NewCheckbox") { //MaterialUI Checkbox
					if (event.target.checked){
						localstate.griditems[index][column] = 1;
					} else {
						localstate.griditems[index][column] = 0;
					}
				} else if (column==="SomeSelectable" || column==="Cost" || column==="Price") {
					//Avoids Refs! Required for numeric input and seletables. Component takes care of view while localstate has yet to update the DB
					localstate.griditems[index][column] = newvalue;
				} else {
					//Update Refs like usual.
					rowRefs.current[index+column].value=newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
				
			btnSave.current.style.color="white";
			btnSave.current.style.backgroundColor="#01579B";
		}
	}

	//Custom Function - Optional
	const onChangeCost = (event, index, column) => {
		if (event.key === "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+column]){
				rowRefs.current[(index+1)+column].focus();
			} else {
				rowRefs.current[("0"+column)].focus();
			}
		} else {
			//If Cost changes, so does Margin
			rowRefs.current[index+"Margin"].value = (rowRefs.current[index+"Price"].value - event.target.value).toFixed(2);
			//Update ref
			rowRefs.current[index+column].value=event.target.value;
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index][column] = event.target.value;
			console.log(localstate.griditems[index].Cost);
			localstate.pendingsaves = true;
			//Update Save button to show pending saves
			btnSave.current.style.color="white";
			btnSave.current.style.backgroundColor="#01579B";
		}
	}


	/* ##########################  Button Functions  ########################## */

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ResetSearches = () => {
		//Reset all:
		for (var i=1; i<7; i++){
			localstate.searchoptions.searchpairs["searchpair"+i].type = "";
			localstate.searchoptions.searchpairs["searchpair"+i].value = "";
			localstate.searchoptions.searchpairs["searchpair"+i].mode = "like";
			localstate.searchoptions.searchpairs["searchpair"+i].uuid = uuidv4();
		}
		//Set Defaults:
		setItemListSearch('');
		localstate.searchoptions.searchpairs.searchpair1.type = "Name";
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	const GoToItem = (id) => {
		history.push('/user/'+id);
		//console.log(id);
	}

	

	/* ##########################  TABLE COLUMN HEADERS  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		// firstname:true,
		// lastname:true,
		// username:true,
		// role:true,
		// email:true,
		// disabled:true,
		// lastlogin:true,
		// created_at:true
		//Be sure to adjust widths for cells as well.
		{ id: "firstname", numeric: false, label: "First", align:"left", allowsort:true, style:{}},
		{ id: "lastname", numeric: false, label: "Last", align:"left", allowsort:true, style:{}},
		{ id: "username", numeric: false, label: "UserName", align:"left", allowsort:true, style:{}},
		{ id: "role", numeric: false, label: "Role", align:"left", allowsort:true, style:{}},
		{ id: "email", numeric: false, label: "Email", align:"left", allowsort:true, style:{}},
		{ id: "disabled", numeric: false, label: "Disabled", align:"center", allowsort:true, style:{width:"50px"}},
		{ id: "lastlogin", numeric: false, label: "Last Login", align:"left", allowsort:true, style:{}},
		{ id: "created_at", numeric: false, label: "Creation Date", align:"left", allowsort:true, style:{}},
		{ id: "id", numeric: false, label: "User ID", align:"right", allowsort:true, style:{}},
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};
	
		return (
			<thead style={{display:"table-header-group"}}>
				<tr style={{border:"1px solid #CCC",
							backgroundColor:"#DDD"}}>
					{/* Disable Checkbox for this page.
						<td style={{width: "14px", padding:"none", display:"table-cell", padding:"2px 4px 2px 5px"}}>

							<Checkbox
								className={classes.gridcheckbox}
								disableRipple
								color="default"
								defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
								checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
								icon={<span className={classes.icon} />}
								onChange={onSelectAllClick}
							/>

						</td>
					*/}
					
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] && (
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* This is a conditional for headCell where the id is "Name". This allows us to put a spacer in here for the expand icon button */}
										{/* The expand button is optional, this can be removed if needed! */}
										{(headCell.id === "Name") &&
											<div style={{ width: "30px", display: "inline-block" }}></div>
										}
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
							</td>
						)
					)}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	{/* Utility Drawer state and functions */}
	const [showutilitydrawer, setShowUtilityDrawer] = useState(false);

	const CloseUtilityDrawer = () =>{
		setShowUtilityDrawer(false);
		//Reload DB?
		//localstate.dbreload = true;
		//Update State?
		//UpdateState(localstate);
	}

	{/* Item List - General purpose boilerplate */}
	const [showitemlist, setShowItemList] = useState(false);
	//We keep our original item list intact, use itemlistsearch for new DB reload
	const [itemlistsearch, setItemListSearch] = useState("");
	const [dupeitems, setDupeItems] = useState('');
	const CloseItemList = () =>{
		//Get unique items
		const result = localstate.searchoptions.itemlist.split(/\r?\n/).filter(element => element);
		const newitemlist = GetUniqueArray(result);
		setItemListSearch(newitemlist);
		setShowItemList(false);

		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}
	const onChangeItemList = (event) => {
		//Optional check for dupes:
		//Bust apart by line breaks!
		//Let's start sending arrays to PHP Laravel instead where possible since we're already
		//going to do work here before the API call.

		//If there is a line break at the end of the list (blank new line), you may end up with a falsy array element at the end.
		//Filter creates a new array based on passing a test function (true or false).
		//By passing a falsy array element (undefined), it won't return that element in the result array.
		//Split by Line Breaks, then filter.
		const result = event.target.value.split(/\r?\n/).filter(element => element);
		//Find Dupes, let the user know there are dupes.
		const dupes = GetDupeArray(result);
		if (dupes.length>0){
			setDupeItems(dupes);
			console.log(dupes);
		} else {
			setDupeItems('');
		}
		localstate.searchoptions.itemlist = event.target.value;
		//setItemList(event.target.value);
	}

	{/* Print Labels */}
	const PrintLabels = () =>{
		var itemids = [];
		//Grab all IDs
		for (var i=0; i<localstate.selectedindexes.length; i++){
			itemids.push(localstate.griditems[localstate.selectedindexes[i]]['ID']);
		}

		var params = {
			itemids:JSON.stringify(itemids),
			labeltype:'basiclabel',
			autoclose:false,
			seriallist:'',
			orderby:localstate.orderby,
			order:localstate.order
		};
	
		// Creating a form
		var form = document.createElement("form");
		form.setAttribute("method","post");
		form.setAttribute("action","../v3/labels");
		form.setAttribute("target", "NewLabel");
		for (var i in params) {
			if (params.hasOwnProperty(i)){
				// Creating the input
				var input = document.createElement('input');
				input.type='hidden';
				input.name=i;
				input.value=params[i];

				// Attach input to form
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		form.submit();
	}


	//Add User
	const AddUser = ()  => {
		//To Do: Create new user record - see if we can pass blank strings to the DB.
		//On success, open new user's edit page.

		axios.get(dbendpoint+"/users/newuser").then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					console.log("Success!"+res.data.userid);
					history.push('/user/'+res.data.userid);
				}
				if (res.data.Status==="Failure"){
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"warning"});
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}


	/* 
	                                                                                         
         _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
        _/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
       _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
      _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
     _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
                                                                                         
                                                                                         
 	*/


	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<div style={{padding:"8px", overflow:"auto", minWidth:"350px"}}>
				
				{/* Standard Page Header with right floated error message space */}
				<div style={{ height: "50px", paddingTop:"5px" }}>
					<div style={{ textAlign: "center" }}>
						<h2>Users</h2>
					</div>
					{(errors.currenterror.errshow) &&
						<div style={{ position: "relative", float: "right", bottom: "26px", height: "0px", fontSize: "12px" }}>
							<ErrorMessage />
						</div>
					}
				</div>

				{/* /* ##########################  Search Inputs  ########################## */}
				{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
				{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}

				{(!isPrintView) &&
					<div>

						{/* Key-Value Searches */}

						{/* Search Pair 1 */}
						{(searchinputs.show1) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
									<Select
										key={localstate.searchoptions.searchpairs.searchpair1.uuid}
										value={localstate.searchoptions.searchpairs.searchpair1.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 1)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"firstname"}>First</MenuItem>
										<MenuItem value={"lastname"}>Last</MenuItem>
										<MenuItem value={"username"}>Username</MenuItem>
										<MenuItem value={"email"}>Email</MenuItem>
										<MenuItem value={"role"}>Role</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair1.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair1.type!=="DateTimeAfter") &&
									<TextField id="search1" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair1.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair1.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 1)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair1.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair1.type==="DateTimeAfter") &&
									<DateTimePicker
										value={localstate.searchoptions.searchpairs.searchpair1.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 1)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchinput} 
											inputProps={{
												...params.inputProps,
												placeholder: "tt.mm.jjjj"
											}}
										 />}
									/>
								}
								{/* The 30px wide spacer for a close button can be optionally removed. The space might better be used for view vs the need for close buttons */}
								<div style={{ width: "30px" }}>&nbsp;</div>
							</div>
						}

						{/* Search Pair 2 */}
						{(searchinputs.show2) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair2.uuid}
										value={localstate.searchoptions.searchpairs.searchpair2.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 2)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"firstname"}>First</MenuItem>
										<MenuItem value={"lastname"}>Last</MenuItem>
										<MenuItem value={"username"}>Username</MenuItem>
										<MenuItem value={"email"}>Email</MenuItem>
										<MenuItem value={"role"}>Role</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair2.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair2.type!=="DateTimeAfter") &&
									<TextField id="search2" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair2.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair2.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 2)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair2.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair2.type==="DateTimeAfter") &&
									<DateTimePicker
										value={localstate.searchoptions.searchpairs.searchpair2.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 2)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchinput} 
											inputProps={{
												...params.inputProps,
												placeholder: ""
											}}
										 />}
									/>
								}
								<div style={{ width: "30px", float: "right" }}>
									{(searchinputs.show2 && !searchinputs.show3) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}

						{/* Search Pair 3 */}
						{(searchinputs.show3) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair3.uuid}
										value={localstate.searchoptions.searchpairs.searchpair3.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 3)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"firstname"}>First</MenuItem>
										<MenuItem value={"lastname"}>Last</MenuItem>
										<MenuItem value={"username"}>Username</MenuItem>
										<MenuItem value={"email"}>Email</MenuItem>
										<MenuItem value={"role"}>Role</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair3.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair3.type!=="DateTimeAfter") &&
									<TextField id="search3" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair3.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair3.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 3)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair3.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair3.type==="DateTimeAfter") &&
									<DateTimePicker
										value={localstate.searchoptions.searchpairs.searchpair3.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 3)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchinput} 
											inputProps={{
												...params.inputProps,
												placeholder: ""
											}}
										 />}
									/>
								}
								<div style={{ width: "30px" }}>
									{(searchinputs.show3 && !searchinputs.show4) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}

						{/* Search Pair 4 */}
						{(searchinputs.show4) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair4.uuid}
										value={localstate.searchoptions.searchpairs.searchpair4.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 4)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"firstname"}>First</MenuItem>
										<MenuItem value={"lastname"}>Last</MenuItem>
										<MenuItem value={"username"}>Username</MenuItem>
										<MenuItem value={"email"}>Email</MenuItem>
										<MenuItem value={"role"}>Role</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair4.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair4.type!=="DateTimeAfter") &&
									<TextField id="search4" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair4.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair4.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 4)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair4.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair4.type==="DateTimeAfter") &&
									<DateTimePicker
										value={localstate.searchoptions.searchpairs.searchpair4.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 4)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchinput} 
											inputProps={{
												...params.inputProps,
												placeholder: ""
											}}
										 />}
									/>
								}
								<div style={{ width: "30px" }}>
									{(searchinputs.show4 && !searchinputs.show5) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}

					</div>
				}
				
				{/* End of Search Inputs */}

				



				{/* Top Buttons & Pagination */}
				{(!isPrintView) &&
					<React.Fragment>
						<div style={{ height: "5px" }}>&nbsp;</div>
						<div>
							{(!showdeleteconfirmation) &&
								<React.Fragment>
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => AddUser()}
									>
										Add User
									</Button>

								</React.Fragment>
							}

							{/* Delete Items Confirmation */}
							{(showdeleteconfirmation) &&
								<div>
									<b>Are you sure you want to delete these items?</b>
									<div style={{ padding: "10px 0px" }}>
										{deleteitems.map((row, index) => {
											if (deleteitems.length === index + 1) {
												return (<span key={index}>{row.Name}</span>)
											} else {
												return (<span key={index}>{row.Name}, </span>)
											}
										})}
									</div>
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained" onClick={() => DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
								</div>
							}

							{(localstate.totalitems > 0) &&
								<TablePagination className={classes.paginationalign}
									SelectProps={
										editmode ? { disabled: true } : { disabled: false }
									}
									style={{ display: "inline-flex", float: "right" }}
									component="div"
									count={localstate.totalitems}
									page={localstate.page}
									onPageChange={handleChangePage}
									rowsPerPage={localstate.rowsperpage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									rowsPerPageOptions={[25, 50, 100]}
								/>
							}
						</div>
					</React.Fragment>
				}
				{/* End of Top Buttons & Pagination */}


				{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
				<div className={classes.flexgridcontainerA}>
					{/* ##########################  Start of Table  ########################## */}
					<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{minWidth:"100%", borderCollapse:"collapse", borderColor:"grey"}}>
						<EnhancedTableHead
							numSelected={localstate.selectedcount} 
							classes={classes}
							order={localstate.order}
							orderBy={localstate.orderby}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={state.griditems.length}
						/>
						{/* /* ##########################  Row Design  ########################## */	 }
						{/* If DB reload, don't allow view of table. */}
						{(!localstate.dbreload) &&
							<tbody style={{ display: "table-row-group" }}>
								{(localstate.griditems.length > 0) &&
									localstate.griditems.map((row, index) => {
										//Create all-new refs on each render. Helps avoid issues with grid states.
										//rowRefs.current[index + "Checkbox"] = React.createRef();
										// rowRefs.current[index + "first"] = React.createRef();
										// rowRefs.current[index + "Name"] = React.createRef();
										// rowRefs.current[index + "Cost"] = React.createRef();
										// rowRefs.current[index + "Price"] = React.createRef();
										// rowRefs.current[index + "Margin"] = React.createRef();
										// rowRefs.current[index + "SomeBoolean"] = React.createRef();
										return (
											<React.Fragment key={row.ID}>
												<tr	className={classes.flexgridrow}>
													{/* Checkbox - None, disabled! */}
													

													{/* First */}
													{(colstate.firstname) &&
														<td className={classes.flexgridstaticcontainer}>
															<div className={classes.flexgridexpand}>
																<PreviewIcon className={classes.transparenticon} color="primary" fontSize="inherit" onClick={() => { GoToItem(row.id) }}></PreviewIcon>
															</div>
															{row.firstname}
														</td>
													}

													{/* Last */}
													{(colstate.lastname) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.lastname}
														</td>
													}

													{/* UserName */}
													{(colstate.username) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.username}
														</td>
													}


													{/* Role */}
													{(colstate.role) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.role}
														</td>
													}

													{/* Email */}
													{(colstate.email) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.email}
														</td>
													}

													

													

													{/* Disabled */}
													{/* NewCheckbox */}
													{(colstate.disabled) &&
														<td>													
															<div style={{ padding: "4px 4px 1px 4px", textAlign:"center" }}>
																<Checkbox
																	className={classes.gridcheckbox}
																	disableRipple
																	color="default"
																	defaultChecked={row.disabled===1 ? true : false}
																	checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onChange={(event) => onChangeValue(event, index, "disabled")}
																	disabled
																/>
															</div>
														</td>
													}

													{/* Last Login */}
													{(colstate.lastlogin) &&
														<td className={classes.flexgridstaticcontainer}>
															<Moment element='span' format="MMMM D, YYYY [at] h:mma">{row.lastlogin}</Moment>
														</td>
													}

													{/* Creation Date */}
													{(colstate.created_at) &&
														<td className={classes.flexgridstaticcontainer}>
															<Moment element='span' format="MMMM D, YYYY [at] h:mma">{row.created_at}</Moment>
														</td>
													}

													
													{/* UserID (id) */}
													{(colstate.id) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.id}
														</td>
													}




												</tr>
												{/* Try: conditional for any render whatsoever! */}
												{(row.ExpandRow === true) &&
													<tr>
														<td colSpan="100%">
															<div style={{ margin: "25px" }}>
																Expanded column container!
														</div>
														</td>
													</tr>
												}
											</React.Fragment>

										)
									}
									)
								}
								{(localstate.griditems.length === 0) &&
									<tr className="flexgridrow"><td colSpan="100%"
										style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
								}
							</tbody>
						}
						{(localstate.dbreload) &&
							<tbody>
								<tr>
									<td colSpan="100%">
										<div style={{padding:"20px", textAlign:"center", margin:"auto"}}>
											<CircularProgress />
										</div>
									</td>
								</tr>
							</tbody>
						}
					</table> 
				</div>

				
				
				{(localstate.totalitems>localstate.rowsperpage) &&
					<TablePagination className={classes.paginationalign}
						SelectProps={
							editmode ? { disabled: true } : { disabled: false }
						}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[25,50, 100]}
					/>
				}
				
			</div>
		</LocalizationProvider>
    );
}

export default UserTable;
